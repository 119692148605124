import { memo } from 'react';
import { compose, withState, withPropsOnChange } from 'recompose';

import { isTranslatedContentDisplayed, switchDisplay } from './translation.utils';
import { DISPLAY_CONTENT_MODES } from './displayContentModes';

const enhancer = compose(
  withState(
    'displayContentMode',
    'onSetDisplayContentMode',
    ({ defaultDisplayMode }) => defaultDisplayMode || DISPLAY_CONTENT_MODES.AUTO,
  ),

  withPropsOnChange(
    [
      'displayContentMode',
      'onTranslate',
      'onSetDisplayContentMode',
      'isTranslatedContentEmpty',
      'translated',
    ],
    ({
      displayContentMode,
      onTranslate,
      onSetDisplayContentMode,
      isTranslatedContentEmpty,
      translated,
    }) => ({
      onRequestTranslate: async () => {
        // Translate only if the translated content is not already displayed.
        const shouldTranslate = !isTranslatedContentDisplayed(
          translated,
          displayContentMode,
        );

        // Actually translate the content if not already done.
        if (shouldTranslate && isTranslatedContentEmpty(translated)) {
          const { success } = (await onTranslate()) || {};
          if (!success) {
            return;
          }
        }

        // Toggle the display mode.
        onSetDisplayContentMode(
          shouldTranslate ?
            DISPLAY_CONTENT_MODES.TRANSLATED
            : DISPLAY_CONTENT_MODES.ORIGINAL,
        );
      },
    }),
  ),

  memo,
);

const TranslationContentSwitch = ({
  displayContentMode,
  original,
  translated,
  onRequestTranslate,
  isTranslatedContentEmpty,
  children,
}) => (
  children(
    switchDisplay(original, translated, displayContentMode, !isTranslatedContentEmpty(translated)),
    isTranslatedContentDisplayed(!isTranslatedContentEmpty(translated), displayContentMode),
    onRequestTranslate,
  )
);

export default enhancer(TranslationContentSwitch);
