import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { compose } from 'recompose';
import cn from 'classnames';

import Asset, { ICON_MAP } from 'react/generic/asset/Asset';

import classNames from './warning.module.scss';

const enhancer = compose(
  memo,
);

const Warning = ({
  warning,
  className,
  isDisplayed,
  onHide,
  children,
}) => (
  <div
    className={cn(
      className,
      classNames.container,
    )}
  >
    { isDisplayed
      && (
        <div className={classNames.warning}>
          <button
            type="button"
            onClick={onHide}
            className={classNames.hide}
          >
            <Asset name={ICON_MAP.x_circle} />
          </button>

          {warning}
        </div>
      ) }

    {children}
  </div>
);

Warning.displayName = 'Warning';

Warning.propTypes = {
  warning: PropTypes.node,
  className: PropTypes.string,
  isDisplayed: PropTypes.bool,
  onHide: PropTypes.func,
  children: PropTypes.node,
};

Warning.defaultProps = {
  warning: null,
  className: null,
  isDisplayed: false,
  onHide: noop,
  children: null,
};

export default enhancer(Warning);
