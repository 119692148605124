import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { stagesOptionsMap } from 'react/business/products/stages/stages.constants';

import { PRODUCT_STAGES } from 'config/constants';

import classNames from './product-stage.module.scss';

const ProductStage = ({ stage }) => (
  <div
    className={cn(
      classNames.stage,
      classNames[stage],
    )}
  >
    {stagesOptionsMap[stage]?.label}
  </div>
);

ProductStage.propTypes = {
  stage: PropTypes.oneOf(Object.values(PRODUCT_STAGES)),
};

ProductStage.defaultProps = {
  stage: '',
};

ProductStage.displayName = 'ProductStage';

export default ProductStage;
