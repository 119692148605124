import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { compose } from 'recompose';
import ReviewFormSuccessStep from 'react/business/review/form/base/steps/success/Success';
import { withRouter } from 'react-router';
import { historyShape, matchShape } from 'shapes/router';
import useRenderAction from 'react/generic/dialog/userRenderAction';
import ReviewDialogResultStep from '../ResultStep';

const enhancer = compose(
  withRouter,
  memo,
);

const ReviewDialogStepSuccess = ({
  successMessage,
  onClickClose,
  match,
  history,
  redirectOnSingleMessage,
}) => {
  const renderAction = useRenderAction({
    history, match, onClickClose, redirectOnSingleMessage,
  });

  return (
    <ReviewDialogResultStep>
      <ReviewFormSuccessStep
        successMessage={successMessage}
        renderAction={renderAction}
      />
    </ReviewDialogResultStep>
  );
};

ReviewDialogStepSuccess.displayName = 'ReviewDialogStepSuccess';

ReviewDialogStepSuccess.propTypes = {
  successMessage: PropTypes.node.isRequired,
  onClickClose: PropTypes.func,
  match: matchShape,
  history: historyShape,
  redirectOnSingleMessage: PropTypes.bool,
};

ReviewDialogStepSuccess.defaultProps = {
  onClickClose: noop,
  match: {},
  history: {},
  redirectOnSingleMessage: false,
};

export default enhancer(ReviewDialogStepSuccess);
