import agent from 'services/http';
import { mapReplyFromServer } from './replies.mapper';

const e = encodeURIComponent;

/**
 * GET /api/products/:productId/reviews/:reviewId/replies.
 * Get the list of replies on a review or reply to a review.
 *
 * @async
 * @param {string} productId - Product id.
 * @param {string} reviewId - Review id.
 * @param {string} [replyId] - Reply id to get replies of.
 * @param {object} query - Query parameters.
 * @returns {Array<object>} List of replies.
 */
export async function getReplies(productId, reviewId, replyId, query) {
  const { body } = await agent
    .get(`/api/products/${e(productId)}/reviews/${e(reviewId)}/replies`)
    .query(query);

  return (body || []).map(mapReplyFromServer);
}

/**
 * GET /api/products/:productId/reviews/:reviewId/replies/:replyId.
 * Get a specific reply.
 *
 * @async
 * @param {string} productId - Product id.
 * @param {string} reviewId - Review id.
 * @param {string} replyId - Reply id.
 * @param {object} query - Query parameters.
 * @returns {object} Reply.
 */
export async function getReply(productId, reviewId, replyId, query) {
  const { body: reply } = await agent
    .get(`/api/products/${e(productId)}/reviews/${e(reviewId)}/replies/${e(replyId)}`)
    .query(query);

  return mapReplyFromServer(reply);
}

/**
 * POST /api/products/:productId/reviews/:reviewId/replies.
 *
 * @async
 * @param {string} productId - Product id.
 * @param {object} reviewId - Review id.
 * @param {object} [replyId] - Reply id to reply to.
 * @param {object} reply - Reply.
 * @param {string} reply.content - Reply text.
 * @returns {object} Reply - The created reply.
 */
export async function createReply(productId, reviewId, replyId, reply) {
  const { body } = await agent
    .post(`/api/products/${e(productId)}/reviews/${e(reviewId)}/replies`)
    .send(reply);

  return mapReplyFromServer(body);
}

/**
 * POST /api/products/:productId/reviews/:reviewId/votes.
 *
 * @async
 * @param {string} productId - Product id.
 * @param {object} reviewId - Review id.
 * @param {object} replyId - Reply id to vote for.
 * @param {number} vote - Vote (0/1/-1).
 * @returns {object} Reply - The updated reply.
 */
export async function voteReply(productId, reviewId, replyId, vote) {
  const { body } = await agent
    .post(`/api/products/${e(productId)}/reviews/${e(reviewId)}/replies/${e(replyId)}/votes`)
    .send({ vote });

  return mapReplyFromServer(body);
}

/**
 * DELETE /api/products/:productId/reviews/:reviewId/replies/:replyId.
 * Delete a specific reply on a review.
 *
 * @async
 * @param {string} productId - Product id.
 * @param {string} reviewId - Review id.
 * @param {string} replyId - Reply id.
 * @returns {object} Http response body.
 */
export async function deleteReply(productId, reviewId, replyId) {
  const { body } = await agent
    .delete(`/api/products/${e(productId)}/reviews/${e(reviewId)}/replies/${e(replyId)}`);

  return body;
}
