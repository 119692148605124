import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import cn from 'classnames';

import classNames from './dialog.module.scss';

const enhancer = compose(
  memo,
);

const DialogActions = ({ ...props }) => (
  <div
    {...props}
    className={cn(
      props.className,
      classNames.dialogActions,
    )}
  />
);

DialogActions.displayName = 'DialogActions';

DialogActions.propTypes = {
  className: PropTypes.string,
};

DialogActions.defaultProps = {
  className: null,
};

export default enhancer(DialogActions);
