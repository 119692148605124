import { noop } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose, wrapDisplayName } from 'recompose';
import memoize from 'memoize-one';
// import agent from 'services/http';

import swal from 'sweetalert';
import { connect } from 'react-redux';
import { valueShape } from './select.shapes';

import warningPic from '../../../style/imgs/swalIcons/red-alert-icon.png';
import classNames from './withSelectValueSelector.module.scss';
import { getProductServers } from '../../../redux/products/actions';

const MODULE_FIELD = 'module';

// Encapsulate react-select value management.
// Use option value as value (instead of option object).
// onChange prop gives the option value (instead of option object).
export default compose(connect(
  null, { getProductServers },
), WrappedComponent => class withSelectValueSelector extends Component {
  static displayName = wrapDisplayName(WrappedComponent, 'withSelectValueSelector');

  static propTypes = {
    /** Value of the current option. */
    // eslint-disable-next-line react/require-default-props
    value: valueShape,
    /** Options of the select. */
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.node,
    })),
    /** Called with the value of the option selected. */
    onChange: PropTypes.func,
    isMulti: PropTypes.bool,
    name: PropTypes.string,
    fieldType: PropTypes.string,
    productName: PropTypes.string,
    productId: PropTypes.string,
  };

  static defaultProps = {
    options: [],
    onChange: noop,
    isMulti: false,
    name: '',
    fieldType: '',
    productName: undefined,
    productId: undefined,
  };

  /**
   * Memoize the option matching the current value.
   *
   * @param {string|number} value - Current value.
   * @param {object[]} [options] - Current options.
   * @returns {object} Current option, or null.
   */
  getOption = memoize((value, options, isMulti) => (
    isMulti ?
      value?.map(val => options?.find(opt => opt.value === val)).filter(Boolean)
      : options?.find(opt => opt.value === value) || null
  ));

  /**
   * Call the onChange prop with the selected option value.
   *
   * @param {object} opt - Selected option.
   * @returns {any} Props onChange return value.
   */
  // eslint-disable-next-line consistent-return
  onChange = async (opt) => {
    if (this.props.isMulti) {
      return this.props.onChange(opt?.map(op => op.value) || []);
    }
    if (opt?.value === 'ARCH' || opt?.value === 'DEP') {
      await this.openWarningPopup(
        opt,
        this.props.value,
        this.fieldType,
      );
    } else {
      if (opt?.value || opt?.value === 0) {
        return this.props.onChange(opt?.value);
      }
      return this.props.onChange(null);
    }
  };

  getOptions = memoize((value, options, name) => {
    if (name === 'stage') {
      return ['DEP'].includes(value) ? options : options?.filter(opt => opt.value !== 'ARCH');
    }
    return options;
  });

  openWarningPopup = async (opt, previousValue) => {
    const element = document.createElement('div');
    if (opt?.value === 'ARCH') {
      element.innerHTML
        = '<b>Are you sure?</b>';
      const title = this.props.fieldType === MODULE_FIELD ?
        'You are going to archive a module. This one will no longer be visible in your product overview.'
        : 'You are going to archive your digital product. The product will no longer be visible in Tangram, or from your space "My Products".';

      // FIXME i18n this
      swal({
        title,
        content: element,
        buttons: ['Yes', 'No'],
      }).then((result) => {
        if (result) {
          this.props.onChange(previousValue);
        } else {
          this.props.onChange(opt?.value || null);
        }
      });
    } else if (opt?.value === 'DEP' && this.props.fieldType !== MODULE_FIELD && this.props.productName && this.props.productId) {
      const servers = await this.props.getProductServers(this.props.productId);
      const hasActiveAssets = servers && servers.total !== 0;

      if (hasActiveAssets) {
        element.innerHTML
          = `There are still managed servers related to your product.
           Please go to <a target="_blank" href="https://mycloudconsole.adeo.cloud/product/iaas-solutions/console/servers?productId=${this.props.productId}">MyCloudConsole</a>,
           and select your product to see the list of managed servers.
          `;
      } else {
        element.innerHTML
          = `Selecting DECOMMISSIONED status implies your component is
      NOT USED anymore and there is NO MORE cost. Please check your
      <a target="_blank" href="https://adeoproduction.cloud.looker.com/dashboards/1451?Product+Consumer=${this.props.productName}&Asset+-+Consumer+CI+ID=&Environment+Type=&Tangram+Status=&Bu+Consumer=&Sum+Expected+Savings=&Provider+Name=&Tech+Product+Consumed=">FinOps Dashboard</a> first before confirming.`;
      }

      const title = 'Warning';
      // FIXME i18n this
      swal({
        icon: warningPic,
        className: classNames.swalImg,
        dangerMode: true,
        title,
        content: element,
        buttons: ['Confirm', 'Cancel'],
      }).then((result) => {
        if (hasActiveAssets) {
          this.props.onChange(previousValue);
          return;
        }
        if (result) {
          this.props.onChange(previousValue);
        } else {
          this.props.onChange(opt?.value || null);
        }
      });
    } else {
      this.props.onChange(opt?.value || null);
    }
  };

  /** @returns {object} JSX. */
  render = () => (
    <WrappedComponent
      {...this.props}
      options={this.getOptions(this.props.value, this.props.options, this.props.name)}
      value={this.getOption(this.props.value, this.props.options, this.props.isMulti)}
      onChange={this.onChange}
    />
  )
});
