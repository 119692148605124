import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import cn from 'classnames';

import { MAX_MARK } from 'config/constants';

import classNames from './rateLabel.module.scss';

const roundOneDecimal = x => Math.round(x * 10) / 10;

const enhancer = compose(
  memo,
);

const formatMark = mark => (mark === null ? '-' : roundOneDecimal(mark));

const RateLabel = ({ mark, className, ...props }) => (
  <span
    {...props}
    className={cn(className, classNames.rateLabel)}
  >
    <span className={classNames.mark}>{formatMark(mark)}</span>
    <span className={classNames.outOf}>/{MAX_MARK}</span>
  </span>
);

RateLabel.displayName = 'RateLabel';

RateLabel.propTypes = {
  mark: PropTypes.number,
  className: PropTypes.string,
};

RateLabel.defaultProps = {
  mark: null,
  className: '',
};

export default enhancer(RateLabel);
