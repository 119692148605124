import { formatRoute } from 'react-router-named-routes';
import ROUTES from 'react/routes';

export const redirectFromSingleMessage = (props) => {
  if (props.match.path === ROUTES.PRODUCT.PRESENTATION_SINGLE) {
    props.history.push(formatRoute(ROUTES.PRODUCT.PRESENTATION, {
      productId: props.match.params.productId,
      view: props.match.params.view,
    }));
  }
};
