import agent from 'services/http';

/**
 * GET /api/tipsAndNews.
 * Get tips and news.
 *
 * @async
 * @returns {{object}} Results.
 */
export async function getTipsAndNews() {
  const { body } = await agent
    .get('/api/tipsAndNews/');
  return (body || {});
}
