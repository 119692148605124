import { defineMessages } from 'react-intl';

export default defineMessages({
  LINK_TITLE: {
    id: 'link.form.title.input.placeholder',
    defaultMessage: 'Title',
  },
  LINK_LINK: {
    id: 'link.form.link.input.placeholder',
    defaultMessage: 'Link',
  },
});
