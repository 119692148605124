import React from 'react';
import { Helmet } from 'react-helmet';

import ApplicationLayout from 'react/business/layout/ApplicationLayout';
import FormattedMessageChildren from 'react/generic/i18n/FormattedMessageChildren';

import messages from './loading-view.messages';
import classNames from './loading-view.module.scss';

const LoadingView = () => (
  <ApplicationLayout>
    <FormattedMessageChildren {...messages.TITLE}>
      {message => (
        <Helmet>
          <title>{message}</title>
        </Helmet>
      )}
    </FormattedMessageChildren>

    <div className={classNames.container}>
      <div className={classNames.spinner}>
        <div />
        <div />
      </div>
    </div>
  </ApplicationLayout>
);

export default LoadingView;
