import {
  compose,
  withProps,
  renderNothing,
  branch,
} from 'recompose';

import config from 'config';

export default compose(
  withProps(() => ({
    href: config.externalUrls.statistics,
  })),

  // Do not render the indicator if the statistics url is falsy.
  branch(
    ({ href }) => !href,
    renderNothing,
  ),
);
