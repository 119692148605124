import * as SearchesService from 'services/searches';
import { ACTIONS } from './constants';

export const setSearchResults = results => ({
  type: ACTIONS.SET_SEARCH_RESULTS,
  results,
});

export const SEARCH_BY_TITLE = text => ({
  type: ACTIONS.SEARCH_BY_TITLE,
  text,
});

export const SEARCH_BY_TITLE_SUCCESS = () => ({
  type: ACTIONS.SEARCH_BY_TITLE_SUCCESS,
});

export const SEARCH_BY_TITLE_FAILURE = error => ({
  type: ACTIONS.SEARCH_BY_TITLE_FAILURE,
  error,
});

export const searchByTitle = (text, context) => async (dispatch) => {
  dispatch(SEARCH_BY_TITLE(text));

  try {
    let results = null;

    if (text) {
      results = context ?
        await SearchesService.searchByTitle(text, context)
        : await SearchesService.searchByTitle(text);
    }
    dispatch(setSearchResults(results));
    return dispatch(SEARCH_BY_TITLE_SUCCESS());
  } catch (err) {
    return dispatch(SEARCH_BY_TITLE_FAILURE(err));
  }
};

export const setCachedFilters = cachedFilters => ({
  type: ACTIONS.SET_CACHED_FILTERS,
  cachedFilters,
});
