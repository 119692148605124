import { defineMessages } from 'react-intl';

export default defineMessages({
  LABEL: {
    id: 'home.indicators.section.created.products.label',
    defaultMessage: '{maintainedProductsCount, plural, one {Product} other {Products}} maintained by platforms',
  },
  PRODUCT: {
    id: 'home.indicators.section.created.products.product',
    defaultMessage: '{productsCount, plural, one {Product} other {Products}}',
  },
  SUB_LABEL: {
    id: 'home.indicators.section.created.products.sublabel',
    defaultMessage: '(from all ADEO)',
  },
});
