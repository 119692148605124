import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import { userShape } from 'shapes/user';
import Avatar from 'react/generic/avatar/Avatar';
import UserName from 'react/generic/users/name/UserName';

import globalMessages from 'config/global.messages';
import classNames from './user-card.module.scss';

const UserCard = ({
  user, projectRole, className, compact,
}) => (
  <div className={cn(classNames.card, compact && classNames.compact, className)}>
    <Avatar className={classNames.avatar} src={user.avatar} />
    <div className={classNames.body}>
      <div className={classNames.identity}>
        <p className={classNames.name}>
          <UserName user={user} />
        </p>
        {projectRole && (
          <p className={classNames.projectRole}>{projectRole}</p>
        )}
      </div>
      {!compact && (
        <a className={classNames.mailTo} href={`mailto:${user.mail}`}>
          <FormattedMessage {...globalMessages.CONTACT} />
        </a>
      )}
    </div>
  </div>
);

UserCard.propTypes = {
  user: userShape.isRequired,
  projectRole: PropTypes.string,
  className: PropTypes.string,
  compact: PropTypes.bool,
};

UserCard.defaultProps = {
  projectRole: null,
  className: null,
  compact: false,
};

export default memo(UserCard);
