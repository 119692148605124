import { toLower, isEmpty } from 'lodash';

import { delayPromise } from 'services/promise';
import { BUFFERED_ACTION_TIMEOUT } from 'config/constants';

/**
 * Implementation of the mergeParams param of makeBufferedAction for a list of ids.
 *
 * @param {object} buffer - The buffer. If it does not exist, will be created (as a Set).
 * @param {string[]} ids - The list of ids.
 * @returns {object} The buffer.
 */
export const mergeParamsIds = (buffer, ids) => {
  const dest = buffer || new Set();
  (ids || []).forEach(id => dest.add(toLower(id)));
  return dest;
};

/**
 * Implementation of the transformParams param of makeBufferedAction for a list of ids.
 *
 * @param {object} buffer - The buffer from mergeParamsIds.
 * @returns {any[]} Parameters of the action.
 */
export const transformParamsIds = buffer => [
  Array.from(buffer),
];

/**
 * Buffer the ids to load before loading the items.
 *
 * @param {Function} action - Action to call.
 * @param {object} opts - Options.
 * @param {number} opts.timeout - Delay to wait before dispatching the action with the buffered ids.
 * @param {Function} opts.mergeParams - Merge buffered and new params.
 * @param {Function} opts.transformParams - Takes the result of mergeParams
 * and returns the LIST of parameters of the action.
 * @returns {Function} The action to dispatch.
 *
 * @example
 * const bufferedAction = makeBufferedAction(getUsers);
 * store.dispatch(bufferedAction([1, 2, 3]));
 * store.dispatch(bufferedAction([4, 5, 6]));
 */
export function makeBufferedAction(
  action,
  {
    timeout = BUFFERED_ACTION_TIMEOUT,
    mergeParams = mergeParamsIds,
    transformParams = transformParamsIds,
  } = {},
) {
  let bufferedParams = null;
  let returnPromise = null;

  /**
   * @param {...any} params - Action parameters.
   * @returns {Function} Action.
   */
  return (...params) => (dispatch) => {
    bufferedParams = mergeParams(bufferedParams, ...params);

    if (!isEmpty(bufferedParams) && !returnPromise) {
      returnPromise = delayPromise(timeout)
        .then(() => {
          const actionParams = transformParams(bufferedParams);
          returnPromise = null;
          bufferedParams = null;
          return dispatch(action(...actionParams));
        });
    }

    return returnPromise;
  };
}
