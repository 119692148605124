export const FILTERS = {
  ORDER_BY: 'orderBy',
  MARKS: 'marks',
};

export const ORDER_BY_OPTIONS = {
  DATE_ASC: 'dateAsc',
  DATE_DESC: 'dateDesc',
  MARK_ASC: 'markAsc',
  MARK_DESC: 'markDesc',
};

export const DETAILED_MARKS = {
  EASY_TO_USE: 'easyToUse',
  MEETS_NEEDS: 'meetsTheNeeds',
  PERFORMANCE_STABILITY: 'performance',
  USER_ASSISTANCE: 'userAssistance',
};

export const REVIEW_SOURCES = {
  WEBSITE: 'Website',
  SNIPPET: 'Snippet',
};
