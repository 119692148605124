import { compose } from 'recompose';
import connect from 'react/hoc/connectProxy';

import { ACTIONS } from 'redux/reviews/constants';
import { patchProductReview } from 'redux/reviews/actions';
import { isLoadingPatchProductReview } from 'redux/reviews/selectors';
import { getProduct } from 'redux/products/actions';

export default compose(
  connect(
    state => ({
      isSubmitting: isLoadingPatchProductReview(state),
    }),
    (dispatch, props) => ({
      onSubmit: async (review) => {
        const reviewPayload = {
          ...review,
          id: props.review.id,
        };
        const action = await dispatch(patchProductReview(props.productId, reviewPayload, true));

        if (action.type === ACTIONS.PATCH_PRODUCT_REVIEW_SUCCESS) {
          // Fetch the product to see marks refresh.
          await dispatch(getProduct(props.productId));
          return props.onSubmitSuccess?.(action.review);
        }
        return props.onSubmitFailure?.(action.error);
      },
    }),
  ),
);
