import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { compose, renameProp } from 'recompose';

import FormattedMessageChildren from 'react/generic/i18n/FormattedMessageChildren';

import { DebouncedReduxFormInput } from 'react/generic/form/input/Input';

import classNames from './links-form-item.module.scss';
import messages from './links-form-item.messages';

const enhancer = compose(
  renameProp('field', 'link'),
);

const LinksFormItem = ({
  link,
  displayTitle,
  titleFieldProps,
  urlFieldProps,
}) => (
  <div className={classNames.container}>
    { displayTitle && (
      <FormattedMessageChildren {...messages.LINK_TITLE}>
        { message => (
          <Field
            name={`${link}.title`}
            type="text"
            component={DebouncedReduxFormInput}
            placeholder={message}
            {
              ...titleFieldProps
            }
          />
        )}
      </FormattedMessageChildren>
    )}
    <FormattedMessageChildren {...messages.LINK_LINK}>
      { message => (
        <Field
          name={`${link}.link`}
          type="text"
          component={DebouncedReduxFormInput}
          placeholder={message}
          {
            ...urlFieldProps
          }
        />
      )}
    </FormattedMessageChildren>
  </div>
);

LinksFormItem.displayName = 'LinksFormItem';

LinksFormItem.propTypes = {
  link: PropTypes.string.isRequired,
  displayTitle: PropTypes.bool,
  titleFieldProps: PropTypes.objectOf(PropTypes.any),
  urlFieldProps: PropTypes.objectOf(PropTypes.any),
};

LinksFormItem.defaultProps = {
  displayTitle: false,
  titleFieldProps: {},
  urlFieldProps: {},
};

export default enhancer(LinksFormItem);
