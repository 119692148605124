import { compose, lifecycle } from 'recompose';
import { uniq } from 'lodash';
import connect from 'react/hoc/connectProxy';

import { ACTIONS } from 'redux/reviews/constants';
import { getLatestReviews } from 'redux/reviews/actions';
import { selectLatestReviewsList } from 'redux/reviews/selectors';

import { getProduct, getBigProduct } from 'redux/products/actions';

export default compose(
  connect(
    state => ({
      reviews: selectLatestReviewsList(state),
    }),
    {
      getLatestReviews,
      getProduct,
      getBigProduct,
    },
  ),

  lifecycle({
    /**
     * Load the 3 latest reviews on mount.
     */
    async componentDidMount() {
      const action = await this.props.getLatestReviews();

      if (action.type === ACTIONS.GET_LATEST_REVIEWS_SUCCESS) {
        // Get components ids
        const componentIds = uniq(
          this.props.reviews?.filter(review => review.digitalEntityType === 'component')
            .map(review => review.product) || [],
        );

        // Get products ids
        const productIds = uniq(
              this.props.reviews?.filter(review => review.digitalEntityType === 'product')
                .map(review => review.product) || [],
        );

        await Promise.all(
          [...componentIds.map(id => this.props.getProduct(id)),
            ...productIds.map(id => this.props.getBigProduct(id))],
        );
      }
    },
  }),
);
