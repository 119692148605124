import React, { memo } from 'react';
import { compose } from 'recompose';
import { FormattedMessage } from 'react-intl';
import ReactSelect from 'react-select';

import messages from 'react/business/home/sections/about/actions/action-cards-grid.messages';

import sassTheme from 'style/theme.variables.scss';

import { SELECT_OPTIONS } from './selectOptions.constants';
import LinkSelect from './LinkSelect';

const linkOptions = SELECT_OPTIONS.map(option => ({
  label: <LinkSelect href={option.url} icon={option.icon} label={option.label} />,
  value: option.id,
}));

const enhancer = compose(
  memo,
);

const customStyles = {
  control: (provided, state) => ({
    display: 'flex',
    border: `1px solid ${sassTheme.titleColor}`,
    borderBottom: state.menuIsOpen ? '1px solid white' : `1px solid ${sassTheme.titleColor}`,
    borderRadius: state.menuIsOpen ? '1.4rem 1.4rem 0 0' : '5rem',
    padding: '0.238rem 0',
    cursor: 'pointer',
  }),
  option: (provided, state) => ({
    ...provided,
    background: state.isFocused ? `${sassTheme.lightBlue}` : 'white',
    padding: '0',
  }),
  placeholder: () => ({
    margin: '0 0 0 0.8rem',
  }),
  valueContainer: (provided) => ({
    ...provided,
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '2px 8px',
    cursor: 'pointer',
  }),
  menu: provided => ({
    ...provided,
    border: `1px solid ${sassTheme.titleColor}`,
    borderTop: 'none',
    borderRadius: '0 0 0.5rem 0.5rem',
    marginTop: '0',
  }),
  menuList: provided => ({
    ...provided,
    paddingTop: '0',
  }),
};

const SelectProductsByCategories = () => (
  <ReactSelect
    placeholder={<FormattedMessage {...messages.CONSULT_SELECT_PLACEHOLDER} />}
    options={linkOptions}
    styles={customStyles}
    isSearchable={false}
  />
);

SelectProductsByCategories.displayName = 'SelectProductsByCategories';

export default enhancer(SelectProductsByCategories);
