import connect from 'react/hoc/connectProxy';

import { createReply } from 'redux/replies/actions';

/**
 * Required props:
 * - productId.
 * - reviewId.
 *
 * Exposes a onCreateReply func bound to the productId and reviewId props.
 */
export default connect(
  null,
  (dispatch, props) => ({
    onCreateReply: reply => dispatch(createReply(props.productId, props.reviewId, null, reply)),
  }),
);
