import React, { memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import { compose } from 'recompose';
import cn from 'classnames';
import { Popover } from 'antd';

import Asset, { ICON_MAP } from 'react/generic/asset/Asset';
import classNames from './helpButton.module.scss';
import HelpMenu from '../helpMenu/HelpMenu';

const enhancer = compose(
  memo,
);

export const HelpButtonPresentation = ({
  className,
}) => {
  const [isMenuDisplayed, setIsMenuDisplayed] = useState(false);

  const handleClick = useCallback(
    () => setIsMenuDisplayed(!isMenuDisplayed),
    [isMenuDisplayed],
  );

  return (
    <div
      className={cn(
        classNames.container,
        className,
      )}
    >
      <Popover
        content={(
          <HelpMenu onClick={handleClick} />
        )}
        trigger="click"
        visible={isMenuDisplayed}
        onVisibleChange={setIsMenuDisplayed}
        className={classNames.mySpace}
        overlayClassName={classNames.helpMenu}
        placement="bottomRight"
        arrowPointAtCenter
      >
        <div className={classNames.helpMenuContainer}>
          <div className={classNames.iconContainer}>
            <Asset
              name={ICON_MAP.help}
              className={classNames.mySpaceIcon}
            />
          </div>
        </div>
      </Popover>
    </div>
  );
};

HelpButtonPresentation.displayName = 'HelpButtonPresentation';

HelpButtonPresentation.propTypes = {
  className: PropTypes.string,
};

HelpButtonPresentation.defaultProps = {
  className: '',
};

const HelpButton = enhancer(HelpButtonPresentation);
HelpButton.displayName = 'DashboardButton';
HelpButton.propTypes = omit(HelpButtonPresentation.propTypes);
HelpButton.defaultProps = omit(HelpButtonPresentation.defaultProps);

export default HelpButton;
