export const PRODUCT_FORM_STEPS = {
  REQUEST: 'request',
  PRESENTATION: 'presentation',
  FEATURES: 'features',
  LINKS: 'links',
  VALIDATION: 'validation',
};

// Can't do Object.values(STEPS_RENDERER) because order of keys is not guaranteed.
export const makeStepsToDisplay = () => [
  PRODUCT_FORM_STEPS.REQUEST,
  PRODUCT_FORM_STEPS.PRESENTATION,
  PRODUCT_FORM_STEPS.FEATURES,
  PRODUCT_FORM_STEPS.LINKS,
  PRODUCT_FORM_STEPS.VALIDATION,
].filter(Boolean);
