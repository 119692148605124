import PropTypes from 'prop-types';

export const storeShape = PropTypes.shape({
  subscribe: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  getState: PropTypes.func.isRequired,
});

export const messageShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  defaultMessage: PropTypes.string.isRequired,
});

export const elementShape = PropTypes.oneOfType([
  // Class component or functional component without memo / forwardRef.
  PropTypes.func,
  // Function component (with memo or forwardRef).
  PropTypes.shape({}),
  // Native element name.
  PropTypes.string,
]);
