import * as TipsAndNewsService from 'services/tipsAndNews';
import { ACTIONS } from './constants';

export const GET_TIPS_AND_NEWS = () => ({
  type: ACTIONS.GET_TIPS_AND_NEWS,
});

export const GET_TIPS_AND_NEWS_SUCCESS = result => ({
  type: ACTIONS.GET_TIPS_AND_NEWS_SUCCESS,
  result,
});

export const GET_TIPS_AND_NEWS_FAILURE = error => ({
  type: ACTIONS.GET_TIPS_AND_NEWS_FAILURE,
  error,
});

export const getTipsAndNews = () => async (dispatch) => {
  dispatch(GET_TIPS_AND_NEWS());

  try {
    const result = await TipsAndNewsService.getTipsAndNews();
    return dispatch(GET_TIPS_AND_NEWS_SUCCESS(result));
  } catch (err) {
    return dispatch(GET_TIPS_AND_NEWS_FAILURE(err));
  }
};
