import {
  compose,
  withHandlers,
  renameProp,
  withProps,
} from 'recompose';

import {
  selectUserById,
  selectUserLanguage,
} from 'redux/users/selectors';
import connect from 'react/hoc/connectProxy';
import { selectCanUserEditReview, selectCanUserDeleteReview } from 'redux/reviews/selectors';
import { ACTIONS as REPLIES_ACTIONS } from 'redux/replies/constants';
import omitProps from 'react/hoc/omitProps';

import { isHtmlContentEmpty } from 'utils';

import withUserLazyLoading from '../../user/lazyLoad/withUserLazyLoading';
import voteProductReviewConnect from './voteProductReview.connect';
import getRepliesConnect from '../reply/connect/getReplies.connect';
import createReplyConnect from '../reply/createReplyForm/createReply.connect';
import translateReviewConnect from './translateReview.connect';
import withRepliesConnect from './withReplies.connect';

/**
 * Required props:
 * - productId (string).
 * - review (reviewShape).
 * - onSetRepliesExpanded (func (bool) => void). Not exposed to child component.
 */
export default compose(
  // Load replies on click of the replies toggle.
  compose(
    getRepliesConnect,

    withHandlers({
      onClickExpandReplies: ({
        onGetReplies,
        review,
        onSetRepliesExpanded,
      }) => async (shouldExpand) => {
        if (shouldExpand) {
          const { type } = await onGetReplies(review.id);
          if (type === REPLIES_ACTIONS.GET_REPLIES_SUCCESS) {
            onSetRepliesExpanded(true);
          }
        } else {
          onSetRepliesExpanded(false);
        }
      },
    }),

    omitProps(['onGetReplies', 'onSetRepliesExpanded']),
  ),

  // Add vote review callback and rename it to onVote.
  compose(
    voteProductReviewConnect,
    renameProp('onVoteProductReview', 'onVote'),
  ),

  // Lazy load and get author from store.
  compose(
    // Get author from the store.
    connect(
      (state, props) => ({
        author: selectUserById(state, props.review?.author),
      }),
    ),

    // Lazy load the review author.
    withUserLazyLoading({
      getIdsToLoadFromProps: props => [props.review?.author].filter(Boolean),
    }),
  ),

  compose(
    // Prop is review.id but createReplyConnect requires reviewId.
    withProps(props => ({
      reviewId: props.review.id,
      productId: props.review.product,
      isContentEmpty: isHtmlContentEmpty(props.review.content),

    })),
    // Add create reply method.
    createReplyConnect,
    // Add translate method.
    translateReviewConnect,

    omitProps(['reviewId', 'productId']),
  ),

  connect(
    (state, props) => ({
      withTranslateButton:
      !props.isContentEmpty && selectUserLanguage(state) !== props.review.contentLanguage,
    }),
  ),

  compose(
    withRepliesConnect,
    connect(
      (state, props) => ({
        canEditReview: selectCanUserEditReview(state, props.review),
        canDeleteReview: selectCanUserDeleteReview(state, props.review),
      }),
    ),
  ),
);
