import { noop } from 'lodash';
import React, { memo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { FormattedMessage } from 'react-intl';
import Asset, { ICON_MAP } from 'react/generic/asset/Asset';

import { FEEDBACK_MESSAGES } from 'config/constants.messages';
import classNames from './success.module.scss';

const enhancer = compose(
  memo,
);

const SuggestionFormSuccessStep = ({
  className,
  successMessage,
  renderAction,
}) => (
  <div className={cn(className, classNames.container)}>
    <Asset
      name={ICON_MAP.thumb_up}
      className={classNames.icon}
      aria-hidden
    />

    <h1 className={classNames.title}>
      <FormattedMessage {...FEEDBACK_MESSAGES.THANK_YOU} />
    </h1>

    <p className={classNames.body}>
      {successMessage}
    </p>

    <p className={classNames.linkContainer}>
      {renderAction({ className: classNames.button })}
    </p>
  </div>
);

SuggestionFormSuccessStep.displayName = 'SuggestionFormSuccessStep';

SuggestionFormSuccessStep.propTypes = {
  className: PropTypes.string,
  successMessage: PropTypes.node,
  renderAction: PropTypes.func,
};

SuggestionFormSuccessStep.defaultProps = {
  className: null,
  successMessage: null,
  renderAction: noop,
};

export default enhancer(SuggestionFormSuccessStep);
