import queryString from 'query-string';
import React, { memo } from 'react';
import { compose, withPropsOnChange } from 'recompose';
import { formatRoute } from 'react-router-named-routes';

import { searchResultsShape } from 'shapes/search';
import ROUTES from 'react/routes';
import { BUSINESS_OBJECTS, PRODUCT_VIEW } from 'config/constants';
import businessMessages from 'config/business.messages';

import { FormattedMessage } from 'react-intl';
import messages from './autocomplete-results.messages';
import AutocompleteResultsSection from './components/section/AutocompleteResultsSection.presentation';

const sections = [
  {
    property: BUSINESS_OBJECTS.PRODUCTS,
    title: () => <FormattedMessage {...messages.PRODUCTS_SECTION_TITLE} />,
    actionLabel: () => <FormattedMessage {...messages.PRODUCTS_SECTION_ACTION} />,
    component: compose(
      withPropsOnChange(
        ['items'],
        ({ items }) => ({
          items: (items || []).map(item => ({
            ...item,
            href: formatRoute(ROUTES.PRODUCT.PRESENTATION, {
              productId: item.id,
              view: PRODUCT_VIEW.OVERVIEW,
            }),
            componentHref: formatRoute(ROUTES.COMPONENT.PRESENTATION, {
              componentId: item.componentId,
              view: PRODUCT_VIEW.OVERVIEW,
            }),
          })),
        }),
      ),
    )(AutocompleteResultsSection),
  },
  {
    property: BUSINESS_OBJECTS.COMMUNITIES,
    title: () => <FormattedMessage {...messages.COMMUNITIES_SECTION_TITLE} />,
    actionLabel: () => <FormattedMessage {...messages.COMMUNITIES_SECTION_ACTION} />,
    component: compose(
      withPropsOnChange(
        ['items'],
        ({ items }) => ({
          items: (items || []).map(item => ({
            ...item,
            href: `${formatRoute(ROUTES.PRODUCTS)}?${queryString.stringify({ communityIds: item.id })}`,
          })),
        }),
      ),
    )(AutocompleteResultsSection),
  },
  {
    property: BUSINESS_OBJECTS.DOMAINS,
    title: () => <FormattedMessage {...messages.DOMAINS_SECTION_TITLE} />,
    actionLabel: () => <FormattedMessage {...messages.DOMAINS_SECTION_ACTION} />,
    component: compose(
      withPropsOnChange(
        ['items'],
        ({ items }) => ({
          items: (items || []).map(item => ({
            ...item,
            href: `${formatRoute(ROUTES.PRODUCTS)}?${queryString.stringify({ domainIds: item.id })}`,
          })),
        }),
      ),
    )(AutocompleteResultsSection),
  },
  {
    property: BUSINESS_OBJECTS.BUSINESS_UNITS,
    title: () => <FormattedMessage {...messages.BUSINESS_UNITS_SECTION_TITLE} />,
    actionLabel: () => <FormattedMessage {...messages.BUSINESS_UNITS_SECTION_ACTION} />,
    component: compose(
      withPropsOnChange(
        ['items'],
        ({ items }) => ({
          items: (items || []).map(item => ({
            ...item,
            href: `${formatRoute(ROUTES.PRODUCTS)}?${queryString.stringify({ businessUnitIds: item.id })}`,
          })),
        }),
      ),
    )(AutocompleteResultsSection),
  },
  {
    property: BUSINESS_OBJECTS.ATOMIC_ACTIVITIES,
    title: () => <FormattedMessage {...messages.ACTIVITIES_SECTION_TITLE} />,
    actionLabel: () => <FormattedMessage {...messages.ACTIVITIES_SECTION_ACTION} />,
    component: compose(
      withPropsOnChange(
        ['items'],
        ({ items }) => ({
          items: (items || []).map(item => ({
            ...item,
            href: `${formatRoute(ROUTES.PRODUCTS)}?${queryString.stringify({ atomicActivityIds: item.id })}`,
          })),
        }),
      ),
    )(AutocompleteResultsSection),
  },
  {
    property: BUSINESS_OBJECTS.ARCHITECTURE_BUILDING_BLOCKS,
    title: () => <FormattedMessage {...businessMessages.ARCHITECTURE_BUILDING_BLOCKS} />,
    actionLabel: () => <FormattedMessage {...messages.ABB_SECTION_ACTION} />,
    component: compose(
      withPropsOnChange(
        ['items'],
        ({ items }) => ({
          items: (items || []).map(item => ({
            ...item,
            href: `${formatRoute(ROUTES.PRODUCTS)}?${queryString.stringify({ abbIds: item.id })}`,
          })),
        }),
      ),
    )(AutocompleteResultsSection),
  },
  {
    property: BUSINESS_OBJECTS.SUBSETS,
    title: () => <FormattedMessage {...businessMessages.SUBSETS} />,
    actionLabel: () => <FormattedMessage {...messages.SUBSET_SECTION_ACTION} />,
    component: compose(
      withPropsOnChange(
        ['items'],
        ({ items }) => ({
          items: (items || []).map(item => ({
            ...item,
            href: `${formatRoute(ROUTES.PRODUCTS)}?${queryString.stringify({ subsetIds: item.id })}`,
          })),
        }),
      ),
    )(AutocompleteResultsSection),
  },
  {
    property: BUSINESS_OBJECTS.PERSONS,
    title: () => <FormattedMessage {...messages.PERSONS_SECTION_TITLE} />,
    actionLabel: () => <FormattedMessage {...messages.PERSONS_SECTION_ACTION} />,
    component: compose(
      withPropsOnChange(
        ['items'],
        ({ items }) => ({
          items: (items || []).map(item => ({
            ...item,
            href: formatRoute(ROUTES.PRODUCT.BYUSER, {
              externalId: item.externalId,
            }),
          })),
        }),
      ),
    )(AutocompleteResultsSection),
  },
];

const enhancer = compose(
  memo,
);

const AutocompleteResults = ({ results }) => (
  <div>
    {sections.map(section => (
      <section.component
        key={section.property}
        type={section.property}
        items={results[section.property]}
        title={section.title()}
        actionLabel={section.actionLabel()}
      />
    ))}
  </div>
);

AutocompleteResults.displayName = 'AutocompleteResults';

AutocompleteResults.propTypes = {
  results: searchResultsShape,
};

AutocompleteResults.defaultProps = {
  results: {},
};

export default enhancer(AutocompleteResults);
