import * as UsersService from 'services/users';
import * as ProductService from 'services/products';
import { makeBufferedAction } from 'services/redux/bufferedAction';

import { ACTIONS } from './constants';

export const GET_CURRENT_USER = () => ({
  type: ACTIONS.GET_CURRENT_USER,
});

export const GET_CURRENT_USER_SUCCESS = user => ({
  type: ACTIONS.GET_CURRENT_USER_SUCCESS,
  user,
});

export const GET_CURRENT_USER_FAILURE = error => ({
  type: ACTIONS.GET_CURRENT_USER_FAILURE,
  error,
});

export const getCurrentUser = () => async (dispatch) => {
  dispatch(GET_CURRENT_USER());

  try {
    const user = await UsersService.getCurrentUser();
    return dispatch(GET_CURRENT_USER_SUCCESS(user));
  } catch (err) {
    return dispatch(GET_CURRENT_USER_FAILURE(err));
  }
};

export const GET_CURRENT_USER_PREFERENCES = () => ({
  type: ACTIONS.GET_CURRENT_USER_PREFERENCES,
});

export const GET_CURRENT_USER_PREFERENCES_SUCCESS = preferences => ({
  type: ACTIONS.GET_CURRENT_USER_PREFERENCES_SUCCESS,
  preferences,
});

export const GET_CURRENT_USER_PREFERENCES_FAILURE = error => ({
  type: ACTIONS.GET_CURRENT_USER_PREFERENCES_FAILURE,
  error,
});

export const getCurrentUserPreferences = () => async (dispatch) => {
  dispatch(GET_CURRENT_USER_PREFERENCES());

  try {
    const preferences = await UsersService.getCurrentUserPreferences();
    return dispatch(GET_CURRENT_USER_PREFERENCES_SUCCESS(preferences));
  } catch (err) {
    return dispatch(GET_CURRENT_USER_PREFERENCES_FAILURE(err));
  }
};

export const SET_CURRENT_USER_PREFERENCES = () => ({
  type: ACTIONS.SET_CURRENT_USER_PREFERENCES,
});

export const SET_CURRENT_USER_PREFERENCES_SUCCESS = preferences => ({
  type: ACTIONS.SET_CURRENT_USER_PREFERENCES_SUCCESS,
  preferences,
});

export const SET_CURRENT_USER_PREFERENCES_FAILURE = error => ({
  type: ACTIONS.SET_CURRENT_USER_PREFERENCES_FAILURE,
  error,
});

export const setCurrentUserPreferences = preferences => async (dispatch) => {
  dispatch(SET_CURRENT_USER_PREFERENCES());

  try {
    const newPreferences = await UsersService.postCurrentUserPreferences(preferences);
    return dispatch(SET_CURRENT_USER_PREFERENCES_SUCCESS(newPreferences));
  } catch (err) {
    return dispatch(SET_CURRENT_USER_PREFERENCES_FAILURE(err));
  }
};

export const GET_USERS = ids => ({
  type: ACTIONS.GET_USERS,
  ids,
});

export const GET_USERS_SUCCESS = (ids, users) => ({
  type: ACTIONS.GET_USERS_SUCCESS,
  ids,
  users,
});

export const GET_USERS_FAILURE = (ids, error) => ({
  type: ACTIONS.GET_USERS_FAILURE,
  ids,
  error,
});

export const getUsers = ids => async (dispatch) => {
  dispatch(GET_USERS(ids));

  try {
    const user = await UsersService.getUsersByIds(ids);
    return dispatch(GET_USERS_SUCCESS(ids, user));
  } catch (err) {
    return dispatch(GET_USERS_FAILURE(ids, err));
  }
};

export const bufferedGetUsers = makeBufferedAction(getUsers);

export const GET_USERS_BY_EXTERNAL_IDS = ids => ({
  type: ACTIONS.GET_USERS_BY_EXTERNAL_IDS,
  ids,
});

export const GET_USERS_BY_EXTERNAL_IDS_SUCCESS = (ids, users) => ({
  type: ACTIONS.GET_USERS_BY_EXTERNAL_IDS_SUCCESS,
  ids,
  users,
});

export const GET_USERS_BY_EXTERNAL_IDS_FAILURE = (ids, error) => ({
  type: ACTIONS.GET_USERS_BY_EXTERNAL_IDS_FAILURE,
  ids,
  error,
});

export const getUsersByExternalIds = ids => async (dispatch) => {
  dispatch(GET_USERS_BY_EXTERNAL_IDS(ids));

  try {
    const user = await UsersService.getUsersByExternalIds(ids);
    return dispatch(GET_USERS_BY_EXTERNAL_IDS_SUCCESS(ids, user));
  } catch (err) {
    return dispatch(GET_USERS_BY_EXTERNAL_IDS_FAILURE(ids, err));
  }
};

export const GET_USER_WORKPLACE_ID = id => ({
  type: ACTIONS.GET_USER_WORKPLACE_ID,
  id,
});

export const GET_USER_WORKPLACE_ID_SUCCESS = (id, workplaceId) => ({
  type: ACTIONS.GET_USER_WORKPLACE_ID_SUCCESS,
  id,
  workplaceId,
});

export const GET_USER_WORKPLACE_ID_FAILURE = (id, error) => ({
  type: ACTIONS.GET_USER_WORKPLACE_ID_FAILURE,
  id,
  error,
  noRedirect: true,
});

export const getUserWorkplaceId = (id, mail) => async (dispatch) => {
  dispatch(GET_USER_WORKPLACE_ID(id));

  try {
    const workplaceId = await UsersService.getWorkplaceId(mail);
    return dispatch(GET_USER_WORKPLACE_ID_SUCCESS(id, workplaceId));
  } catch (err) {
    return dispatch(GET_USER_WORKPLACE_ID_FAILURE(id, err));
  }
};

export const bufferedGetUsersByExternalIds = makeBufferedAction(getUsersByExternalIds);

export const FOLLOW_PRODUCT = id => ({
  type: ACTIONS.FOLLOW_PRODUCT,
  id,
});

export const FOLLOW_PRODUCT_SUCCESS = (id, followedProducts) => ({
  type: ACTIONS.FOLLOW_PRODUCT_SUCCESS,
  id,
  followedProducts,
});

export const FOLLOW_PRODUCT_FAILURE = (id, error) => ({
  type: ACTIONS.FOLLOW_PRODUCT_FAILURE,
  id,
  error,
});

export const followProduct = productId => async (dispatch) => {
  dispatch(FOLLOW_PRODUCT(productId));

  try {
    const followedProducts = await UsersService.followProduct(productId);
    return dispatch(FOLLOW_PRODUCT_SUCCESS(productId, followedProducts));
  } catch (err) {
    return dispatch(FOLLOW_PRODUCT_FAILURE(productId, err));
  }
};

export const UNFOLLOW_PRODUCT = id => ({
  type: ACTIONS.UNFOLLOW_PRODUCT,
  id,
});

export const UNFOLLOW_PRODUCT_SUCCESS = (id, followedProducts) => ({
  type: ACTIONS.UNFOLLOW_PRODUCT_SUCCESS,
  id,
  followedProducts,
});

export const UNFOLLOW_PRODUCT_FAILURE = (id, error) => ({
  type: ACTIONS.UNFOLLOW_PRODUCT_FAILURE,
  id,
  error,
});

export const unfollowProduct = productId => async (dispatch) => {
  dispatch(UNFOLLOW_PRODUCT(productId));

  try {
    const followedProducts = await UsersService.unfollowProduct(productId);
    return dispatch(UNFOLLOW_PRODUCT_SUCCESS(productId, followedProducts));
  } catch (err) {
    return dispatch(UNFOLLOW_PRODUCT_FAILURE(productId, err));
  }
};

export const GET_MANAGER_ROLES = () => ({
  type: ACTIONS.GET_MANAGER_ROLES,
});

export const GET_MANAGER_ROLES_SUCCESS = roles => ({
  type: ACTIONS.GET_MANAGER_ROLES_SUCCESS,
  roles,
});
export const GET_MANAGER_ROLES_FAILURE = error => ({
  type: ACTIONS.GET_ROLES_ON_TOP_FAILURE,
  error,
});

export const GET_ROLES_ON_TOP = () => ({
  type: ACTIONS.GET_ROLES_ON_TOP,
});

export const GET_ROLES_ON_TOP_SUCCESS = roles => ({
  type: ACTIONS.GET_ROLES_ON_TOP_SUCCESS,
  roles,
});

export const GET_ROLES_ON_TOP_FAILURE = error => ({
  type: ACTIONS.GET_ROLES_ON_TOP_FAILURE,
  error,
});

export const getManagerRoles = () => async (dispatch) => {
  dispatch(GET_MANAGER_ROLES());

  try {
    const { roles } = await ProductService.getManagerRoles();
    return dispatch(GET_MANAGER_ROLES_SUCCESS(roles));
  } catch (err) {
    return dispatch(GET_MANAGER_ROLES_FAILURE(err));
  }
};

export const getRolesOnTop = () => async (dispatch) => {
  dispatch(GET_ROLES_ON_TOP());

  try {
    const { roles } = await ProductService.getRolesOnTop();
    return dispatch(GET_ROLES_ON_TOP_SUCCESS(roles));
  } catch (err) {
    return dispatch(GET_ROLES_ON_TOP_FAILURE(err));
  }
};

export const GET_MY_CREATED_PRODUCTS = () => ({
  type: ACTIONS.GET_MY_CREATED_PRODUCTS,
});

export const GET_PRODUCTS_TO_VALIDATE = () => ({
  type: ACTIONS.GET_PRODUCTS_TO_VALIDATE,
});

export const GET_MY_CREATED_PRODUCTS_SUCCESS = (myProducts, count) => ({
  type: ACTIONS.GET_MY_CREATED_PRODUCTS_SUCCESS,
  myProducts,
  count,
});

export const GET_PRODUCTS_TO_VALIDATE_SUCCESS = (products, count) => ({
  type: ACTIONS.GET_PRODUCTS_TO_VALIDATE_SUCCESS,
  products,
  count,
});

export const GET_MY_CREATED_PRODUCTS_FAILURE = err => ({
  type: ACTIONS.GET_MY_CREATED_PRODUCTS_FAILURE,
  err,
});

export const GET_PRODUCTS_TO_VALIDATE_FAILURE = err => ({
  type: ACTIONS.GET_PRODUCTS_TO_VALIDATE_FAILURE,
  err,
});

export const getMyCreatedProducts = (query = {}) => async (dispatch) => {
  dispatch(GET_MY_CREATED_PRODUCTS());

  try {
    const { items, count } = await UsersService.getCurrentUserCreatedProducts(query);

    return dispatch(GET_MY_CREATED_PRODUCTS_SUCCESS(items, count));
  } catch (err) {
    return dispatch(GET_MY_CREATED_PRODUCTS_FAILURE(err));
  }
};

export const getProductsToValidate = (query = {}) => async (dispatch) => {
  dispatch(GET_PRODUCTS_TO_VALIDATE);

  try {
    const { items, count } = await UsersService.getProductsToValidate(query);

    return dispatch(GET_PRODUCTS_TO_VALIDATE_SUCCESS(items, count));
  } catch (err) {
    return dispatch(GET_PRODUCTS_TO_VALIDATE_FAILURE(err));
  }
};

export const GET_MY_FOLLOWED_PRODUCTS = () => ({
  type: ACTIONS.GET_MY_FOLLOWED_PRODUCTS,
});

export const GET_MY_FOLLOWED_PRODUCTS_SUCCESS = myProducts => ({
  type: ACTIONS.GET_MY_FOLLOWED_PRODUCTS_SUCCESS,
  myProducts,
});

export const GET_MY_FOLLOWED_PRODUCTS_FAILURE = err => ({
  type: ACTIONS.GET_MY_FOLLOWED_PRODUCTS_FAILURE,
  err,
});

export const getMyFollowedProducts = (query = {}) => async (dispatch) => {
  dispatch(GET_MY_FOLLOWED_PRODUCTS());

  try {
    const myProducts = await UsersService.getCurrentUserFollowedProducts(query);
    return dispatch(GET_MY_FOLLOWED_PRODUCTS_SUCCESS(myProducts));
  } catch (err) {
    return dispatch(GET_MY_FOLLOWED_PRODUCTS_FAILURE(err));
  }
};

export const toggleFollowedProduct = productId => ({
  type: ACTIONS.UPDATE_FOLLOWED_PRODUCT,
  productId,
});
