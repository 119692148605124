import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import cn from 'classnames';

import classNames from './dialog.module.scss';

const enhancer = compose(
  memo,
);

const DialogTitle = ({ ...props }) => (
  <div
    {...props}
    className={cn(
      props.className,
      classNames.dialogTitle,
    )}
  />
);

DialogTitle.displayName = 'DialogTitle';

DialogTitle.propTypes = {
  className: PropTypes.string,
};

DialogTitle.defaultProps = {
  className: null,
};

export default enhancer(DialogTitle);
