import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { compose } from 'recompose';

import IconButton from 'react/generic/button/IconButton';
import Asset, { ICON_MAP } from 'react/generic/asset/Asset';

import classNames from '../icon-button.module.scss';

const enhancer = compose(
  memo,
);

const EditIconButton = ({ onClickEdit }) => (
  <IconButton
    outline
    overrideClassName={classNames.editButton}
    overrideClassNames={classNames}
    onClick={onClickEdit}
  >
    <Asset name={ICON_MAP.edit} />
  </IconButton>
);

EditIconButton.propTypes = {
  onClickEdit: PropTypes.func,
};

EditIconButton.defaultProps = {
  onClickEdit: noop,
};

export default enhancer(EditIconButton);
