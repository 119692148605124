import makeReviewFormDialog from 'react/business/review/form/dialog/base/ReviewFormDialog';

import withSubmitEditReview from 'react/business/review/form/base/withSubmitEditReview';
import EditFormStep from './steps/form/Form';
import EditSuccessStep from './steps/success/Success';
import EditFailureStep from './steps/failure/Failure';

// Handle onSubmit to edit a review.
const SubmitEditFormStep = withSubmitEditReview(EditFormStep);

export default makeReviewFormDialog(
  SubmitEditFormStep,
  EditSuccessStep,
  EditFailureStep,
);
