import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { compose } from 'recompose';

import formContainer from './Form.container';

export default (WrappedComponent) => {
  const enhancer = compose(
    formContainer,
  );

  const SuggestionFormChildrenContainer = ({
    onSubmit,
    canSubmit,
    children,
    ...props
  }) => (
    <form onSubmit={onSubmit}>
      {children({
        form: (
          <WrappedComponent {...props} />
        ),
        canSubmit,
      })}
    </form>
  );

  SuggestionFormChildrenContainer.displayName = 'SuggestionFormChildrenContainer';

  SuggestionFormChildrenContainer.propTypes = {
    canSubmit: PropTypes.bool,
    onSubmit: PropTypes.func,
    /** Enforce function as child. */
    children: PropTypes.func,
  };

  SuggestionFormChildrenContainer.defaultProps = {
    canSubmit: false,
    onSubmit: noop,
    children: noop,
  };

  return enhancer(SuggestionFormChildrenContainer);
};
