import { connect } from 'react-redux';

import { emptyMapDispatchToProps } from 'services/redux/empty';

export default (
  mapStateToProps,
  mapDispatchToProps = emptyMapDispatchToProps,
  mergeProps = undefined,
) => connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
);
