import { compose } from 'recompose';

import connect from 'react/hoc/connectProxy';
import { selectIsBelowMediumBreakpoint } from 'redux/responsive/selectors';

const mapStateToProps = state => ({
  isBelowMediumBreakpoint: selectIsBelowMediumBreakpoint(state),
});

export default compose(
  connect(mapStateToProps),
);
