import connect from 'react/hoc/connectProxy';
import {
  selectSearchResults,
  selectIsSearchByTitleLoading,
  selectSearchByTitleError,
} from 'redux/searches/selectors';

export default connect(
  state => ({
    results: selectSearchResults(state),
    isLoading: selectIsSearchByTitleLoading(state),
    error: selectSearchByTitleError(state),
  }),
);
