import { compose } from 'recompose';
import connect from 'react/hoc/connectProxy';

import { ACTIONS } from 'redux/suggestions/constants';
import { createProductSuggestion } from 'redux/suggestions/actions';
import { isLoadingCreateProductSuggestion } from 'redux/suggestions/selectors';

export default compose(
  connect(
    state => ({
      isSubmitting: isLoadingCreateProductSuggestion(state),
    }),
    (dispatch, props) => ({
      onSubmit: async ({ productId, ...suggestion }) => {
        const action = await dispatch(createProductSuggestion(productId, suggestion, true));

        return action.type === ACTIONS.CREATE_PRODUCT_SUGGESTION_SUCCESS ?
          props.onSubmitSuccess?.(action.suggestion)
          : props.onSubmitFailure?.(action.error);
      },
    }),
  ),
);
