import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import ProductCard from 'react/business/products/card/ProductCard';
import PropTypes from 'prop-types';
import { productShape } from 'shapes/product';

import ProductsCarouselButton from './ProductsCarouselButton';

import classNames from './products-carousel.module.scss';

const ProductsCarousel = ({ products, displayedItemsCount }) => !!products?.length && (
  <div className={classNames.container}>
    <Slider
      lazyLoad
      swipeToSlide
      infinite={false}
      className={classNames.slider}
      slidesToShow={displayedItemsCount}
      nextArrow={(
        <ProductsCarouselButton
          slidesToShow={displayedItemsCount}
        />
      )}
      prevArrow={(
        <ProductsCarouselButton
          isPrevious
          slidesToShow={displayedItemsCount}
        />
      )}
    >
      {
        products.map(product => (
          <ProductCard
            className={classNames.card}
            key={product.id}
            product={product}
            displayType="carousel"
          />
        ))
      }
    </Slider>
  </div>
);

ProductsCarousel.propTypes = {
  products: PropTypes.arrayOf(productShape),
  displayedItemsCount: PropTypes.number,
};

ProductsCarousel.defaultProps = {
  products: [],
  displayedItemsCount: 3,
};

export default ProductsCarousel;
