import { compose, withProps, withHandlers } from 'recompose';
import { createForm } from 'rc-form';

import { htmlToEditorState, editorStateToHtml } from 'react/generic/form/wysiwyg/wysiwyg.utils';

export default compose(
  createForm(),

  withHandlers({
    onSubmit: ({ form, onSubmit }) => (event) => {
      event.preventDefault();
      event.stopPropagation();

      form.validateFields((err, values) => {
        if (!err) {
          onSubmit({
            ...values,
            content: editorStateToHtml(values.content),
            productId: values.productId?.value,
          });
        }
      });
    },
  }),

  withProps(({
    form,
    isSubmitting,
    suggestion,
    productId,
  }) => ({
    canSubmit: !isSubmitting
    && (!!form.getFieldValue('productId') || !!productId)
    && !!form.getFieldValue('title')
    && form.getFieldValue('content')?.getCurrentContent()?.hasText(),

    titleDecorator: form.getFieldDecorator(
      'title',
      {
        initialValue: suggestion?.title,
      },
    ),

    contentDecorator: form.getFieldDecorator(
      'content',
      {
        initialValue: htmlToEditorState(suggestion?.content),
      },
    ),

    productIdDecorator: form.getFieldDecorator(
      'productId',
      {
        initialValue: productId || suggestion?.productId ?
          { value: productId || suggestion?.productId }
          : null,
      },
    ),

    showProductField: !productId,
  })),
);
