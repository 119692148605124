import connect from 'react/hoc/connectProxy';

import { voteProductReview } from 'redux/reviews/actions';

/**
 * Requires a productId prop.
 * Exposes a onVoteProductReview func bound to the productId prop.
 */
export default connect(
  null,
  (dispatch, props) => ({
    onVoteProductReview: (reviewId, vote) => dispatch(voteProductReview(
      props.productId,
      reviewId,
      vote,
    )),
  }),
);
