import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter as RouterProvider } from 'connected-react-router';
import { Provider as ReduxProvider } from 'react-redux';
import { DragDropContextProvider } from 'react-dnd';
import backend from 'react-dnd-html5-backend';
import { ToastProvider } from 'react-toast-notifications';

import LocaleProvider, { localeBundleShape } from 'react/generic/i18n/LocaleProvider';
import ToastsStoreListener from 'react/generic/toasts/ToastsStoreListener';

import { hide as hideSplash } from 'services/splash';

import { historyShape } from 'shapes/router';
import { storeShape } from 'shapes/vendor';

class AppWrapper extends Component {
  /**
   * When application is mounted, remove splash screen.
   */
  componentDidMount() {
    hideSplash();
  }

  /**
   * Renders the component.
   *
   * @returns {object} - The React element.
   */
  render() {
    const {
      store, history, children, localeBundle,
    } = this.props;
    return (
      <DragDropContextProvider backend={backend}>
        <ReduxProvider store={store}>
          <LocaleProvider localeBundle={localeBundle}>
            <ToastProvider placement="bottom-right">
              <ToastsStoreListener>
                <RouterProvider history={history}>
                  {children}
                </RouterProvider>
              </ToastsStoreListener>
            </ToastProvider>
          </LocaleProvider>
        </ReduxProvider>
      </DragDropContextProvider>
    );
  }
}

AppWrapper.propTypes = {
  store: storeShape.isRequired,
  history: historyShape.isRequired,
  localeBundle: localeBundleShape.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

AppWrapper.defaultProps = {
  children: null,
};

export default AppWrapper;
