import { defineMessages } from 'react-intl';

export default defineMessages({
  TITLE: {
    id: 'homepage.latestproducts.section.title',
    defaultMessage: 'Most recent products',
  },
  ALL_PRODUCTS: {
    id: 'homepage.latestproducts.see.allProducts',
    defaultMessage: 'See all products',
  },
});
