import { mapProps } from 'recompose';

// Redux-form's Field passes a { input: { value, onChange } } prop,
// so flatten it to { value, onChange } to the base component.
export default mapProps(({
  // Only expose some meta props.
  meta: {
    error,
    warning,
    touched,
  },

  // Flatten input
  input,
  ...props
}) => ({
  ...props,
  ...input,

  // Only display error if the field was touched by the user.
  error: touched && error,
  warning,
}));
