import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { DropTarget } from 'react-dnd';
import cn from 'classnames';

import { identity } from 'services/functions/utils';

import { collect, spec } from './drop-target-spec';
import classNames from './list-field-drop-target.module.scss';

const {
  string, bool, node, func,
} = PropTypes;

export default (type) => {
  const enhancer = compose(
    DropTarget([type], spec, collect),
  );

  const ListFieldDropTarget = ({
    className, connectDropTarget, isHovered, children,
  }) => connectDropTarget(
    <div
      className={cn(
        className,
        classNames.target,
        isHovered && classNames.hovered,
      )}
    >
      {children}
    </div>,
  );

  ListFieldDropTarget.displayName = 'ListFieldDropTarget';

  ListFieldDropTarget.propTypes = {
    className: string,
    connectDropTarget: func,
    isHovered: bool,
    children: node,
  };

  ListFieldDropTarget.defaultProps = {
    className: '',
    connectDropTarget: identity,
    isHovered: false,
    children: null,
  };

  const EnhancedListFieldDropTarget = enhancer(ListFieldDropTarget);

  EnhancedListFieldDropTarget.displayName = 'EnhancedListFieldDropTarget';

  EnhancedListFieldDropTarget.propTypes = {
    children: node,
  };

  EnhancedListFieldDropTarget.defaultProps = {
    children: null,
  };

  return EnhancedListFieldDropTarget;
};
