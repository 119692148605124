import { compose, withPropsOnChange } from 'recompose';

import omitProps from 'react/hoc/omitProps';
import { sanitize } from 'services/html';

export default (
  inputProp = 'unsafeHtml',
  outputProp = 'children',
  omitInputProp = true,
) => compose(
  ...[
    // Sanitize unsafeHtml.
    withPropsOnChange(
      [inputProp],
      ({ [inputProp]: unsafeHtml }) => ({
        [outputProp]: sanitize(unsafeHtml),
      }),
    ),

    // Omit unsafeHtml.
    (omitInputProp && inputProp !== outputProp)
    && omitProps(['unsafeHtml']),
  ].filter(Boolean),
);
