import PropTypes from 'prop-types';

import { DETAILED_MARKS } from 'react/business/review/reviews.constants';

export const detailedMarksShape = PropTypes.shape({
  easyToUse: PropTypes.number,
  meetsTheNeeds: PropTypes.number,
  performance: PropTypes.number,
  userAssistance: PropTypes.number,
});

export const detailedMarkShape = PropTypes.shape({
  name: PropTypes.string,
  decorator: PropTypes.func,
});

export const detailedMarksFieldIdsShape = PropTypes.shape({
  [DETAILED_MARKS.EASY_TO_USE]: PropTypes.string,
  [DETAILED_MARKS.MEETS_NEEDS]: PropTypes.string,
  [DETAILED_MARKS.PERFORMANCE_STABILITY]: PropTypes.string,
  [DETAILED_MARKS.USER_ASSISTANCE]: PropTypes.string,
});
