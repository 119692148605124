import React, { memo, useRef, useState } from 'react';
import { compose } from 'recompose';
import { FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import { formatRoute } from 'react-router-named-routes';

import Button from 'react/generic/button/Button';

import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { matchShape } from 'shapes/router';
import routes from 'react/routes';
import classNames from './share.module.scss';
import messages from './share.messages';

const enhancer = compose(
  withRouter,
  memo,
);

const MessageCardShare = ({ appendText, match }) => {
  const tooltipRef = useRef(null);

  const [timer, setTimer] = useState();

  const copyUrl = () => {
    clearTimeout(timer);

    const el = document.createElement('textarea');
    const url = window.location.href.split('?')[0];

    el.value
      = match.path === routes.PRODUCT.PRESENTATION_SINGLE ?
        formatRoute(url)
        : formatRoute(`${url}/${appendText}`);

    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    tooltipRef.current.dispatchEvent(new Event('openTooltip'));

    setTimer(setTimeout(() => {
      tooltipRef.current.dispatchEvent(new Event('closeTooltip'));
    }, 1300));
  };

  return (
    <div className={classNames.shareButtonContainer}>
      <ReactTooltip
        className={classNames.copyInfo}
      >
        <FormattedMessage {...messages.COPY_TEXT} />
      </ReactTooltip>

      <div
        ref={tooltipRef}
        data-effect="solid"
        data-place="top"
        data-event="openTooltip"
        data-event-off="closeTooltip"
        data-tip=""
      >
        <Button
          overrideClassName={classNames.shareButton}
          onClick={copyUrl}
        >
          <FormattedMessage {...messages.SHARE} />
        </Button>
      </div>

    </div>
  );
};

MessageCardShare.displayName = 'MessageCardShare';

MessageCardShare.propTypes = {
  appendText: PropTypes.string,
  match: matchShape,
};

MessageCardShare.defaultProps = {
  appendText: '',
  match: {},
};

export default enhancer(MessageCardShare);
