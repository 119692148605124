import { debounce, noop } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { DEBOUNCE_TIME } from 'config/constants';

/**
 * Debounce the onChange event handler.
 *
 * @example
 * import withDebouncedOnChange from 'react/generic/form/withDebouncedOnChange';
 *
 * const DebouncedInput = withDebouncedOnChange()('input');
 *
 * const Form = ({ title, onChangeTitle, description, onChangeDescription }) => (
 *   <>
 *     <DebouncedInput value={title} onChange={onChangeTitle} />
 *     <DebouncedInput value={description} onChange={onChangeDescription} />
 *   </>
 * );
 *
 * @param {number} debounceTime - Debounce time in ms.
 * @param {string} valuePropName - Value prop name in child component.
 * @param {string} onChangePropName - On change handler prop name in child component.
 * @returns {Function} HOC.
 */
export default (
  debounceTime = DEBOUNCE_TIME,
  valuePropName = 'value',
  onChangePropName = 'onChange',
) => WrappedComponent => class DebouncedOnChange extends Component {
  static propTypes = {
    [onChangePropName]: PropTypes.func,
  };

  static defaultProps = {
    [onChangePropName]: noop,
  };

  state = {
    value: this.props[valuePropName],
    prevProps: this.props,
  };

  /**
   * Sync current state value with next props value only when next props value changed.
   *
   * @param {object} nextProps - Component next props.
   * @param {object} prevState - Component current state.
   * @returns {object} Updated state.
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      value: prevState.prevProps[valuePropName] !== nextProps[valuePropName] ?
        nextProps[valuePropName]
        : prevState.value,
      prevProps: nextProps,
    };
  }

  /**
   * Debounce the onChange event.
   */
  emitOnChange = debounce(
    value => this.props[onChangePropName](value),
    debounceTime,
  );

  /**
   * On change, sync the state and emit a debounced onChange event to update the parent.
   *
   * @param {any} value - New value.
   */
  onChange = (value) => {
    this.setState({ value });
    this.emitOnChange(value);
  };

  /** @returns {object} JSX. */
  render() {
    // Expand all props.
    const props = {
      ...this.props,
      // Override value and onChange props.
      [valuePropName]: this.state.value,
      [onChangePropName]: this.onChange,
    };

    return (
      <WrappedComponent {...props} />
    );
  }
};
