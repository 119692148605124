import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import cn from 'classnames';

import classNames from './fieldWarning.module.scss';

const enhancer = compose(
  memo,
);

const FieldWarning = ({ className, children, ...props }) => (
  <h3
    {...props}
    className={cn(className, classNames.fieldWarning)}
  >
    {children}
  </h3>
);

FieldWarning.displayName = 'FieldWarning';

FieldWarning.propTypes = {
  // Heading must have content.
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

FieldWarning.defaultProps = {
  className: null,
};

export default enhancer(FieldWarning);
