import { createSelector } from 'reselect';

import { sortBy, userCanEditSuggestion, userCanDeleteSuggestion } from 'services/suggestions';
import { FILTERS } from 'react/business/suggestion/suggestions.constants';
import { selectCurrentUser } from 'redux/users/selectors';

export const selectProductSuggestionsMapById
  = (state, productId) => state.suggestions.map[productId];

export const selectProductSuggestionsListById = createSelector(
  [
    selectProductSuggestionsMapById,
    (state, productId, filters) => filters,
  ],
  (productSuggestionsMap, filters) => Object
    .values(productSuggestionsMap || {})
    .sort(sortBy[filters[FILTERS.ORDER_BY]]),
);

export const selectSuggestionsCurrentCount
  = (state, productId) => Object.keys(state.suggestions.map[productId] || {}).length;

export const selectProductSuggestionsListByIdTop2 = createSelector(
  [
    selectProductSuggestionsMapById,
  ],
  productSuggestionsMap => Object.values(productSuggestionsMap || {}).slice(0, 2),
);

export const selectProductSuggestionById = (
  state,
  productId,
  suggestionId,
) => selectProductSuggestionsMapById(state, productId)?.[suggestionId];

export const selectAllSuggestionsCount = state => state.suggestions.allSuggestionsCount;

export const selectProductSuggestionsCount
  = (state, productId) => state.suggestions.countByProduct[productId];

export const isLoadingGetAllSuggestionsCount
  = state => !!state.suggestions.isLoading.getAllSuggestionsCount;
export const isLoadingGetProductSuggestions
  = state => !!state.suggestions.isLoading.getProductSuggestions;
export const isLoadingGetProductSuggestion
  = state => !!state.suggestions.isLoading.getProductSuggestion;
export const isLoadingCreateProductSuggestion
  = state => !!state.suggestions.isLoading.createProductSuggestion;
export const isLoadingPatchProductSuggestion
  = state => !!state.suggestions.isLoading.patchProductSuggestion;
export const isLoadingVoteProductSuggestion
  = state => !!state.suggestions.isLoading.voteProductSuggestion;

export const isLoadingDeleteProductSuggestion
  = state => !!state.suggestions.isLoading.deleteProductSuggestion;

export const isLoadingSuggestions = createSelector(
  [
    isLoadingGetProductSuggestions,
    isLoadingGetProductSuggestion,
    isLoadingCreateProductSuggestion,
    isLoadingVoteProductSuggestion,
    isLoadingPatchProductSuggestion,
    isLoadingDeleteProductSuggestion,
  ],
  (...args) => args.some(v => v),
);

export const selectCanUserEditSuggestion = createSelector(
  [
    (state, suggestion) => suggestion,
    selectCurrentUser,
  ],
  (suggestion, user) => userCanEditSuggestion(suggestion, user?.id),
);

export const selectCanUserDeleteSuggestion = createSelector(
  [
    (state, suggestion) => suggestion,
    selectCurrentUser,
  ],
  (suggestion, user) => userCanDeleteSuggestion(suggestion, user?.id),
);
