import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { enhancer } from 'react/business/links/form/LinksForm';
import { userShape } from 'shapes/user';
import messages from './employeeType.messages';
import configEmployeeTypes from './employeeType.config';

const isExternal = (type) => {
  const employeeType = configEmployeeTypes.find(config => config.code === type);
  return employeeType?.isExternal;
};

const EmployeeType = ({
  user,
  className,
}) => (
  <>
    { isExternal(user?.employeeType) && (
    <div className={className}>
      <FormattedMessage {...messages.EXTERNAL} />
    </div>
    ) }
  </>
);

EmployeeType.displayName = 'UserName';

EmployeeType.propTypes = {
  user: userShape,
  className: PropTypes.string,
};

EmployeeType.defaultProps = {
  user: null,
  className: null,
};

export default enhancer(EmployeeType);
