import moment from 'moment';

import { ORDER_BY_OPTIONS } from 'react/business/review/reviews.constants';

export * from './reviews.repository';

export const sortBy = {
  [ORDER_BY_OPTIONS.DATE_ASC]: (a, b) => (moment(b.date).isBefore(a.date) ? 1 : -1),
  [ORDER_BY_OPTIONS.DATE_DESC]: (a, b) => (moment(a.date).isBefore(b.date) ? 1 : -1),
  [ORDER_BY_OPTIONS.MARK_ASC]: (a, b) => a.mark - b.mark,
  [ORDER_BY_OPTIONS.MARK_DESC]: (a, b) => b.mark - a.mark,
};

export const userCanEditReview = (review, currentUserId) => (
  review.author === currentUserId
  && !review.directRepliesCount
);

export const userCanDeleteReview = (review, currentUserId) => (
  review.author === currentUserId
  && !review.directRepliesCount
);
