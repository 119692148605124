import React from 'react';
import { compose, mapProps } from 'recompose';
import { bool, func, number } from 'prop-types';
import cn from 'classnames';
import { noop } from 'lodash';

import Asset, { ICON_MAP } from 'react/generic/asset/Asset';

import classNames from './products-carousel-button.module.scss';

const enhancer = compose(
  mapProps(props => ({
    ...props,
    onClick: () => {
      props.onClick();
    },
  })),
);

const ProductsCarouselButton = ({
  currentSlide,
  slideCount,
  slidesToShow,
  isPrevious,
  onClick,
}) => {
  const isPreviousButtonOnNotFirstSlide = isPrevious && currentSlide > 0;
  const isNextButtonOnNotLastSlide = !isPrevious && (currentSlide + slidesToShow) < slideCount;

  const shouldDisplay = isPreviousButtonOnNotFirstSlide || isNextButtonOnNotLastSlide;

  return (
    <div className={
      cn(
        classNames.chevron,
        classNames.container,
        isPrevious && classNames.previous,
        !shouldDisplay && classNames.hidden,
      )
    }
    >
      <button className={classNames.carouselButton} onClick={onClick}>
        <Asset name={ICON_MAP.chevron_large} className={classNames.chevron} />
      </button>
    </div>
  );
};

ProductsCarouselButton.displayName = 'ProductsCarouselButton';
ProductsCarouselButton.propTypes = {
  currentSlide: number,
  slideCount: number,
  slidesToShow: number,
  isPrevious: bool,
  onClick: func,
};
ProductsCarouselButton.defaultProps = {
  currentSlide: 0,
  slideCount: 0,
  slidesToShow: 1,
  isPrevious: false,
  onClick: noop,
};

export default enhancer(ProductsCarouselButton);
