import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  branch,
  renderComponent,
  lifecycle,
  mapProps,
} from 'recompose';

import { searchResultsShape } from 'shapes/search';
import { isSearchEmpty } from 'services/searches';
import globalMessages from 'config/global.messages';

import { FormattedMessage } from 'react-intl';
import { noop } from 'lodash';
import withSearch from '../input/connect/withSearch.connect';
import searchInputContainer from '../input/SearchInput.container';
import connectAutocompleteResults from '../autocompleteResults/autocompleteResults.connect';
import SearchInput from '../input/SearchInput';
import AutocompleteResultsPresentation from '../autocompleteResults/AutocompleteResults.presentation';

import messages from './search-autocomplete.messages';
import classNames from './searchAutocomplete.module.scss';

const AutocompleteResults = compose(
  branch(
    props => props.isLoading || props.results === null,
    renderComponent(({ search }) => (
      <FormattedMessage
        {...messages.LOADING}
        values={{ search }}
      />
    )),
  ),

  branch(
    props => props.error,
    renderComponent(() => <FormattedMessage {...globalMessages.UNEXPECTED_ERROR} />),
  ),

  branch(
    props => isSearchEmpty(props.results),
    renderComponent(({ search }) => (
      <FormattedMessage
        {...messages.NO_RESULTS}
        values={{ search }}
      />
    )),
  ),
)(AutocompleteResultsPresentation);

const ClearOnUnmount = compose(
  lifecycle({
    /** Clear the search results when unmounting the popover. */
    componentWillUnmount() {
      this.props.onUnmount();
    },
  }),

  mapProps(({ onUnmount, ...props }) => props),
)('div');

const enhancer = compose(
  memo,
  connectAutocompleteResults,
  withSearch,
  searchInputContainer,
);

const SearchAutocomplete = ({
  results,
  isLoading,
  error,
  search,
  onChangeSearch,
  onSubmit,
  onSearchCleared,
  isFocused,
  onFocus,
  onBlur,
  searchContext,
  onResultClick,
}) => (

  <div className={classNames.container}>
    <SearchInput
      search={search}
      onChangeSearch={onChangeSearch}
      onSubmit={onSubmit}
      onFocus={onFocus}
      onBlur={onBlur}
      searchContext={searchContext}
    />

    {(search && isFocused)
        && (
          <ClearOnUnmount onUnmount={onSearchCleared}>
            <div className={classNames.autocompletePopover}>
              <div className={classNames.searchForButtonContainer}>
                <button
                  type="button"
                  onClick={onSubmit}
                  className={classNames.searchForButton}
                >
                  <FormattedMessage
                    {...messages.SEARCH_FOR}
                    values={{ search }}
                  />
                </button>
              </div>

              <div className={classNames.resultsContainer}>
                <AutocompleteResults
                  search={search}
                  results={results}
                  isLoading={isLoading}
                  error={error}
                  context={searchContext}
                  onResultClick={onResultClick}
                />
              </div>
            </div>
          </ClearOnUnmount>
        )}
  </div>
);

SearchAutocomplete.displayName = 'SearchAutocomplete';

SearchAutocomplete.propTypes = {
  results: searchResultsShape,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  search: PropTypes.string.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  onSearchCleared: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isFocused: PropTypes.bool,
  onFocus: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  searchContext: PropTypes.string,
  onResultClick: PropTypes.func,
};

SearchAutocomplete.defaultProps = {
  results: {},
  isLoading: false,
  error: null,
  isFocused: false,
  searchContext: '',
  onResultClick: noop,
};

export default enhancer(SearchAutocomplete);
