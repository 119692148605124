import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { FormattedMessage } from 'react-intl';

import Asset, { ICON_MAP } from 'react/generic/asset/Asset';
import Button from 'react/generic/button/Button';

import { messageShape } from 'shapes/vendor';

import messages from './add-more-button.messages';
import classNames from './add-more-button.module.scss';

const { func } = PropTypes;

const AddMoreButton = ({ message, onClick }) => (
  <Button
    outline
    onClick={onClick}
    overrideClassName={classNames.button}
    overrideClassNames={classNames}
  >
    <Asset name={ICON_MAP.plus} className={classNames.icon} />
    <FormattedMessage {...message} />
  </Button>
);

AddMoreButton.displayName = 'AddMoreButton';
AddMoreButton.propTypes = {
  message: messageShape,
  onClick: func,
};
AddMoreButton.defaultProps = {
  message: messages.DEFAULT,
  onClick: noop,
};

export default AddMoreButton;
