import { defineMessages } from 'react-intl';

export default defineMessages({
  TITLE_PLACEHOLDER: {
    id: 'suggestion.form.titlePlaceholder',
    defaultMessage: 'Make a succint, clear title which accurately summarizes your request.',
  },
  CONTENT_PLACEHOLDER: {
    id: 'suggestion.form.contentPlaceholder',
    defaultMessage: `One suggestion per post - if you have multiple ideas, please write multiple posts so we can be clear about updates/status.
Unique - if a similar idea has already been published, please vote and comment on that idea instead of publishing a duplicate.
Elaborate - explain your idea fully and clearly e.g., avoid generalities like ‘more customization’, and instead detail exactly what customizations and functionality you’re interested in having on your device or in the app.`,
  },
});
