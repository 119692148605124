import { defineMessages } from 'react-intl';

import { DETAILED_MARKS } from 'react/business/review/reviews.constants';

export default defineMessages({
  MARK_LABEL: {
    id: 'reviews.form.markLabel',
    defaultMessage: 'Mark',
  },

  [DETAILED_MARKS.EASY_TO_USE]: {
    id: 'reviews.form.easyToUse',
    defaultMessage: 'Easy to use',
  },
  [DETAILED_MARKS.MEETS_NEEDS]: {
    id: 'reviews.form.meetsTheNeeds',
    defaultMessage: 'Meets the needs',
  },
  [DETAILED_MARKS.PERFORMANCE_STABILITY]: {
    id: 'reviews.form.performanceStability',
    defaultMessage: 'Performance/Stability',
  },
  [DETAILED_MARKS.USER_ASSISTANCE]: {
    id: 'reviews.form.userAssistance',
    defaultMessage: 'User assistance',
  },

  COMMENT_PLACEHOLDER: {
    id: 'reviews.form.commentPlaceholder',
    defaultMessage: `Your feedback is important: a quality feedback contains enough details to give to the Product Team a clear view of the qualities and defects of the solution.
Explain what usages, what features contributed to your user experience, whether positive or negative.`,
  },
});
