import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Card from 'react/generic/card/Card';

import classNames from './messageCard.module.scss';

export default class MessageCard extends React.PureComponent {
  static propTypes = {
    compact: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    compact: false,
    className: null,
  };

  /** @returns {object} JSX. */
  render() {
    const {
      className,
      compact,
      ...props
    } = this.props;

    return (
      <Card
        {...props}
        className={cn(
          compact && classNames.compact,
          classNames.container,
          className,
        )}
      />
    );
  }
}
