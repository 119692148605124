import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { productShape } from 'shapes/product';

import Asset, { ICON_MAP } from 'react/generic/asset/Asset';
import FillPicture from 'react/generic/fillPicture/FillPicture';

import classNames from './product-logo.module.scss';

const ProductLogo = ({
  product,
  className,
}) => (
  product?.logo?.url ? (
    <FillPicture
      src={product.logo.url}
      className={className}
      alt={`${product.name} logo`}
    />
  ) : (
    <div className={cn(className, classNames.emptyContainer)}>
      <Asset name={ICON_MAP.box} className={classNames.emptyIcon} />
    </div>
  )
);

ProductLogo.displayName = 'ProductLogo';

ProductLogo.propTypes = {
  product: productShape,
  className: PropTypes.string,
};

ProductLogo.defaultProps = {
  product: null,
  className: null,
};

export default ProductLogo;
