import {
  compose,
  branch,
  renderComponent,
  withHandlers,
  withState,
} from 'recompose';

const STEPS = {
  FORM: 'form',
  SUCCESS: 'success',
  FAILURE: 'failure',
};

export default (SuccessComponent, FailureComponent) => compose(
  withState(
    'step',
    'onChangeStep',
    STEPS.FORM,
  ),

  withHandlers({
    onGoToFormStep: ({ onChangeStep }) => () => onChangeStep(STEPS.FORM),
    onGoToSuccessStep: ({ onChangeStep }) => () => onChangeStep(STEPS.SUCCESS),
    onGoToFailureStep: ({ onChangeStep }) => () => onChangeStep(STEPS.FAILURE),
  }),

  branch(
    props => props.step === STEPS.SUCCESS,
    renderComponent(SuccessComponent),
  ),

  branch(
    props => props.step === STEPS.FAILURE,
    renderComponent(FailureComponent),
  ),
);
