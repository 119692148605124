import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import cn from 'classnames';

import classNames from './fieldTitle.module.scss';

const enhancer = compose(
  memo,
);

const FieldTitle = ({ className, children, ...props }) => (
  <h3
    {...props}
    className={cn(className, classNames.fieldTitle)}
  >
    {children}
  </h3>
);

FieldTitle.displayName = 'FieldTitle';

FieldTitle.propTypes = {
  // Heading must have content.
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

FieldTitle.defaultProps = {
  className: null,
};

export default enhancer(FieldTitle);
