import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import cn from 'classnames';

import classNames from './main.module.scss';

const enhancer = compose(
  memo,
);

const MessageCardMain = ({ className, compact, ...props }) => (
  <main
    {...props}
    className={cn(
      classNames.main,
      className,
      compact && classNames.compact,
    )}
  />
);

MessageCardMain.displayName = 'MessageCardMain';

MessageCardMain.propTypes = {
  compact: PropTypes.bool,
  className: PropTypes.string,
};

MessageCardMain.defaultProps = {
  compact: false,
  className: null,
};

export default enhancer(MessageCardMain);
