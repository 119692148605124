import PropTypes from 'prop-types';
import moment from 'moment';
import { messageShape } from './vendor';

export const dateShape = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.instanceOf(Date),
  PropTypes.instanceOf(moment),
]);

export const navigationElementShape = PropTypes.shape({
  value: PropTypes.string,
  label: messageShape,
  logo: PropTypes.string,
  count: PropTypes.number,
});

export const externalImageShape = PropTypes.shape({
  id: PropTypes.string,
  url: PropTypes.string,
  alt: PropTypes.string,
});
