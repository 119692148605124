import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Rate as RateAntd } from 'antd';
import cn from 'classnames';

import classNames from './rate.module.scss';

export default class Rate extends PureComponent {
  static propTypes = {
    // value: PropTypes.number.isRequired,
    /** Root element class name. */
    className: PropTypes.string,
    /** Class implementing the rate scss mixin. */
    overrideClassName: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    overrideClassName: null,
  };

  /** @returns {object} JSX. */
  render() {
    const {
      // value,
      className,
      overrideClassName,
      ...props
    } = this.props;

    return (
      <RateAntd
        {...props}
        className={cn(
          className,
          classNames.rate,
          overrideClassName,
          !overrideClassName && classNames.default,
        )}
      />
    );
  }
}
