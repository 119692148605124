import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import cn from 'classnames';

import { VOTES } from 'config/constants';
import globalMessages from 'config/global.messages';
import { helpfulShape } from 'shapes/product';

import { FormattedMessage } from 'react-intl';
import Asset, { ICON_MAP } from 'react/generic/asset/Asset';

import classNames from './helpful.module.scss';

export default class Helpful extends React.PureComponent {
  static propTypes = {
    /** Id of the review or reply. */
    messageId: PropTypes.string.isRequired,
    helpful: helpfulShape,
    className: PropTypes.string,
    votesContainerClassName: PropTypes.string,
    onVote: PropTypes.func,
    label: PropTypes.node,
  };

  static defaultProps = {
    helpful: {},
    className: null,
    votesContainerClassName: null,
    onVote: noop,
    label: <FormattedMessage {...globalMessages.HELPFUL} />,
  };

  /**
   * Called when users clicks on +1 for the message.
   *
   * @returns {any} Props onVote return value.
   */
  onVoteUp = () => this.props.onVote(
    this.props.messageId,
    this.props.helpful?.userVote !== VOTES.UPVOTE ?
      VOTES.UPVOTE
      : VOTES.REMOVE,
  );

  /**
   * Called when users clicks on -1 for the message.
   *
   * @returns {any} Props onVote return value.
   */
  onVoteDown = () => this.props.onVote(
    this.props.messageId,
    this.props.helpful?.userVote !== VOTES.DOWNVOTE ?
      VOTES.DOWNVOTE
      : VOTES.REMOVE,
  );

  /** @returns {object} JSX. */
  render() {
    const {
      helpful,
      className,
      votesContainerClassName,
      label,
    } = this.props;

    return (
      <div className={cn(classNames.helpfulContainer, className)}>
        <div className={cn(classNames.votesContainer, votesContainerClassName)}>
          {label && (
            <div className={classNames.textContainer}>
              {label}
            </div>
          )}

          <button
            type="button"
            className={cn('unstyled', classNames.voteButton)}
            onClick={this.onVoteUp}
          >
            <div className={cn(
              classNames.helpfulIconContainer,
              helpful?.userVote === VOTES.UPVOTE && classNames.myVote,
            )}
            >
              <Asset
                className={classNames.helpfulIconUp}
                name={ICON_MAP.thumb_up}
              />
            </div>
            {helpful.up}
          </button>

          <button
            type="button"
            className={cn('unstyled', classNames.voteButton)}
            onClick={this.onVoteDown}
          >
            <div className={cn(
              classNames.helpfulIconContainer,
              helpful?.userVote === VOTES.DOWNVOTE && classNames.myVote,
            )}
            >
              <Asset
                className={classNames.helpfulIconDown}
                name={ICON_MAP.thumb_down}
              />
            </div>
            {helpful.down}
          </button>
        </div>
      </div>
    );
  }
}
