import React, { memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import { compose } from 'recompose';
import { userShape } from 'shapes/user';
import cn from 'classnames';
import { Popover } from 'antd';

import withNotificationsUnreadCountConnect
  from 'react/business/notifications/connect/withNotificationsUnreadCount.connect';
import withIsGetUnreadNotificationsCountLoadingConnect
  from 'react/business/notifications/connect/withIsGetUnreadNotificationsCountLoading.connect';
import UserMenu from 'react/business/user/userMenu/UserMenu';

import withCurrentUser from 'react/business/user/withCurrentUser/withCurrentUser.connect';
import Badge from 'react/generic/badge/Badge';

import Asset, { ICON_MAP } from 'react/generic/asset/Asset';
import classNames from './dashboardbutton.module.scss';

const enhancer = compose(
  withCurrentUser,
  withNotificationsUnreadCountConnect,
  withIsGetUnreadNotificationsCountLoadingConnect,
  memo,
);

export const DashboardButtonPresentation = ({
  className,
  notificationsUnreadCount,
  isGetUnreadNotificationsCountLoading,
  isBelowMediumBreakpoint,
  user,
}) => {
  const [isMenuDisplayed, setIsMenuDisplayed] = useState(false);

  const handleClick = useCallback(
    () => setIsMenuDisplayed(!isMenuDisplayed),
    [isMenuDisplayed],
  );

  return (
    <div
      className={cn(
        classNames.container,
        className,
      )}
    >
      <Popover
        content={(
          <UserMenu
            notificationsUnreadCount={notificationsUnreadCount}
            isGetUnreadNotificationsCountLoading={isGetUnreadNotificationsCountLoading}
            onClick={handleClick}
          />
        )}
        trigger="click"
        visible={isMenuDisplayed}
        onVisibleChange={setIsMenuDisplayed}
        className={classNames.mySpace}
        overlayClassName={classNames.userMenu}
        placement="bottomRight"
        arrowPointAtCenter
      >
        <div className={classNames.mySpaceContainer}>
          <div className={classNames.iconContainer}>
            <Asset
              name={ICON_MAP.user_menu}
              className={classNames.mySpaceIcon}
            />
            { (notificationsUnreadCount > 0 || isGetUnreadNotificationsCountLoading)
              && (
                <Badge
                  text=""
                  isLoading={isGetUnreadNotificationsCountLoading}
                  className={classNames.notificationsLinkContainer}
                />
              ) }
          </div>
          {
            !isBelowMediumBreakpoint && (
              <div className={classNames.userName}>{user.firstName}</div>
            )
          }
        </div>
      </Popover>
    </div>
  );
};

DashboardButtonPresentation.displayName = 'DashboardButtonPresentation';

DashboardButtonPresentation.propTypes = {
  className: PropTypes.string,
  /** From withNotificationsUnreadCount. */
  notificationsUnreadCount: PropTypes.number,
  /** From withIsGetUnreadNotificationsCountLoading. */
  isGetUnreadNotificationsCountLoading: PropTypes.bool,
  isBelowMediumBreakpoint: PropTypes.bool,
  user: userShape.isRequired,
};

DashboardButtonPresentation.defaultProps = {
  className: '',
  notificationsUnreadCount: 0,
  isGetUnreadNotificationsCountLoading: false,
  isBelowMediumBreakpoint: false,
};

const enhancedProps = ['user', 'notificationsUnreadCount', 'isGetUnreadNotificationsCountLoading'];

const DashboardButton = enhancer(DashboardButtonPresentation);
DashboardButton.displayName = 'DashboardButton';
DashboardButton.propTypes = omit(DashboardButtonPresentation.propTypes, enhancedProps);
DashboardButton.defaultProps = omit(DashboardButtonPresentation.defaultProps, enhancedProps);

export default DashboardButton;
