import { withStateHandlers } from 'recompose';

export default withStateHandlers(
  () => ({
    isWarningDisplayed: false,
  }),
  {
    onShowWarning: () => () => ({
      isWarningDisplayed: true,
    }),

    onHideWarning: () => () => ({
      isWarningDisplayed: false,
    }),
  },
);
