import { uniqueId, noop } from 'lodash';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  withPropsOnChange,
  withStateHandlers,
} from 'recompose';
import cn from 'classnames';

import { FormattedMessage } from 'react-intl';

import FormattedMessageChildren from 'react/generic/i18n/FormattedMessageChildren';
import Button from 'react/generic/button/Button';
import Rate from 'react/generic/rate/Rate';
import Wysiwyg from 'react/generic/form/wysiwyg/Wysiwyg';
import Products from 'react/business/products/form/autocompletes/Products';
import productMessages from 'react/business/products/form/autocompletes/product.messages';
import Asset, { ICON_MAP } from 'react/generic/asset/Asset';

import withMediumBreakpoint from 'react/hoc/withMediumBreakpoint';

import { detailedMarkShape, detailedMarksFieldIdsShape } from 'shapes/review';

import { DETAILED_MARKS } from 'react/business/review/reviews.constants';
import messages from './fields.messages';
import classNames from './fields.module.scss';

const enhancer = compose(
  withMediumBreakpoint,
  // Create a markFieldId when creating the component.
  withPropsOnChange(
    () => false,
    () => ({
      markFieldId: uniqueId('mark'),
      detailedMarksFieldIds: {
        [DETAILED_MARKS.EASY_TO_USE]: uniqueId('mark'),
        [DETAILED_MARKS.MEETS_NEEDS]: uniqueId('mark'),
        [DETAILED_MARKS.PERFORMANCE_STABILITY]: uniqueId('mark'),
        [DETAILED_MARKS.USER_ASSISTANCE]: uniqueId('mark'),
      },
    }),
  ),

  withStateHandlers(
    ({ isBelowMediumBreakpoint }) => ({
      shouldShowDetailedMarks: isBelowMediumBreakpoint,
    }),
    {
      onShowDetailedMarks: ({ shouldShowDetailedMarks }) => (event) => {
        event.preventDefault();
        return { shouldShowDetailedMarks: !shouldShowDetailedMarks };
      },
    },
  ),

  memo,
);

const compactToolbarOptions = {
  options: ['inline', 'list', 'history'],
  inline: {
    inDropdown: false,
    options: ['bold', 'italic', 'underline'],
  },
  list: {
    inDropdown: false,
    options: ['unordered', 'ordered'],
  },
  history: {
    inDropdown: false,
    options: ['undo', 'redo'],
  },
};

const ReviewFormFields = ({
  contentDecorator,
  markDecorator,
  productIdDecorator,
  markFieldId,
  detailedMarks,
  detailedMarksFieldIds,
  showProductField,
  isBelowMediumBreakpoint,
  shouldShowDetailedMarks,
  onShowDetailedMarks,
}) => {
  const icon = shouldShowDetailedMarks ? ICON_MAP.minus : ICON_MAP.plus;

  return (
    <>
      { (showProductField) && (
        <FormattedMessageChildren {...productMessages.SELECT_PRODUCT_PLACEHOLDER}>
          { placeholder => productIdDecorator(
            <Products placeholder={placeholder} />,
          ) }
        </FormattedMessageChildren>
      ) }
      <div className={classNames.markContainer}>
        <label
          htmlFor={markFieldId}
          className={classNames.markLabel}
        >
          <FormattedMessage {...messages.MARK_LABEL} />
        </label>

        { markDecorator(
          <Rate
            id={markFieldId}
            overrideClassName={classNames.markRate}
          />,
        ) }
        { isBelowMediumBreakpoint && (
          <Button
            overrideClassName={classNames.circle}
            onClick={onShowDetailedMarks}
          >
            <Asset name={icon} />
          </Button>
        ) }
      </div>

      <div
        className={cn(
          classNames.detailedMarks,
          !shouldShowDetailedMarks && classNames.detailedMarksHidden,
        )}
      >
        {(detailedMarks || []).map(({ name, decorator }) => (
          <div key={name} className={classNames.markContainer}>
            <label
              className={classNames.markLabel}
            >
              <FormattedMessage {...messages[DETAILED_MARKS[name]]} />
            </label>

            {decorator(
              <Rate
                id={detailedMarksFieldIds[DETAILED_MARKS[name]]}
                overrideClassName={classNames.markRate}
              />,
            )}
          </div>
        ))}
      </div>

      <div className={classNames.commentContainer}>
        <FormattedMessageChildren {...messages.COMMENT_PLACEHOLDER}>
          { placeholder => contentDecorator(
            <Wysiwyg
              editorClassName={classNames.comment}
              placeholder={placeholder}
              toolbar={isBelowMediumBreakpoint ? compactToolbarOptions : {}}
            />,
          ) }
        </FormattedMessageChildren>
      </div>
    </>
  );
};

ReviewFormFields.displayName = 'ReviewFormFields';

ReviewFormFields.propTypes = {
  contentDecorator: PropTypes.func.isRequired,
  markDecorator: PropTypes.func.isRequired,
  productIdDecorator: PropTypes.func.isRequired,
  markFieldId: PropTypes.string.isRequired,
  detailedMarks: PropTypes.arrayOf(detailedMarkShape),
  detailedMarksFieldIds: detailedMarksFieldIdsShape,
  showProductField: PropTypes.bool,
  isBelowMediumBreakpoint: PropTypes.bool,
  shouldShowDetailedMarks: PropTypes.bool,
  onShowDetailedMarks: PropTypes.func,
};

ReviewFormFields.defaultProps = {
  detailedMarks: [],
  detailedMarksFieldIds: null,
  showProductField: false,
  isBelowMediumBreakpoint: false,
  shouldShowDetailedMarks: true,
  onShowDetailedMarks: noop,
};

export default enhancer(ReviewFormFields);
