import { defineMessages } from 'react-intl';

export default defineMessages({
  MAINTAINED_BY_PLATFORM: {
    id: 'product.card.description.maintained',
    defaultMessage: 'Maintained by platform',
  },
  HIGLIGHTED_COMPONENTS: {
    id: 'product.card.description.components_highlight',
    defaultMessage: 'Found in a component',
  },
});
