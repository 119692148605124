import { noop } from 'lodash';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers, renameProp } from 'recompose';

import omitProps from 'react/hoc/omitProps';
import { reviewShape } from 'shapes/product';

import Dialog from 'react/generic/dialog/Dialog';
import withFormSteps from 'react/generic/form/withFormSteps';

import classNames from './review-form-dialog.module.scss';

export default (
  FormStep,
  SuccessStep,
  FailureStep,
) => {
  const FormWithSteps = compose(
  // Add form steps state and goToX step handlers.
    withFormSteps(
      ({ onRequestClose }) => (<SuccessStep onClickClose={onRequestClose} />),
      ({ onGoToFormStep }) => (<FailureStep onClickTryAgain={onGoToFormStep} />),
    ),

    // Override parent onSubmitSuccess to also change the step of the form.
    withHandlers({
      onSubmitSuccess: ({ onGoToSuccessStep, onSubmitSuccess }) => () => {
        onGoToSuccessStep();
        return onSubmitSuccess?.();
      },
    }),

    // Do not expose onGoToSuccessStep (overriden above).
    omitProps(['onGoToSuccessStep']),
    // Add onSubmitFailure method to redirect to the failure step.
    renameProp('onGoToFailureStep', 'onSubmitFailure'),
  )(FormStep);

  const enhancer = compose(
    memo,
  );

  const ReviewFormDialog = ({
    productId,
    onSubmitSuccess,
    review,
    fromHome,
    ...props
  }) => (
    <Dialog
      {...props}
      contentClassName={classNames.dialog}
    >
      <FormWithSteps
        productId={productId}
        onSubmitSuccess={onSubmitSuccess}
        onRequestClose={props.onRequestClose}
        review={review}
        fromHome
      />
    </Dialog>
  );

  ReviewFormDialog.displayName = 'ReviewFormDialog';

  ReviewFormDialog.propTypes = {
    productId: PropTypes.string,
    onSubmitSuccess: PropTypes.func,
    onRequestClose: PropTypes.func,
    fromHome: PropTypes.bool,
    review: reviewShape,
  };

  ReviewFormDialog.defaultProps = {
    productId: '',
    onSubmitSuccess: noop,
    onRequestClose: noop,
    fromHome: false,
    review: null,
  };

  return enhancer(ReviewFormDialog);
};
