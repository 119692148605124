import { defineMessages } from 'react-intl';

export default defineMessages({
  REVIEW_FORM_DIALOG_TITLE: {
    id: 'review.form.dialog.create.title',
    defaultMessage: 'Give your feedback too!',
  },

  SUBMIT: {
    id: 'review.form.dialog.create.submit',
    defaultMessage: 'Send my feedback',
  },
});
