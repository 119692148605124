import PropTypes from 'prop-types';

export const singleValueShape = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.instanceOf(Symbol),
]);

export const valueShape = PropTypes.oneOfType([
  singleValueShape,
  PropTypes.arrayOf(singleValueShape),
]);

export const optionShape = PropTypes.shape({
  value: valueShape,
  label: PropTypes.node,
});

export const optionsShape = PropTypes.arrayOf(optionShape);
