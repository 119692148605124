import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import cn from 'classnames';

import { dateShape } from 'shapes/generic';

import RelativeDate from 'react/generic/date/relative/RelativeDate';

import classNames from './date.module.scss';

const enhancer = compose(
  memo,
);

const MessageDate = ({ date, className, ...props }) => (
  <div {...props} className={cn(className, classNames.date)}>
    <RelativeDate date={date} />
  </div>
);

MessageDate.displayName = 'MessageDate';

MessageDate.propTypes = {
  date: dateShape,
  className: PropTypes.string,
};

MessageDate.defaultProps = {
  date: null,
  className: null,
};

export default enhancer(MessageDate);
