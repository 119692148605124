import { lifecycle, compose } from 'recompose';
import connect from 'react/hoc/connectProxy';

import {
  getAllMaintainedProductsCount as getAllMaintainedProductsCountAction,
  getAllProductsCount as getAllProductsCountAction,
  getLatestProducts as getLatestProductsAction,
} from 'redux/products/actions';
import { getAllReviewsCount as getAllReviewsCountAction } from 'redux/reviews/actions';

const mapDispatchToProps = {
  getAllProductsCount: getAllProductsCountAction,
  getAllMaintainedProductsCount: getAllMaintainedProductsCountAction,
  getAllReviewsCount: getAllReviewsCountAction,
  getLatestProducts: getLatestProductsAction,
};

export const withFetchProductsAndReviewsTotalCount = compose(
  connect(null, mapDispatchToProps),
  lifecycle({
    /**
     * On mount, fetch all products count.
     */
    componentDidMount() {
      const {
        getAllProductsCount, getAllReviewsCount, getAllMaintainedProductsCount,
      } = this.props;
      getAllProductsCount();
      getAllMaintainedProductsCount();
      getAllReviewsCount();
    },
  }),
);

export const withFetchLatestProducts = compose(
  connect(null, mapDispatchToProps),
  lifecycle({
    /**
     * On mount, fetch latest created products.
     */
    componentDidMount() {
      const { getLatestProducts } = this.props;
      getLatestProducts();
    },
  }),
);
