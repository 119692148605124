import { omit } from 'lodash';
import connect from 'react/hoc/connectProxy';
import { compose, mapProps } from 'recompose';

import makeLazyLoader from 'react/hoc/lazyLoader.hoc';
import { bufferedGetUsers } from 'redux/users/actions';
import { selectUserIdsLoadingList, selectUsersIdsList } from 'redux/users/selectors';

export default ({
  // Rename the idsLoading and idsLoaded props if the names collide with your own.
  propNamesMapper = {
    idsLoading: 'idsLoading',
    idsLoaded: 'idsLoaded',
  },

  // Omit the lazy loader props for the wrapped component.
  shouldOmitLazyLoaderProps = true,

  ...config
}) => compose(
  ...[
    // Fetch the loading and loaded items from the props.
    connect(
      state => ({
        [propNamesMapper.idsLoading]: selectUserIdsLoadingList(state),
        [propNamesMapper.idsLoaded]: selectUsersIdsList(state),
      }),
      { getUsers: bufferedGetUsers },
    ),

    // Create the lazy loader hoc.
    makeLazyLoader({
      fetchItemsFromIds: (idsToFetch, props) => props.getUsers(idsToFetch),
      getIdsLoadingFromProps: props => props[propNamesMapper.idsLoading],
      getIdsLoadedFromProps: props => props[propNamesMapper.idsLoaded],
      ...config,
    }),

    // If necessary, omit the loading and loaded props.
    shouldOmitLazyLoaderProps && mapProps(props => omit(
      props,
      [
        propNamesMapper.idsLoading,
        propNamesMapper.idsLoaded,
      ],
    )),
  ].filter(Boolean),
);
