import React from 'react';
import {
  compose, branch, renderComponent,
} from 'recompose';

import BigProductRibbon from './BigProductRibbon';
import BigProductTooltip from '../tooltips/BigProductTooltip';

const BigProductRibbonSwitch = compose(
  branch(
    props => props.product?.parentDigitalProduct === props.product.id
      || !props.product.parentDigitalProduct,
    renderComponent(() => (
      <BigProductTooltip placement="top">
        <BigProductRibbon />
      </BigProductTooltip>
    )),
  ),
)(() => null);

BigProductRibbonSwitch.displayName = 'BigProductRibbonSwitch';

export default BigProductRibbonSwitch;
