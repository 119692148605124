import { pickBy } from 'lodash';
import { createSelector } from 'reselect';
import { selectCurrentUser } from 'redux/users/selectors';
import { userCanDeleteReply } from 'services/replies';

/**
 * Get a map of replies.
 *
 * @param {object} state - State.
 * @param {string} productId - Id of the product holding the reviews.
 * @param {string} reviewId - Id of the review holding the replies.
 * @returns {object} Map of all replies (flat).
 */
export const selectRepliesMapById = (
  state,
  productId,
  reviewId,
) => state.replies.map[productId]?.[reviewId];

/**
 * Get a map of replies to a specific reply.
 *
 * @param {object} state - State.
 * @param {string} productId - Id of the product holding the reviews.
 * @param {string} reviewId - Id of the review holding the replies.
 * @param {string} replyId - Id of the reply holding the replies.
 * @returns {object} Filtered map.
 */
export const selectFilteredRepliesMapById = createSelector(
  [
    selectRepliesMapById,
    (state, productId, reviewId, replyId = null) => replyId,
  ],
  (repliesMap, replyId) => pickBy(repliesMap, reply => reply.reply === replyId),
);

/**
 * Get a list of replies.
 */
export const selectRepliesListById = createSelector(
  [
    selectRepliesMapById,
  ],
  repliesMap => Object.values(repliesMap || {}),
);

/**
 * Get a list of replies to a specific reply.
 */
export const selectFilteredRepliesListById = createSelector(
  [
    selectFilteredRepliesMapById,
  ],
  repliesMap => Object.values(repliesMap || {}),
);

/**
 * Get a reply by its id.
 *
 * @param {object} state - State.
 * @param {string} productId - Id of the product holding the review.
 * @param {string} reviewId - Id of the review holding the reply.
 * @param {string} replyId - Id of the reply to get.
 * @returns {object} The reply.
 */
export const selectReplyById = (
  state,
  productId,
  reviewId,
  replyId,
) => selectRepliesMapById(state, productId, reviewId)?.[replyId];

export const isLoadingGetReplies = state => !!state.replies.isLoading.getReplies;
export const isLoadingGetReply = state => !!state.replies.isLoading.getReply;
export const isLoadingCreateReply = state => !!state.replies.isLoading.createReply;
export const isLoadingVoteReply = state => !!state.replies.isLoading.voteReply;
export const isLoadingDeleteReply = state => !!state.replies.isLoading.deleteReply;

export const isLoadingReplies = createSelector(
  [
    isLoadingGetReplies,
    isLoadingGetReply,
    isLoadingCreateReply,
    isLoadingVoteReply,
    isLoadingDeleteReply,
  ],
  (...args) => args.some(v => v),
);

export const selectCanUserDeleteReply = createSelector(
  [
    (state, props) => state.replies.map[props.productId][props.reply.review],
    (state, props) => props.reply,
    selectCurrentUser,
  ],
  (replies, reply, user) => userCanDeleteReply(replies, reply, user?.id),
);
