import React, { memo } from 'react';
import { compose, withProps } from 'recompose';
import { FormattedMessage } from 'react-intl';

import omitProps from 'react/hoc/omitProps';
import { deleteProductReview } from 'redux/reviews/actions';
import { getProduct } from 'redux/products/actions';
import { ACTIONS as REVIEWS_ACTIONS } from 'redux/reviews/constants';
import { isLoadingReviews } from 'redux/reviews/selectors';
import connect from 'react/hoc/connectProxy';

import messages from 'react/business/review/delete/dialog/base/delete-confirmation-dialog.messages';
import { withRouter } from 'react-router';
import { redirectFromSingleMessage } from 'services/functions/redirectFromSingleMessage';

export default compose(
  withRouter,
  memo,
  connect(
    state => ({
      isLoading: isLoadingReviews(state),
    }),
    (dispatch, props) => ({
      onRequestDelete: async () => {
        const action = await dispatch(deleteProductReview(
          props.productId,
          props.reviewId,
        ));

        if (action.type === REVIEWS_ACTIONS.DELETE_PRODUCT_REVIEW_SUCCESS) {
          redirectFromSingleMessage(props);

          // Fetch the product to see marks refresh.
          await dispatch(getProduct(props.productId));

          return props.onDeleteSuccess?.();
        }

        return props.onDeleteFailure?.();
      },
    }),
  ),

  withProps({
    title: <FormattedMessage {...messages.FEEDBACK_DELETE_DIALOG_TITLE} />,
    content: <FormattedMessage {...messages.FEEDBACK_DELETE_DIALOG_CONTENT} />,
  }),

  omitProps(['productId', 'reviewId', 'onDeleteSuccess', 'onDeleteFailure']),
);
