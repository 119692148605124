import React, { memo } from 'react';
import { compose } from 'recompose';

import Link from 'react/generic/link/Link';
import LogoSvg from 'style/imgs/branding/horizontal-white.svg';

import classNames from './logo.module.scss';

const enhance = compose(
  memo,
);

const Logo = enhance(() => (
  <Link
    to="/"
    className={classNames.link}
  >
    <LogoSvg className={classNames.logo} />
  </Link>
));

Logo.displayName = 'Logo';

export default Logo;
