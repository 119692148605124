import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import FieldTitle from './title/FieldTitle';
import FieldError from './error/FieldError';
import FieldWarning from './warning/FieldWarning';
import FieldTooltip from './tooltip/FieldTooltip';

import classNames from './field.module.scss';

const enhancer = compose(
  memo,
);

const Field = ({
  title,
  tooltip,
  error,
  warning,
  children,
  specific,
}) => (
  <div className={classNames.field}>
    { (title || error || warning || tooltip)
      && (
        <div className={classNames.header}>
          <div className={classNames.left}>
            { title
              && (
                <div className={classNames.titleContainer}>
                  <FieldTitle>{title}</FieldTitle>
                </div>
              ) }

            { tooltip
              && (
                <div className={classNames.tooltipContainer}>
                  <FieldTooltip body={tooltip} specific={specific} />
                </div>
              ) }
          </div>

          { error
            && <FieldError>{error}</FieldError>}

          { warning && !error
            && <FieldWarning>{warning}</FieldWarning>}
        </div>
      ) }

    {children}
  </div>
);

Field.displayName = 'Field';

Field.propTypes = {
  title: PropTypes.node,
  tooltip: PropTypes.node,
  error: PropTypes.node,
  warning: PropTypes.node,
  children: PropTypes.node,
  specific: PropTypes.bool,
};

Field.defaultProps = {
  title: null,
  tooltip: null,
  error: null,
  warning: null,
  children: null,
  specific: false,
};

export default enhancer(Field);
