import { flatten } from 'lodash';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import cn from 'classnames';

import classNames from './coverPicture.module.scss';

/** Picture as cover background image. */
export default class CoverPicture extends PureComponent {
  static propTypes = {
    /** Root element className. */
    className: PropTypes.string,
    /** Root element style. */
    style: PropTypes.shape({}),
    /** Src of the picture. */
    src: PropTypes.oneOfType([
      /** Single src. */
      PropTypes.string,
      /** Src and fallbacks. */
      PropTypes.arrayOf(PropTypes.string),
    ]),
  };

  static defaultProps = {
    className: null,
    style: null,
    src: null,
  };

  makeStyle = memoize((style, src) => ({
    ...style,
    backgroundImage: flatten([src])
      .filter(Boolean)
      .map(s => `url(${s})`)
      .join(', '),
  }));

  /** @returns {object} JSX. */
  render() {
    const {
      src,
      style,
      className,
      ...props
    } = this.props;

    return (
      <div
        {...props}
        className={cn(className, classNames.cover)}
        style={this.makeStyle(style, src)}
      />
    );
  }
}
