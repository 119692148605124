import { noop } from 'lodash';
import React, { PureComponent } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { addLayer, removeLayer } from 'services/layers';

import classNames from './layer.module.scss';

export default class Layer extends PureComponent {
  static propTypes = {
    /** Is the dialog open. */
    isOpen: PropTypes.bool,
    /** Should request to close the dialog when pressing escape. */
    shouldCloseOnEscape: PropTypes.bool,
    /** Should close the modal when clicking on the overlay. */
    shouldCloseOnClickOverlay: PropTypes.bool,
    /** Animation duration in ms. */
    animationDuration: PropTypes.number,
    /** DOM element where to create the portal. */
    // DOM element is an object.
    // eslint-disable-next-line react/forbid-prop-types
    portalElement: PropTypes.object,
    /** Root element className. */
    className: PropTypes.func,
    /** Overlay root element className. */
    overlayClassName: PropTypes.func,
    /** Called when requesting to close the dialog. */
    onRequestClose: PropTypes.func,
    /** Called when the exit animation is done and the content is unmounted. */
    onDidClose: PropTypes.func,
    /** Dialog content. */
    children: PropTypes.func,
  };

  static defaultProps = {
    isOpen: false,
    shouldCloseOnEscape: true,
    shouldCloseOnClickOverlay: true,
    animationDuration: 0,
    portalElement: null,
    className: noop,
    overlayClassName: noop,
    onRequestClose: noop,
    onDidClose: noop,
    children: noop,
  };

  state = {
    isOpen: this.props.isOpen,
    isClosing: false,
  };

  layerId = null;

  closingTimeoutHandle = null;

  /**
   * Add layer.
   */
  componentDidMount() {
    if (this.props.isOpen) {
      this.onOpen();
    }
  }

  /**
   * Add or remove layer if the isOpen changed.
   *
   * @param {object} prevProps - Previous props.
   */
  componentDidUpdate(prevProps) {
    if (this.props.isOpen !== prevProps.isOpen) {
      if (this.props.isOpen) {
        this.onOpen();
      } else {
        this.onClose();
      }
    }
  }

  /** Remove the layer if necessary. */
  componentWillUnmount() {
    if (this.layerId) {
      this.onClose(false);
    }

    clearTimeout(this.closingTimeoutHandle);
  }

  /** Add a layer and save the id. */
  onOpen() {
    this.setState({
      isOpen: true,
      isClosing: false,
    });

    this.layerId = addLayer({
      shouldCloseOnEscape: this.props.shouldCloseOnEscape,
      onRequestClose: this.props.onRequestClose,
    });
  }

  /**
   * Remove the layer and start the exit animation if necessary.
   *
   * @param {boolean} [withAnimation=true] - Launch closing animation.
   */
  onClose(withAnimation = true) {
    removeLayer(this.layerId);
    this.layerId = null;

    if (withAnimation) {
      this.setState({
        isClosing: true,
      });

      clearTimeout(this.closingTimeoutHandle);
      this.closingTimeoutHandle = setTimeout(
        () => {
          this.setState({
            isOpen: false,
            isClosing: false,
          });

          this.props.onDidClose();
        },
        this.props.animationDuration,
      );
    }
  }

  /**
   * Render the dialog in a portal.
   *
   * @returns {object} JSX.
   */
  render() {
    const {
      shouldCloseOnClickOverlay,
      onRequestClose,
      portalElement,
      className,
      overlayClassName,
      children,
    } = this.props;

    const {
      isOpen,
      isClosing,
    } = this.state;

    return createPortal(
      <div
        className={cn(
          className({ isOpen, isClosing }),
          classNames.container,
          isOpen && classNames.isOpen,
        )}
      >
        <button
          type="button"
          onClick={shouldCloseOnClickOverlay ? onRequestClose : undefined}
          className={cn(
            overlayClassName({ isOpen, isClosing }),
            classNames.overlay,
            shouldCloseOnClickOverlay && classNames.interactive,
          )}
        />

        {children({
          isOpen,
          isClosing,
        })}
      </div>,
      portalElement || document.getElementById('modal-root'),
    );
  }
}
