import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { LocaleProvider as AntdLocaleProvider } from 'antd';

export const localeBundleShape = PropTypes.shape({
  locale: PropTypes.string,
  messages: PropTypes.object,
  antdLocale: PropTypes.object,
});

const LocaleProvider = ({
  localeBundle,
  children,
  ...props
}) => {
  const { locale, messages, antdLocale } = localeBundle;
  return (
    <AntdLocaleProvider locale={antdLocale}>
      <IntlProvider
        {...props}
        locale={locale}
        messages={messages}
        key={locale}
      >
        {children}
      </IntlProvider>
    </AntdLocaleProvider>
  );
};

LocaleProvider.propTypes = {
  localeBundle: localeBundleShape.isRequired,
  children: PropTypes.node.isRequired,
};

export default LocaleProvider;
