import { createSelector } from 'reselect';

export const selectTipsAndNews = state => state.tipsAndNews?.data;

export const isLoadingGetTipsAndNews = state => !!state.tipsAndNews?.isLoading.getTipsAndNews;

export const isLoadingTipsAndNews = createSelector(
  [
    isLoadingGetTipsAndNews,
  ],
  (...args) => args.some(v => v),
);
