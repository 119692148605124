import { defineMessages } from 'react-intl';

export default defineMessages({
  PRODUCTS_COUNT: {
    id: 'business.products.count',
    defaultMessage: '{count, plural, =0 {no product} one {{count} product} other {{count} products}}',
  },

  KEY_USERS_COUNT: {
    id: 'business.users.keyUsers.count',
    defaultMessage: '{count, plural, =0 {no key user} one {{count} key user} other {{count} key users}}',
  },

  BUSINESS_ACTIVITIES: {
    id: 'business.businessActivities',
    defaultMessage: 'Business Activities',
  },
  MACRO_ACTIVITIES: {
    id: 'business.businessActivities.macroActivities',
    defaultMessage: 'Macro Activities',
  },
  ACTIVITIES: {
    id: 'business.businessActivities.activities',
    defaultMessage: 'Activities',
  },
  ATOMIC_ACTIVITIES: {
    id: 'business.businessActivities.atomicActivities',
    defaultMessage: 'Elementary Activities',
  },
  MACRO_ACTIVITIES_TOOLTIP: {
    id: 'business.businessActivities.macroActivities.tooltip',
    defaultMessage: 'A macro activity represents a business sector which gather several business activities.',
  },
  ACTIVITIES_TOOLTIP: {
    id: 'business.businessActivities.activities.tooltip',
    defaultMessage: 'An activity represents a merging of business gesture.',
  },
  ATOMIC_ACTIVITIES_TOOLTIP: {
    id: 'business.businessActivities.atomicActivities.tooltip',
    defaultMessage: 'An elementary activity corresponds to a business gesture.',
  },
  MODULE: {
    id: 'business.module',
    defaultMessage: 'Modules',
  },
  BUSINESS_UNIT: {
    id: 'business.businessUnit',
    defaultMessage: 'Business Unit',
  },
  ARCHITECTURE_BUILDING_BLOCKS: {
    id: 'business.abb',
    defaultMessage: 'Architecture Building Blocks',
  },
  SUBSETS: {
    id: 'business.subset',
    defaultMessage: 'Subsets',
  },
});
