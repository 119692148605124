import { compose, withProps } from 'recompose';

import { roundToHalfInteger } from 'services/math';
import Rate from './Rate';

export default compose(
  withProps(({ value }) => ({
    value: roundToHalfInteger(value),
  })),
)(Rate);
