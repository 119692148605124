import React, { memo, forwardRef } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { elementShape } from 'shapes/vendor';

import classNames from './html.module.scss';

const enhancer = compose(
  memo,
);

const HtmlContentViewer = forwardRef((
  {
    element,
    className,
    ...props
  },
  ref,
) => React.createElement(
  element,
  {
    ...props,
    ref,
    className: cn(className, classNames.html),
  },
));

HtmlContentViewer.displayName = 'HtmlContentViewer';

HtmlContentViewer.propTypes = {
  element: elementShape,
  className: PropTypes.string,
};

HtmlContentViewer.defaultProps = {
  element: 'div',
  className: null,
};

export default enhancer(HtmlContentViewer);
