import React from 'react';
import connect from 'react/hoc/connectProxy';
import { compose, lifecycle, withHandlers } from 'recompose';
import { withToastManager } from 'react-toast-notifications';

import { FormattedMessage } from 'react-intl';

import { ackAddToast } from 'redux/toasts/actions';
import { selectAddToastStack } from 'redux/toasts/selectors';

/**
 * Listens for addToast events and calls the Toast Manager.
 */
export default compose(
  withToastManager,

  connect(
    state => ({
      addToastStack: selectAddToastStack(state),
    }),
    {
      onAckAddToast: ackAddToast,
    },
  ),

  withHandlers({
    onHandleToasts: ({
      toastManager,
      addToastStack,
      onAckAddToast,
    }) => () => {
      addToastStack.forEach(({ toast, appToastId, meta }) => {
        toastManager.add(
          meta?.isIntlMsg ?
            <FormattedMessage {...toast.content} />
            : toast.content,
          toast.options,
        );
        onAckAddToast(appToastId);
      });
    },
  }),

  lifecycle({
    /**
     * Handle toasts stack on mount.
     */
    componentDidMount() {
      this.props.onHandleToasts();
    },

    /**
     * Handle toasts stack on update.
     */
    componentDidUpdate() {
      this.props.onHandleToasts();
    },
  }),
)(({ children }) => children);
