import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { fieldArrayFieldsPropTypes } from 'redux-form';
import { injectIntl, intlShape } from 'react-intl';
import cn from 'classnames';

import AddMoreButton from 'react/generic/form/ListField/add-more/AddMoreButton';
import { linkFactory } from 'services/products/products-form';
import { ITEM_TYPES } from 'react/generic/form/ListField/constants';
import makeListField from 'react/generic/form/ListField/ListField';
import FieldTooltip from 'react/generic/form/field/tooltip/FieldTooltip';

import { linksReferences } from 'react/business/products/links/product-link.constants';

import linkClassNames from 'react/business/products/links/product-link.module.scss';
import classNames from './links-form.module.scss';
import messages from './links-form.messages';
import LinksFormItem from './linksFormItem/LinksFormItem';

const ListField = makeListField(ITEM_TYPES.LINKS);

const LinksFormPresentation = ({
  links,
  urlFieldProps,
  titleFieldProps,
  title,
  linkName,
  showAddButton,
  intl,
  tooltip,
}) => {
  const onAdd = useCallback(
    () => {
      links.push(linkFactory());
    },
    [links],
  );

  const { icon, activeClassName } = linksReferences[linkName] || {};

  const displayTitle = links.length > 1;

  const newFieldProps = {
    intl,
    urlFieldProps,
    titleFieldProps,
    displayTitle,
  };

  return (
    <div>
      <div className={classNames.title}>
        <div className={cn(classNames.iconContainer,
          linkClassNames.iconCircleContainer,
          (activeClassName || linkClassNames.active))}
        >
          <div className={linkClassNames.iconContainer}>
            {React.cloneElement(
              icon,
              {
                className: cn(icon.props.className, linkClassNames.icon),
              },
            )}
          </div>
        </div>
        <span>
          {title}
        </span>
        {tooltip && (
          <span className={classNames.tooltipContainer}>
            <FieldTooltip body={tooltip} />
          </span>
        )}
      </div>

      <ListField
        fields={links}
        tooltip={tooltip}
        fieldProps={newFieldProps}
      >
        <LinksFormItem />
      </ListField>
      { showAddButton && (
        <div className={classNames.buttonContainer}>
          <AddMoreButton onClick={onAdd} message={messages.ADD_LINKS} />
        </div>
      ) }
    </div>
  );
};

LinksFormPresentation.displayName = 'LinksFormPresentation';

LinksFormPresentation.propTypes = {
  links: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
  linkName: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  urlFieldProps: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  titleFieldProps: PropTypes.object,
  title: PropTypes.node,
  tooltip: PropTypes.node,
  showAddButton: PropTypes.bool,
  intl: intlShape.isRequired,
};

LinksFormPresentation.defaultProps = {
  urlFieldProps: {},
  titleFieldProps: {},
  title: null,
  tooltip: null,
  showAddButton: true,
};

export default injectIntl(LinksFormPresentation);
