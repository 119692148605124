import PropTypes from 'prop-types';
import { compose } from 'recompose';
import connect from 'react/hoc/connectProxy';

import FollowButton from 'react/generic/followButton/FollowButton';

import {
  followProduct as followProductAction,
  unfollowProduct as unfollowProductAction,
  toggleFollowedProduct as toggleFollowedProductAction,
} from 'redux/users/actions';
import { setDisplayBannerFollowProduct as setDisplayBannerFollowProductAction } from 'redux/products/actions';
import {
  selectIsFollowProductLoading,
  selectIsProductFollowed,
} from 'redux/products/selectors';

const mapStateToProps = (state, props) => ({
  loading: selectIsFollowProductLoading(state, props.productId),
  isFollowed: props.selectIsProductFollowed ?
    props.selectIsProductFollowed(state, props.productId)
    : selectIsProductFollowed(state, props.productId),
});

const mapDispatchToProps = {
  followProduct: followProductAction,
  unfollowProduct: unfollowProductAction,
  setDisplayBannerFollowProduct: setDisplayBannerFollowProductAction,
  toggleFollowedProduct: toggleFollowedProductAction,
};

const mergeProps = (
  // state props (result of mapStateToProps)
  { isFollowed, loading, followersCount },
  // dispatch props
  {
    followProduct, unfollowProduct, setDisplayBannerFollowProduct, toggleFollowedProduct,
  },
  // own props
  {
    productId, productName, disabled, withToggle, ...ownProps
  },
) => ({
  ...ownProps,
  value: isFollowed,
  loading,
  count: followersCount,
  disabled: !productId || disabled,
  onChange: (wantToFollow) => {
    if (wantToFollow) {
      followProduct(productId);
      setDisplayBannerFollowProduct(true, productName);
    } else {
      unfollowProduct(productId);
      setDisplayBannerFollowProduct(false, '');
    }
    if (withToggle) {
      toggleFollowedProduct(productId);
    }
  },
});

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
);

const EnhancedFollowProductButton = enhancer(FollowButton);

EnhancedFollowProductButton.propTypes = {
  className: PropTypes.string,
  productId: PropTypes.string,
  productName: PropTypes.string,
  disabled: PropTypes.bool,
};

EnhancedFollowProductButton.defaultProps = {
  className: '',
  productId: '',
  productName: '',
  disabled: false,
};

export default EnhancedFollowProductButton;
