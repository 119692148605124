import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import cn from 'classnames';

import { FormattedMessage } from 'react-intl';
import Asset, { ICON_MAP } from '../asset/Asset';

import messages from './follow-button.messages';
import classNames from './followbutton.module.scss';

class FollowButton extends PureComponent {
  static propTypes = {
    /** Is user following? */
    value: PropTypes.bool,
    /** On change event handler. */
    onChange: PropTypes.func,
    /** Is action loading? */
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,

    className: PropTypes.string,
  };

  static defaultProps = {
    value: false,
    loading: false,
    disabled: false,
    onChange: noop,
    onClick: noop,

    className: '',
  };

  onClick = (e) => {
    const { value, onChange } = this.props;
    onChange(!value);
    this.props.onClick(e);
  };

  /**
   * Renders the component.
   *
   * @returns {object} - The React element.
   */
  render() {
    const {
      loading,
      value,
      disabled,
      className,
    } = this.props;

    return (
      <button
        type="button"
        className={cn(
          className,
          classNames.button,
          value && classNames.on,
        )}
        onClick={this.onClick}
        disabled={loading || disabled}
      >
        { value
          && (
            <Asset
              name={ICON_MAP.check_icon}
              className={classNames.icon}
              aria-hidden
            />
          ) }

        <div className={classNames.label}>
          <FormattedMessage
            {...(value ? messages.FOLLOWING : messages.FOLLOW)}
          />
        </div>
      </button>
    );
  }
}

export default FollowButton;
