import store from 'redux/store';
import { getCurrentUser } from 'redux/users/actions';
import { getNotifications, getUnreadNotificationsCount } from 'redux/notifications/actions';

export default () => {
  store.dispatch(getUnreadNotificationsCount());
  store.dispatch(getNotifications({ limit: 0 }));
  return Promise.all([
    store.dispatch(getCurrentUser()),
  ]);
};
