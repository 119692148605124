import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { elementShape } from 'shapes/vendor';
import withSafeHtml from './withSafeHtml';

import HtmlContentViewer from './HtmlContentViewer';

const enhancer = compose(
  withSafeHtml(),
  memo,
);

const SafeHtml = enhancer(({
  element,
  children,
  forwardRef,
  ...props
}) => React.createElement(
  element,
  {
    ...props,
    ref: forwardRef,
    dangerouslySetInnerHTML: { __html: children },
  },
));

SafeHtml.displayName = 'SafeHtml';

SafeHtml.propTypes = {
  /** Unsafe html content. */
  unsafeHtml: PropTypes.string,
  /** Element to render. */
  element: elementShape,
  /** Ref to forward to the content. */
  forwardRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

SafeHtml.defaultProps = {
  unsafeHtml: '',
  element: HtmlContentViewer,
  forwardRef: null,
};

export default SafeHtml;
