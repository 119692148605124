import { defineMessages } from 'react-intl';

export default defineMessages({
  PRODUCTS_SECTION_TITLE: { id: 'autocomplete.section.title.products', defaultMessage: 'Products' },
  PRODUCTS_SECTION_ACTION: { id: 'autocomplete.section.action.products', defaultMessage: 'Access product' },
  COMMUNITIES_SECTION_TITLE: { id: 'autocomplete.section.title.communities', defaultMessage: 'Platforms' },
  COMMUNITIES_SECTION_ACTION: { id: 'autocomplete.section.action.communities', defaultMessage: 'Access the products of this platform' },
  DOMAINS_SECTION_TITLE: { id: 'autocomplete.section.title.domains', defaultMessage: 'Domains' },
  DOMAINS_SECTION_ACTION: { id: 'autocomplete.section.action.domains', defaultMessage: 'Access the products of this domain' },
  BUSINESS_UNITS_SECTION_TITLE: { id: 'autocomplete.section.title.businessUnits', defaultMessage: 'Business units' },
  BUSINESS_UNITS_SECTION_ACTION: { id: 'autocomplete.section.action.businessUnits', defaultMessage: 'Access the products used by this business unit' },
  ACTIVITIES_SECTION_TITLE: { id: 'autocomplete.section.title.activities', defaultMessage: 'Business activities' },
  ACTIVITIES_SECTION_ACTION: { id: 'autocomplete.section.action.activities', defaultMessage: 'Access the products linked to this business activity' },
  ABB_SECTION_ACTION: { id: 'autocomplete.section.action.abb', defaultMessage: 'Access the products linked to this ABB' },
  SUBSET_SECTION_ACTION: { id: 'autocomplete.section.action.subset', defaultMessage: 'Access the products linked to this subset' },
  PERSONS_SECTION_TITLE: { id: 'autocomplete.section.title.persons', defaultMessage: 'People' },
  PERSONS_SECTION_ACTION: { id: 'autocomplete.section.action.persons', defaultMessage: 'Access person\'s projects' },
});
