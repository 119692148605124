import connect from 'react/hoc/connectProxy';
import { getReplies } from 'redux/replies/actions';

/**
 * Requires a productId prop.
 * Exposes a onGetReplies func bound to the productId prop.
 */
export default connect(
  null,
  (dispatch, props) => ({
    onGetReplies: (reviewId, replyId, query) => dispatch(getReplies(
      props.productId,
      reviewId,
      replyId,
      query,
    )),
  }),
);
