import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

const enhancer = compose(
  memo,
);

const TooltipLayout = ({
  title,
  body,
  titleClassName,
  bodyClassName,
}) => (
  <>
    <div className={titleClassName}>
      {title}
    </div>
    <div className={bodyClassName}>
      {body}
    </div>
  </>
);

TooltipLayout.propTypes = {
  title: PropTypes.node,
  body: PropTypes.node,
  titleClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
};

TooltipLayout.defaultProps = {
  title: null,
  body: null,
  titleClassName: '',
  bodyClassName: '',
};

export default enhancer(TooltipLayout);
