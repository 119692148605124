import { compose } from 'recompose';
import connect from 'react/hoc/connectProxy';
import {
  selectAllProductsCount,
  selectIsAllProductsCountLoading,
} from 'redux/products/selectors';

const mapStateToProps = state => ({
  allProductsCount: selectAllProductsCount(state),
  allProductsCountLoading: selectIsAllProductsCountLoading(state),
});

export default compose(
  connect(mapStateToProps),
);
