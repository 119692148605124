import React from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import { compose, withPropsOnChange } from 'recompose';

import Asset, { ICON_MAP } from 'react/generic/asset/Asset';
import Tooltip from './content/TooltipContent';

import { TOOLTIP_DEFINITION, TOOLTIP_DEFINITION_SPECIFIC } from './field-tooltip.constants';
import classNames from './field-tooltip.module.scss';

const enhancer = compose(
  /** Create unique id on mount. */
  withPropsOnChange(
    () => false,
    () => ({
      id: uniqueId('formField'),
    }),
  ),
);

const getTooltipDefinition = specific => (
  specific ? TOOLTIP_DEFINITION_SPECIFIC : TOOLTIP_DEFINITION
);

const FieldTooltip = ({
  id,
  children,
  specific,
  ...props
}) => (
  <>
    <div
      {...getTooltipDefinition(specific)}
      data-for={id}
      className={classNames.anchor}
    >
      {children}
    </div>

    <Tooltip id={id} {...props} specific={specific} />
  </>
);

FieldTooltip.displayName = 'FieldTooltip';

FieldTooltip.propTypes = {
  /** From enhancer. */
  id: PropTypes.string.isRequired,
  /** Anchor element. */
  children: PropTypes.node,
  specific: PropTypes.bool,
};

FieldTooltip.defaultProps = {
  children: <Asset name={ICON_MAP.info} />,
  specific: false,
};

export default enhancer(FieldTooltip);
