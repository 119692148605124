import * as SuggestionsService from 'services/suggestions';
import { selectUserPreferences } from 'redux/users/selectors';

import { SUGGESTIONS_PAGINATION_ITEM_NUMBER } from 'config/constants';
import { ACTIONS } from './constants';

export const GET_PRODUCT_SUGGESTIONS = (productId, query) => ({
  type: ACTIONS.GET_PRODUCT_SUGGESTIONS,
  productId,
  query,
});

export const GET_PRODUCT_SUGGESTIONS_SUCCESS = (productId, suggestions, count, preserve) => ({
  type: ACTIONS.GET_PRODUCT_SUGGESTIONS_SUCCESS,
  productId,
  suggestions,
  count,
  preserve,
});

export const GET_PRODUCT_SUGGESTIONS_FAILURE = (productId, error) => ({
  type: ACTIONS.GET_PRODUCT_SUGGESTIONS_FAILURE,
  productId,
  error,
});

export const getProductSuggestions = (
  productId,
  { preserve, ...query },
) => async (dispatch, getState) => {
  const preferences = selectUserPreferences(getState());

  query.limit = preferences?.suggestionsOnPage || SUGGESTIONS_PAGINATION_ITEM_NUMBER;

  dispatch(GET_PRODUCT_SUGGESTIONS(productId, query));

  try {
    const { count, suggestions } = await SuggestionsService.getProductSuggestions(productId, {
      ...query,
      lang: preferences.alwaysTranslateContent ? preferences.language : null,
    });

    return dispatch(GET_PRODUCT_SUGGESTIONS_SUCCESS(productId, suggestions, count, preserve));
  } catch (err) {
    return dispatch(GET_PRODUCT_SUGGESTIONS_FAILURE(productId, err));
  }
};

export const GET_PRODUCT_SUGGESTION = (productId, suggestionId, query) => ({
  type: ACTIONS.GET_PRODUCT_SUGGESTION,
  productId,
  suggestionId,
  query,
});

export const GET_PRODUCT_SUGGESTION_SUCCESS = (productId, suggestion) => ({
  type: ACTIONS.GET_PRODUCT_SUGGESTION_SUCCESS,
  productId,
  suggestion,
});

export const GET_PRODUCT_SUGGESTION_FAILURE = (productId, suggestionId, error) => ({
  type: ACTIONS.GET_PRODUCT_SUGGESTION_FAILURE,
  productId,
  suggestionId,
  error,
});

export const getProductSuggestion = (productId, suggestionId, query) => async (dispatch) => {
  dispatch(GET_PRODUCT_SUGGESTION(productId, suggestionId, query));

  try {
    const suggestion = await SuggestionsService
      .getProductSuggestion(productId, suggestionId, query);

    return dispatch(GET_PRODUCT_SUGGESTION_SUCCESS(productId, suggestion));
  } catch (err) {
    return dispatch(GET_PRODUCT_SUGGESTION_FAILURE(productId, suggestionId, err));
  }
};

export const CREATE_PRODUCT_SUGGESTION = (productId, suggestion, noRedirect) => ({
  type: ACTIONS.CREATE_PRODUCT_SUGGESTION,
  productId,
  suggestion,
  noRedirect,
});

export const CREATE_PRODUCT_SUGGESTION_SUCCESS = (productId, suggestion) => ({
  type: ACTIONS.CREATE_PRODUCT_SUGGESTION_SUCCESS,
  productId,
  suggestion,
});

export const CREATE_PRODUCT_SUGGESTION_FAILURE = (productId, noRedirect, error) => ({
  type: ACTIONS.CREATE_PRODUCT_SUGGESTION_FAILURE,
  productId,
  noRedirect,
  error,
});

export const createProductSuggestion = (productId, suggestion, noRedirect) => async (dispatch) => {
  dispatch(CREATE_PRODUCT_SUGGESTION(productId, suggestion, noRedirect));

  try {
    const createdSuggestion = await SuggestionsService
      .createProductSuggestion(productId, suggestion);
    return dispatch(CREATE_PRODUCT_SUGGESTION_SUCCESS(productId, createdSuggestion));
  } catch (err) {
    return dispatch(CREATE_PRODUCT_SUGGESTION_FAILURE(productId, noRedirect, err));
  }
};

export const VOTE_PRODUCT_SUGGESTION = (productId, suggestionId, vote) => ({
  type: ACTIONS.VOTE_PRODUCT_SUGGESTION,
  productId,
  suggestionId,
  vote,
});

export const VOTE_PRODUCT_SUGGESTION_SUCCESS = (productId, suggestion, vote) => ({
  type: ACTIONS.VOTE_PRODUCT_SUGGESTION_SUCCESS,
  productId,
  suggestion,
  vote,
});

export const VOTE_PRODUCT_SUGGESTION_FAILURE = (productId, suggestionId, error) => ({
  type: ACTIONS.VOTE_PRODUCT_SUGGESTION_FAILURE,
  productId,
  suggestionId,
  error,
});

export const voteProductSuggestion = (productId, suggestionId, vote) => async (dispatch) => {
  dispatch(VOTE_PRODUCT_SUGGESTION(productId, suggestionId, vote));

  try {
    const suggestion = await SuggestionsService
      .voteProductSuggestion(productId, suggestionId, vote);
    return dispatch(VOTE_PRODUCT_SUGGESTION_SUCCESS(productId, suggestion, vote));
  } catch (err) {
    return dispatch(VOTE_PRODUCT_SUGGESTION_FAILURE(productId, suggestionId, err));
  }
};

export const PATCH_PRODUCT_SUGGESTION = (productId, suggestion) => ({
  type: ACTIONS.PATCH_PRODUCT_SUGGESTION,
  productId,
  suggestion,
});

export const PATCH_PRODUCT_SUGGESTION_SUCCESS = (productId, suggestion) => ({
  type: ACTIONS.PATCH_PRODUCT_SUGGESTION_SUCCESS,
  productId,
  suggestion,
});

export const PATCH_PRODUCT_SUGGESTION_FAILURE = (productId, err) => ({
  type: ACTIONS.PATCH_PRODUCT_SUGGESTION_FAILURE,
  productId,
  err,
});

export const patchProductSuggestion = (productId, suggestion) => async (dispatch) => {
  dispatch(PATCH_PRODUCT_SUGGESTION(productId, suggestion));

  try {
    const updatedSuggestion = await SuggestionsService
      .patchProductSuggestion(productId, suggestion);
    return dispatch(PATCH_PRODUCT_SUGGESTION_SUCCESS(productId, updatedSuggestion));
  } catch (err) {
    return dispatch(PATCH_PRODUCT_SUGGESTION_FAILURE(productId, err));
  }
};

export const DELETE_PRODUCT_SUGGESTION = (productId, suggestionId) => ({
  type: ACTIONS.DELETE_PRODUCT_SUGGESTION,
  productId,
  suggestionId,
});

export const DELETE_PRODUCT_SUGGESTION_SUCCESS = (productId, suggestionId) => ({
  type: ACTIONS.DELETE_PRODUCT_SUGGESTION_SUCCESS,
  productId,
  suggestionId,
});

export const DELETE_PRODUCT_SUGGESTION_FAILURE = (productId, suggestionId, err) => ({
  type: ACTIONS.DELETE_PRODUCT_SUGGESTION_FAILURE,
  productId,
  suggestionId,
  err,
});

export const deleteProductSuggestion = (productId, suggestionId) => async (dispatch) => {
  dispatch(DELETE_PRODUCT_SUGGESTION(productId, suggestionId));

  try {
    await SuggestionsService.deleteProductSuggestion(productId, suggestionId);
    return dispatch(DELETE_PRODUCT_SUGGESTION_SUCCESS(productId, suggestionId));
  } catch (err) {
    return dispatch(DELETE_PRODUCT_SUGGESTION_FAILURE(productId, suggestionId, err));
  }
};

export const GET_ALL_SUGGESTIONS_COUNT = () => ({
  type: ACTIONS.GET_ALL_SUGGESTIONS_COUNT,
});

export const GET_ALL_SUGGESTIONS_COUNT_SUCCESS = count => ({
  type: ACTIONS.GET_ALL_SUGGESTIONS_COUNT_SUCCESS,
  count,
});

export const GET_ALL_SUGGESTIONS_COUNT_FAILURE = error => ({
  type: ACTIONS.GET_ALL_SUGGESTIONS_COUNT_FAILURE,
  error,
});

export const getAllSuggestionsCount = () => async (dispatch) => {
  dispatch(GET_ALL_SUGGESTIONS_COUNT());

  try {
    const { count } = await SuggestionsService.getSuggestions({ limit: 0 });

    return dispatch(GET_ALL_SUGGESTIONS_COUNT_SUCCESS(count));
  } catch (err) {
    return dispatch(GET_ALL_SUGGESTIONS_COUNT_FAILURE(err));
  }
};
