import { omit } from 'lodash';
import { mapProps } from 'recompose';

/**
 * Create a HOC which will omit the given props.
 *
 * @example
 * import omitProps from 'react/hoc/omitProps';
 *
 * compose(omitProps(['onSubmit']))(Component);
 *
 * @param {string[]} propsToOmit - Keys of props to omit.
 * @returns {Function} HOC.
 */
export default propsToOmit => mapProps(props => omit(props, propsToOmit));
