import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import cn from 'classnames';

import classNames from './corner-ribbon.module.scss';

const enhancer = compose(
  memo,
);

const CornerRibbon = ({
  className,
  children,
  contentClassName,
  overrideClassName,
  overrideClassNames,
  ...props
}) => (
  <div
    {...props}
    className={cn(
      className,
      classNames.ribbon,
      overrideClassName,
    )}
  >
    <div
      className={cn(
        classNames.corner,
        classNames.xCorner,
        overrideClassNames.corner,
        overrideClassNames.xCorner,
      )}
    />

    <div
      className={cn(
        classNames.corner,
        classNames.yCorner,
        overrideClassNames.corner,
        overrideClassNames.yCorner,
      )}
    />

    <div className={cn(contentClassName, classNames.content)}>
      {children}
    </div>
  </div>
);

CornerRibbon.displayName = 'CornerRibbon';

CornerRibbon.propTypes = {
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  children: PropTypes.node,
};

CornerRibbon.defaultProps = {
  className: null,
  contentClassName: null,
  children: null,
};

const EnhancedCornerRibbon = enhancer(CornerRibbon);

EnhancedCornerRibbon.displayName = 'CornerRibbon';
EnhancedCornerRibbon.defaultProps = CornerRibbon.defaultProps;
EnhancedCornerRibbon.propTypes = CornerRibbon.propTypes;

export default EnhancedCornerRibbon;
