import PropTypes from 'prop-types';

export const userShape = PropTypes.shape({
  id: PropTypes.string,
  externalId: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  profile: PropTypes.string,
  bu: PropTypes.string,
  country: PropTypes.string,
  avatar: PropTypes.string,
});

export const currentUserShape = PropTypes.shape({
  cachedSearchFilters: PropTypes.object,
  preferences: PropTypes.object,
  businessUnit: PropTypes.string,
  email: PropTypes.string,
  externalId: PropTypes.string,
  firstName: PropTypes.string,
  id: PropTypes.string,
  lastName: PropTypes.string,
  isDomainLeader: PropTypes.bool,
  isCommunityArchitect: PropTypes.bool,
  isBusinessUnitArchitect: PropTypes.bool,
  communitiesWhereUserIsArchitect: PropTypes.array,
  domainsWhereUserIsLeader: PropTypes.array,
  businessUnitsWhereUserIsArchitect: PropTypes.array,
});
