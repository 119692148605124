import React, { memo } from 'react';
import { compose } from 'recompose';

import {
  withFetchProductsAndReviewsTotalCount,
  withFetchLatestProducts,
} from 'react/business/home/sections/home.connect';
import HomeAboutSection from 'react/business/home/sections/about/HomeAboutSection';
import HomeIndicatorsSection from 'react/business/home/sections/indicators/HomeIndicatorsSection';
import HomeLatestProductsSection from 'react/business/home/sections/latestproducts/HomeLatestProductsSection';
import HomeLatestReviewsSection from 'react/business/home/sections/latestreviews/HomeLatestReviewsSection';

const enhancer = compose(
  withFetchProductsAndReviewsTotalCount,
  withFetchLatestProducts,
  memo,
);

const HomePage = () => (
  <>
    <HomeAboutSection />
    <HomeIndicatorsSection />
    <HomeLatestProductsSection />
    <HomeLatestReviewsSection />
  </>
);

HomePage.displayName = 'HomePage';

export default enhancer(HomePage);
