import { compose, withStateHandlers } from 'recompose';

export const defaultFilters = {
  abbCommunityIds: [],
  abbIds: [],
  subsetCommunityIds: [],
  subsetIds: [],
  activityIds: [],
  atomicActivityIds: [],
  brandIds: [],
  businessUnitIds: [],
  communityIds: [],
  countryIds: [],
  domainIds: [],
  macroActivityIds: [],
  stages: [],
  title: '',
};

export default compose(
  withStateHandlers(
    ({ defaultFiltersValue }) => ({
        filters: defaultFiltersValue || defaultFilters,
      }),
    {
      onSetFilters: ({ filters }, { onChangeFilters }) => (modifiedFilters) => {
        // return default filters if q string change
        if (Object.keys(modifiedFilters).length === 0) {
          return { filters: defaultFilters };
        }

        // Change filters value.
        const newValues = {
          ...filters,
          ...modifiedFilters,
        };

        // Check existence before calling the callback.
        // eslint-disable-next-line no-unused-expressions
        onChangeFilters && onChangeFilters(newValues);

        return { filters: newValues };
      },

      /**
       * Reset the requested filters in state.
       *
       * @returns {object} Default filters.
       */
      onResetFilters: () => () => ({ filters: defaultFilters }),
    },
  ),
);
