import PropTypes from 'prop-types';

import { BUSINESS_OBJECTS } from 'config/constants';

export const searchResultsEntityShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

export const searchResultsShape = PropTypes.shape({
  [BUSINESS_OBJECTS.PRODUCTS]: PropTypes.arrayOf(searchResultsEntityShape),
  [BUSINESS_OBJECTS.COMMUNITIES]: PropTypes.arrayOf(searchResultsEntityShape),
  [BUSINESS_OBJECTS.DOMAINS]: PropTypes.arrayOf(searchResultsEntityShape),
  [BUSINESS_OBJECTS.BUSINESS_UNITS]: PropTypes.arrayOf(searchResultsEntityShape),
});
