import { compose } from 'recompose';

import connect from 'react/hoc/connectProxy';
import { selectIsBelowLargeBreakpoint } from 'redux/responsive/selectors';

const mapStateToProps = state => ({
  isBelowLargeBreakpoint: selectIsBelowLargeBreakpoint(state),
});

export default compose(
  connect(mapStateToProps),
);
