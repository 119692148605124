import { compose } from 'recompose';

import connect from 'react/hoc/connectProxy';
import {
  selectAllReviewsCount,
  isLoadingGetAllReviewsCount,
} from 'redux/reviews/selectors';

const mapStateToProps = state => ({
  count: selectAllReviewsCount(state),
  loading: isLoadingGetAllReviewsCount(state),
});

export default compose(
  connect(mapStateToProps),
);
