import { noop } from 'lodash';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import parseDuration from 'parse-duration';

import Layer from 'react/generic/layer/Layer';
import classNames from './dialog.module.scss';
import sassVars from './dialog.variables.scss';

// Export dialog components.
export DialogTitle from './DialogTitle';
export DialogContent from './DialogContent';
export DialogActions from './DialogActions';
export DialogAction from './DialogAction';

// Animation duration in ms.
const defaultAnimationDuration = parseDuration(sassVars.dialogAnimationDuration);

export default class Dialog extends PureComponent {
  static propTypes = {
    /** Is the dialog open. */
    isOpen: PropTypes.bool,
    /** Should request to close the dialog when pressing escape. */
    shouldCloseOnEscape: PropTypes.bool,
    /** Should close the modal when clicking on the overlay. */
    shouldCloseOnClickOverlay: PropTypes.bool,
    /** Animation duration in ms. */
    animationDuration: PropTypes.number,
    /** DOM element where to create the portal. */
    // DOM element is an object.
    // eslint-disable-next-line react/forbid-prop-types
    portalElement: PropTypes.object,
    /** Root element className. */
    className: PropTypes.string,
    /** Overlay root element className. */
    overlayClassName: PropTypes.string,
    /** Content root element className. */
    contentClassName: PropTypes.string,
    /** Called when requesting to close the dialog. */
    onRequestClose: PropTypes.func,
    /** Called when the exit animation is done and the content is unmounted. */
    onDidClose: PropTypes.func,
    /** Dialog content. */
    children: PropTypes.node,
  };

  static defaultProps = {
    isOpen: false,
    shouldCloseOnEscape: true,
    shouldCloseOnClickOverlay: true,
    animationDuration: defaultAnimationDuration,
    portalElement: null,
    className: '',
    overlayClassName: '',
    contentClassName: '',
    onRequestClose: noop,
    onDidClose: noop,
    children: null,
  };

  getContainerClassName = ({ isOpen }) => cn(
    this.props.className,
    classNames.container,
    isOpen && classNames.isOpen,
  );

  getOverlayClassName = ({ isOpen, isClosing }) => cn(
    this.props.overlayClassName,
    classNames.overlay,
    (isOpen && !isClosing) && classNames.isOpen,
    isClosing && classNames.isClosing,
  );

  /**
   * Render the dialog in a portal.
   *
   * @returns {object} JSX.
   */
  render() {
    const {
      isOpen,
      shouldCloseOnClickOverlay,
      onRequestClose,
      onDidClose,
      portalElement,
      shouldCloseOnEscape,
      animationDuration,
      contentClassName,
      children,
    } = this.props;

    return (
      <Layer
        className={this.getContainerClassName}
        overlayClassName={this.getOverlayClassName}
        openClassName={classNames.isOpen}
        portalElement={portalElement}
        shouldCloseOnClickOverlay={shouldCloseOnClickOverlay}
        onRequestClose={onRequestClose}
        onDidClose={onDidClose}
        isOpen={isOpen}
        animationDuration={animationDuration}
        shouldCloseOnEscape={shouldCloseOnEscape}
      >
        {({ isClosing: stateIsClosing, isOpen: stateIsOpen }) => (
          <div
            className={cn(
              contentClassName,
              classNames.dialog,
              (stateIsOpen && !stateIsClosing) && classNames.isOpen,
              stateIsClosing && classNames.isClosing,
            )}
          >
            {(stateIsOpen || stateIsClosing) && children}
          </div>
        )}
      </Layer>
    );
  }
}
