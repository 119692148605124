import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { compose } from 'recompose';

import IconButton from 'react/generic/button/IconButton';
import Asset, { ICON_MAP } from 'react/generic/asset/Asset';

import classNames from '../icon-button.module.scss';

const enhancer = compose(
  memo,
);

const DeleteIconButton = ({ onClickDelete }) => (
  <IconButton
    outline
    overrideClassName={classNames.deleteButton}
    overrideClassNames={classNames}
    onClick={onClickDelete}
  >
    <Asset name={ICON_MAP.trash} />
  </IconButton>
);

DeleteIconButton.propTypes = {
  onClickDelete: PropTypes.func,
};

DeleteIconButton.defaultProps = {
  onClickDelete: noop,
};

export default enhancer(DeleteIconButton);
