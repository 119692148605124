import React, { useCallback } from 'react';
import globalMessages from 'config/global.messages';
import { FormattedMessage } from 'react-intl';
import Button from 'react/generic/button/Button';
import { redirectFromSingleMessage } from '../../../services/functions/redirectFromSingleMessage';

const useRenderAction = ({
  history, match, onClickClose, redirectOnSingleMessage,
}) => useCallback(
  ({ className }) => (
    <Button
      className={className}
      onClick={() => {
        onClickClose();
        if (redirectOnSingleMessage) {
          redirectFromSingleMessage({ history, match });
        }
      }}
    >
      <FormattedMessage {...globalMessages.CLOSE} />
    </Button>
  ),
  [history, match, onClickClose, redirectOnSingleMessage],
);

export default useRenderAction;
