export const spec = {
  /**
   * Callback for when item's drag ends and it drops into a drop target.
   *
   * @param {{onDrop: Function, index: number|string}} props - The DropTarget
   * component props (injected).
   * @param {{getItem: Function}} monitor - The DragAndDrop monitor (injected).
   */
  drop(props, monitor) {
    props.onDrop(monitor.getItem(), props.index);
  },
};

export const collect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isHovered: monitor.isOver(),
});
