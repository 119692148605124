import PropTypes from 'prop-types';
import { dateShape, externalImageShape } from './generic';
import { detailedMarksShape } from './review';

export const markShape = PropTypes.shape({
  average: PropTypes.number,
  count: PropTypes.number,
  detail: PropTypes.objectOf(PropTypes.number),
});

export const linkShape = PropTypes.shape({
  name: PropTypes.string,
  link: PropTypes.string,
});

export const linksShape = PropTypes.shape({
  source: PropTypes.arrayOf(linkShape),
  apiReference: PropTypes.arrayOf(linkShape),
  discussion: PropTypes.arrayOf(linkShape),
  productUrl: PropTypes.arrayOf(linkShape),
  productMarketingUrl: PropTypes.arrayOf(linkShape),
  documentation: PropTypes.arrayOf(linkShape),
  roadmap: PropTypes.arrayOf(linkShape),
  backlog: PropTypes.arrayOf(linkShape),
  datasets: PropTypes.arrayOf(linkShape),
});

export const tagShape = PropTypes.shape({
  id: PropTypes.string,
  label: PropTypes.string,
});

export const roleShape = PropTypes.shape({
  id: PropTypes.string,
  label: PropTypes.string,
  displayOnTop: PropTypes.bool,
  isProductLeader: PropTypes.bool,
});

export const teamMemberShape = PropTypes.shape({
  externalId: PropTypes.string,
  role: roleShape,
});

export const teamMemberFormShape = PropTypes.shape({
  id: PropTypes.string,
  role: roleShape,
  user: PropTypes.shape({
    value: PropTypes.shape({
      externalId: PropTypes.string,
    }),
  }),
});

export const cidShape = PropTypes.shape({
  c: PropTypes.number,
  i: PropTypes.number,
  d: PropTypes.number,
});

export const softwarePublisherShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

export const urlShape = PropTypes.shape({
  uri: PropTypes.string,
  title: PropTypes.string,
});

export const productShape = PropTypes.shape({
  id: PropTypes.string,
  shortDescription: PropTypes.string,
  name: PropTypes.string,
  logo: externalImageShape,
  screenshots: PropTypes.arrayOf(externalImageShape),
  presentation: PropTypes.string,
  created: PropTypes.string,
  version: PropTypes.string,
  activeUsers: PropTypes.number,
  businessUnitsUsingProduct: PropTypes.arrayOf(PropTypes.string),
  businessUnitInCharge: PropTypes.string,
  activeContributors: PropTypes.number,
  marks: markShape,
  reviewCount: PropTypes.number,
  links: linksShape,
  followed: PropTypes.bool,
  tags: PropTypes.shape({
    devices: PropTypes.arrayOf(tagShape),
    os: PropTypes.arrayOf(tagShape),
    misc: PropTypes.arrayOf(tagShape),
    techno: PropTypes.arrayOf(tagShape),
  }),
  mainFeatures: PropTypes.arrayOf(PropTypes.string),
  teamMembers: PropTypes.arrayOf(teamMemberShape),
  isGlobal: PropTypes.bool,
  isDraft: PropTypes.bool,
  isMaintainedByPlatform: PropTypes.bool,
  cid: cidShape,
  aliases: PropTypes.arrayOf(PropTypes.string),
  sla: PropTypes.string,
  numberOfUsers: PropTypes.number,
  bia: PropTypes.string,
  softwarePublisher: PropTypes.oneOfType([softwarePublisherShape, PropTypes.string]),
  valueProposition: PropTypes.string,
  stakeholders: PropTypes.arrayOf(PropTypes.string),
  kpi: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.string,
    description: PropTypes.string,
  })),
  personalData: PropTypes.string,
  psi: PropTypes.string,
  invoicingModel: PropTypes.string,
  architectureBuildingBlocks: PropTypes.arrayOf(PropTypes.string),

  recoveryPointObjectiveRecommended: PropTypes.number,
  recoveryTimeObjectiveRecommended: PropTypes.number,
  supportTimeRange: PropTypes.shape({
    end_value: PropTypes.string,
    value: PropTypes.string,
  }),
  supportDaysAvailable: PropTypes.arrayOf(PropTypes.string),
  recoveryPointObjective: PropTypes.number,
  recoveryTimeObjective: PropTypes.number,
  scheduledMaintenanceRange: PropTypes.string,
  incidentTakingIntoAccount: PropTypes.number,
  urgence1: PropTypes.number,
  urgence2: PropTypes.number,
  urgence3: PropTypes.number,
  takingIntoAccountRequests: PropTypes.number,
  requestedServices: PropTypes.string,
  linkToServiceCatalog: urlShape,
  availability: PropTypes.number,
  availabilityInf: PropTypes.string,
  availabilityInfoLink: urlShape,
  latencyInfo: PropTypes.string,
  latencyInfoLink: urlShape,
  uptimeInfo: PropTypes.string,
  uptimeInfoLink: urlShape,
  githubPrefix: PropTypes.string,
  hostingType: PropTypes.string,
  communityId: PropTypes.string,
  domainId: PropTypes.string,
  validation: PropTypes.oneOfType([
    PropTypes.shape({
      status: PropTypes.string,
      rejectedReason: PropTypes.string,
      rejectedReasonDescription: PropTypes.string,
      lastValidationTimestamp: PropTypes.string,
    }),
  ]),
});

export const helpfulShape = PropTypes.shape({
  up: PropTypes.number,
  down: PropTypes.number,
  userVote: PropTypes.number,
});

export const replyShape = PropTypes.shape({
  id: PropTypes.string,
  date: dateShape,
  author: PropTypes.string,
  content: PropTypes.string,
  helpful: helpfulShape,
});

export const reviewShape = PropTypes.shape({
  id: PropTypes.string,
  date: dateShape,
  author: PropTypes.string,
  mark: PropTypes.number,
  detailedMarks: detailedMarksShape,
  content: PropTypes.string,
  directRepliesCount: PropTypes.number,
  helpful: helpfulShape,
});

export const suggestionShape = PropTypes.shape({
  id: PropTypes.string,
  date: dateShape,
  author: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  directRepliesCount: PropTypes.number,
  helpful: helpfulShape,
});

export const productHistoryShape = PropTypes.shape({
  productId: PropTypes.string,
  productName: PropTypes.string,
  userId: PropTypes.string,
});
