import querystring from 'query-string';
import { withRouter } from 'react-router';
import { compose, withPropsOnChange } from 'recompose';
import connect from 'react/hoc/connectProxy';
import { formatRoute } from 'react-router-named-routes';

import { searchByTitle, setSearchResults } from 'redux/searches/actions';
import ROUTES from 'react/routes';

export default compose(
  withRouter,

  withPropsOnChange(
    ['history'],
    ({ history }) => ({
      onSubmit: (search) => {
        const query = querystring.stringify({
          q: search,
        });

        history.push(`${formatRoute(ROUTES.PRODUCTS)}?${query}`);
      },
    }),
  ),

  connect(
    null,
    dispatch => ({
      onSearch: ({ search }) => {
        dispatch(searchByTitle(search));
      },
      onSearchCleared: () => dispatch(setSearchResults(null)),
    }),
  ),
);
