import { createSelector } from 'reselect';
import { selectBusinessUnitsIsLoading } from 'redux/businessUnits/selectors';
import { selectCommunitiesIsLoading } from 'redux/communities/selectors';
import { isLoadingNotifications } from 'redux/notifications/selectors';
import { isLoadingProducts } from 'redux/products/selectors';
import { isLoadingReplies } from 'redux/replies/selectors';
import { isLoadingReviews } from 'redux/reviews/selectors';
import { isLoadingUsers } from 'redux/users/selectors';
import { isLoadingSuggestions } from 'redux/suggestions/selectors';
import { isLoadingReplies as isLoadingReviewReplies } from 'redux/suggestions/replies/selectors';

export const isAppConnected = state => state.app.isConnected;

export const selectGlobalLoading = createSelector(
  [
    selectBusinessUnitsIsLoading,
    selectCommunitiesIsLoading,
    isLoadingNotifications,
    isLoadingProducts,
    isLoadingReplies,
    isLoadingReviews,
    isLoadingSuggestions,
    isLoadingReviewReplies,
    isLoadingUsers,
  ],
  (...args) => args.some(v => v),
);
