import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop, debounce } from 'lodash';

import { DEBOUNCE_TIME } from 'config/constants';

import { FormattedMessage } from 'react-intl';
import SyncSelect from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import Select from 'react/generic/form/select/Select';

import selectMessages from 'react/generic/select/select.messages';
import messages from './autocomplete.messages';

export const autocompleteValueShape = PropTypes.shape({
  value: PropTypes.any,
  label: PropTypes.any,
});

class Autocomplete extends PureComponent {
 static propTypes = {
   // eslint-disable-next-line react/require-default-props
   value: PropTypes.oneOfType([
     PropTypes.arrayOf(autocompleteValueShape),
     autocompleteValueShape,
   ]),
   onChange: PropTypes.func.isRequired,
   onBlur: PropTypes.func,
   // For sync select, pass options in props.
   options: PropTypes.arrayOf(autocompleteValueShape),
   // For async select, provide a Promise.
   loadOptions: PropTypes.func,
   className: PropTypes.string,
   onSelect: PropTypes.func,
   disabled: PropTypes.bool,
   isMulti: PropTypes.bool,
   // Transforms it on Creatable input.
   onCreateOption: PropTypes.func,
 };

 static defaultProps = {
   isMulti: false,
   className: null,
   options: [],
   loadOptions: null,
   onBlur: noop,
   onSelect: noop,
   disabled: false,
   onCreateOption: null,
 };

  loadOptionsProxy = debounce((inputValue, callback) => {
    this.props
      .loadOptions(inputValue)
      .then(options => callback(options));
  }, DEBOUNCE_TIME);

  noOptionsMessage = ({ inputValue }) => ((this.props.loadOptions && !inputValue) ?
    <FormattedMessage {...messages.TYPE_TEXT} />
    : <FormattedMessage {...selectMessages.DEFAULT_NO_OPTIONS} />);

  formatCreateLabel = inputValue => (
    <FormattedMessage {...messages.CREATE_LABEL} values={{ label: inputValue }} />
  );

  onCreateOptionProxy = async (newLabel) => {
    const {
      value,
      onCreateOption,
      onChange,
      isMulti,
    } = this.props;

    // Create the new element using the callback.
    /** @type {{ label: *, value: * }} newElement */
    const newElement = await onCreateOption(newLabel);

    // Manually trigger the onChange event for the form handler
    // to set the newly created element in the current value.
    if (isMulti) {
      // We have to concat the newly created value to the current value.
      onChange((value || []).concat(newElement));
    } else {
      onChange(newElement);
    }
  };

  onBlur = () => {
    this.props.onBlur(this.props.value);
  };

  /** @returns {object} JSX. */
  render() {
    const {
      loadOptions,
      options,
      disabled,
      onCreateOption,
      onBlur,
      ...props
    } = this.props;

    const commonSelectProps = {
      defaultOptions: true,
      noOptionsMessage: this.noOptionsMessage,
      isDisabled: disabled,
      onBlur: this.onBlur,
      ...props,
    };

    return (
      loadOptions ? (
        <Select
          element={onCreateOption ? AsyncCreatableSelect : AsyncSelect}
          loadOptions={this.loadOptionsProxy}
          onCreateOption={this.onCreateOptionProxy}
          formatCreateLabel={this.formatCreateLabel}
          {...commonSelectProps}
        />
      ) : (
        <Select
          element={SyncSelect}
          options={options}
          {...commonSelectProps}
        />
      )
    );
  }
}

export default Autocomplete;
