import { defineMessages } from 'react-intl';

export default defineMessages({
  SUGGESTION_FORM_DIALOG_TITLE: {
    id: 'suggestion.form.dialog.create.title',
    defaultMessage: 'Suggest a feature',
  },
  SUBMIT: {
    id: 'suggestion.form.dialog.create.submit',
    defaultMessage: 'Submit',
  },
});
