import connect from 'react/hoc/connectProxy';
import {
  selectFilteredRepliesListById,
} from 'redux/replies/selectors';

/**
 * Required props:
 * - productId (string).
 * - review (reviewShape).
 */
export default connect(
  (state, props) => ({
    // Direct replies.
    replies: selectFilteredRepliesListById(state, props.productId, props.review.id, null),
  }),
);
