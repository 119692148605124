import React, { memo } from 'react';
import { compose } from 'recompose';

import Asset, { ICON_MAP } from 'react/generic/asset/Asset';
import CornerRibbon from 'react/generic/ribbon/corner-ribbon/CornerRibbon';

import classNames from './big-product-ribbon.module.scss';

const enhancer = compose(
  memo,
);

const BigProductRibbon = props => (
  <CornerRibbon
    {...props}
    contentClassName={classNames.content}
    overrideClassName={classNames.ribbon}
    overrideClassNames={classNames}
  >
    <Asset
      name={ICON_MAP.cube}
      className={classNames.icon}
    />
  </CornerRibbon>
);

BigProductRibbon.displayName = 'BigProductRibbon';

export default enhancer(BigProductRibbon);
