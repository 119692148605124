import React, { memo, useCallback, useState } from 'react';
import { bool } from 'prop-types';
import cn from 'classnames';

import Asset, { ICON_MAP } from 'react/generic/asset/Asset';
import SearchAutocomplete from 'react/business/search/autocomplete/SearchAutocomplete';
import DashboardButton from 'react/business/user/dashboardButton/DashboardButton';
import Logo from 'react/business/layout/header/logo/Logo';

import classNames from './app-header.module.scss';
import HelpButton from '../../user/helpButton/HelpButton';

const AppHeader = ({ isBelowMediumBreakpoint, pageType }) => {
  const headerProduct = `header--${pageType}`;

  const [isSearchCollapsed, setSearchCollapsed] = useState(true);

  const collapseSearchbar = useCallback(
    () => setSearchCollapsed(true),
    [setSearchCollapsed],
  );

  const expandSearchbar = useCallback(
    () => setSearchCollapsed(false),
    [setSearchCollapsed],
  );

  return (
    <header
      role="banner"
      className={cn(classNames.header, classNames[headerProduct])}
    >
      {
        isSearchCollapsed && (
          <div className={cn(classNames.logoContainer)}>
            <Logo />
          </div>
        )
      }

      <div className={classNames.right}>
        <div
          className={cn(classNames.searchContainer, isSearchCollapsed && classNames.collapsed)}
        >
          {
            !isBelowMediumBreakpoint || !isSearchCollapsed ? (
              <>
                <SearchAutocomplete />
                {
                  isBelowMediumBreakpoint && (
                    <button
                      className={cn('unstyled with-pointer', classNames.expand)}
                      onClick={collapseSearchbar}
                    >
                      <Asset name={ICON_MAP.reset_icon} />
                    </button>
                  )
                }
              </>
            ) : (
              <button className="unstyled with-pointer" onClick={expandSearchbar}>
                <Asset name={ICON_MAP.loupe} />
              </button>
            )
          }
        </div>
        <div className={classNames.rightActions}>
          <HelpButton
            className={classNames.userProfileLinkContainer}
            isBelowMediumBreakpoint={isBelowMediumBreakpoint}
          />
          <DashboardButton
            className={classNames.userProfileLinkContainer}
            isBelowMediumBreakpoint={isBelowMediumBreakpoint}
          />
        </div>
      </div>
    </header>
  );
};

AppHeader.displayName = 'AppHeader';

AppHeader.propTypes = {
  isBelowMediumBreakpoint: bool,
};

AppHeader.defaultProps = {
  isBelowMediumBreakpoint: false,
};

export default memo(AppHeader);
