import React from 'react';
import PropTypes from 'prop-types';
import { formatRoute } from 'react-router-named-routes';
import { FormattedMessage } from 'react-intl';

import Asset, { ICON_MAP } from 'react/generic/asset/Asset';
import Link from 'react/generic/link/Link';

import { messageShape } from 'shapes/vendor';

import classNames from './linkSelect.module.scss';

const LinkSelect = ({
  href,
  icon,
  label,
}) => (
  <Link to={formatRoute(href)} className={classNames.optionLink}>
    <div className={classNames.optionContainer}>
      <Asset
        name={icon}
        className={classNames.icon}
      />
      <div className={classNames.label}>
        <FormattedMessage {...label} />
      </div>
      <Asset name={ICON_MAP.chevron} className={classNames.chevron} />
    </div>
  </Link>
);

LinkSelect.displayName = 'LinkSelect';

LinkSelect.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.oneOf(Object.values(ICON_MAP)),
  label: messageShape.isRequired,
};

LinkSelect.defaultProps = {
  href: '',
  icon: ICON_MAP.world,
};

export default LinkSelect;
