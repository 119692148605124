import { defineMessages } from 'react-intl';

export default defineMessages({
  DEFAULT_NO_OPTIONS: {
    id: 'global.select.noOptions',
    defaultMessage: 'No options',
  },

  DEFAULT_PLACEHOLDER: {
    id: 'global.select.placeholder',
    defaultMessage: 'Select',
  },
});
