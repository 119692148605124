import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import cn from 'classnames';

import classNames from './fieldError.module.scss';

const enhancer = compose(
  memo,
);

const FieldError = ({ className, children, ...props }) => (
  <h3
    {...props}
    className={cn(className, classNames.fieldError)}
  >
    {children}
  </h3>
);

FieldError.displayName = 'FieldError';

FieldError.propTypes = {
  // Heading must have content.
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

FieldError.defaultProps = {
  className: null,
};

export default enhancer(FieldError);
