import React, { memo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { RotateSpinner } from 'react-spinners-kit';
import { formatRoute } from 'react-router-named-routes';
import { productShape } from 'shapes/product';

import ROUTES from 'react/routes';
import Link from 'react/generic/link/Link';
import Button from 'react/generic/button/Button';
import ProductsCarousel from 'react/business/products/carousel/ProductsCarousel';

import theme from 'style/theme.variables.scss';
import connectLatestProductsSection from './home-latestproducts-section.connect';
import connectProductsCount from './withProductsCount.connect';
import classNames from './home-latestproducts-section.module.scss';
import messages from './home-latestproducts-section.messages';

const DISPLAYED_ITEMS_COUNT = {
  SMALL: 1,
  MEDIUM: 2,
  LARGE: 3,
};

const getDisplayedItemsCount = (isBelowMediumBreakpoint, isBelowLargeBreakpoint) => {
  switch (true) {
    case isBelowMediumBreakpoint:
      return DISPLAYED_ITEMS_COUNT.SMALL;
    case isBelowLargeBreakpoint:
      return DISPLAYED_ITEMS_COUNT.MEDIUM;
    default:
      return DISPLAYED_ITEMS_COUNT.LARGE;
  }
};

const enhancer = compose(
  connectLatestProductsSection,
  connectProductsCount,
  memo,
);

const HomeLatestProductsSection = ({
  latestCreatedProducts,
  latestCreatedProductsLoading,
  isBelowMediumBreakpoint,
  isBelowLargeBreakpoint,
  allProductsCount,
  allProductsCountLoading,
}) => (
  <div className={classNames.container}>
    <h4 className={classNames.title}>
      <FormattedMessage {...messages.TITLE} />
    </h4>
    <div className={classNames.carouselContainer}>
      {
        latestCreatedProductsLoading ? (
          <div className={classNames.loading}>
            <RotateSpinner
              color={theme.titleColor}
              size={32}
            />
          </div>
        ) : (
          <div className={classNames.carousel}>
            <ProductsCarousel
              products={latestCreatedProducts}
              displayedItemsCount={getDisplayedItemsCount(
                isBelowMediumBreakpoint,
                isBelowLargeBreakpoint,
              )}
            />
          </div>
        )
      }
    </div>
    <div className={classNames.allProductsContainer}>
      <Link to={formatRoute(ROUTES.PRODUCTS)}>
        <Button
          outline
          overrideClassName={classNames.button}
          overrideClassNames={classNames}
        >
          <span className={classNames.allProductsLabel}>
            <FormattedMessage {...messages.ALL_PRODUCTS} />
          </span>
          <span className={classNames.allProductsCount}>
            {
              allProductsCountLoading ? (
                <RotateSpinner
                  color="white"
                  className={classNames.allProductsLoader}
                  size={16}
                />
              ) : `(${allProductsCount})`
            }
          </span>
        </Button>
      </Link>
    </div>
  </div>
);

HomeLatestProductsSection.displayName = 'HomeLatestProductsSection';

HomeLatestProductsSection.propTypes = {
  latestCreatedProducts: PropTypes.arrayOf(productShape),
  latestCreatedProductsLoading: PropTypes.bool,
  isBelowMediumBreakpoint: PropTypes.bool,
  isBelowLargeBreakpoint: PropTypes.bool,
  allProductsCount: PropTypes.number,
  allProductsCountLoading: PropTypes.bool,
};

HomeLatestProductsSection.defaultProps = {
  latestCreatedProducts: [],
  latestCreatedProductsLoading: false,
  isBelowMediumBreakpoint: false,
  isBelowLargeBreakpoint: false,
  allProductsCount: 0,
  allProductsCountLoading: false,
};

export default enhancer(HomeLatestProductsSection);
