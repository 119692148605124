export const spec = {
  /**
   * Callback for when item's drag begins.
   * The returned value will be the result of monitor.getItem() when dropped.
   *
   * @param {{id: number|string, index: number}} props - The DragSource component props.
   * @returns {{id: number|string, index: number}} - The item representation during dnd flow.
   */
  beginDrag(props) {
    return { id: props.id, index: props.index };
  },
};

export const collect = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
});
