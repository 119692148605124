import React, { memo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import cn from 'classnames';

import classNames from './action-card.module.scss';

const enhancer = compose(
  memo,
);

const ActionCard = ({
  title,
  quote,
  hint,
  children,
  className,
  titleClassName,
}) => (
  <div className={cn(className, classNames.card)}>
    <div className={classNames.header}>
      <h1 className={cn(titleClassName, classNames.title)}>{title}</h1>
      {quote && (<div className={classNames.quote}>{quote}</div>)}
      {hint && (<div className={classNames.hint}>{hint}</div>)}
    </div>
    <div className={classNames.buttons}>
      {children}
    </div>
  </div>
);

ActionCard.displayName = 'ActionCard';

ActionCard.propTypes = {
  title: PropTypes.node,
  quote: PropTypes.node,
  hint: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  titleClassName: PropTypes.string,
};

ActionCard.defaultProps = {
  title: null,
  quote: null,
  hint: null,
  children: null,
  className: '',
  titleClassName: '',
};

export default enhancer(ActionCard);
