import React from 'react';
import { Helmet } from 'react-helmet';

import HomePage from 'react/pages/home/HomePage';
import ApplicationLayout from 'react/business/layout/ApplicationLayout';
import FormattedMessageChildren from 'react/generic/i18n/FormattedMessageChildren';

import messages from './home.messages';

const Home = () => (
  <ApplicationLayout>
    <FormattedMessageChildren {...messages.TITLE}>
      {message => (
        <Helmet>
          <title>{message}</title>
        </Helmet>
      )}
    </FormattedMessageChildren>
    <HomePage />
  </ApplicationLayout>
);

Home.displayName = 'HomeView';

export default Home;
