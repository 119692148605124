import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import cn from 'classnames';

import classNames from './footer.module.scss';

const enhancer = compose(
  memo,
);

const MessageCardFooter = ({ className, compact, ...props }) => (
  <footer
    {...props}
    className={cn(
      classNames.footer,
      className,
      compact && classNames.compact,
    )}
  />
);

MessageCardFooter.displayName = 'MessageCardFooter';

MessageCardFooter.propTypes = {
  compact: PropTypes.bool,
  className: PropTypes.string,
};

MessageCardFooter.defaultProps = {
  compact: false,
  className: null,
};

export default enhancer(MessageCardFooter);
