import { DISPLAY_CONTENT_MODES } from './displayContentModes';

/**
 * Return the requested content.
 *
 * @param {string} content - Original content.
 * @param {string} translatedContent - Original content translated.
 * @param {string} displayContentMode - Display content mode.
 * @param {boolean} isContentTranslated - True if the content is translated.
 * @returns {string} Returns content or translatedContent given displayContentMode.
 */
export function switchDisplay(content, translatedContent, displayContentMode, isContentTranslated) {
  switch (displayContentMode) {
    case DISPLAY_CONTENT_MODES.ORIGINAL:
      return content;
    case DISPLAY_CONTENT_MODES.TRANSLATED:
      return translatedContent;
    case DISPLAY_CONTENT_MODES.AUTO:
    default:
      return (isContentTranslated ? translatedContent : content) || content;
  }
}

/**
 * Return whether or not the translated content is displayed.
 *
 * @param {boolean} isContentTranslated - True if the content is translated.
 * @param {string} displayContentMode - Display content mode.
 * @returns {boolean} If true, translated content is displayed.
 */
export function isTranslatedContentDisplayed(isContentTranslated, displayContentMode) {
  switch (displayContentMode) {
    case DISPLAY_CONTENT_MODES.ORIGINAL:
      return false;
    case DISPLAY_CONTENT_MODES.TRANSLATED:
      return true;
    case DISPLAY_CONTENT_MODES.AUTO:
    default:
      return !!isContentTranslated;
  }
}

export const isTranslatedContentEmpty = content => !content;
