import makeReviewFormDialog from 'react/business/review/form/dialog/base/ReviewFormDialog';

import withSubmitCreateReview from 'react/business/review/form/base/withSubmitCreateReview';
import CreateFormStep from './steps/form/Form';
import CreateSuccessStep from './steps/success/Success';
import CreateFailureStep from './steps/failure/Failure';

// Handle onSubmit to create a review.
const SubmitCreateFormStep = withSubmitCreateReview(CreateFormStep);

export default makeReviewFormDialog(
  SubmitCreateFormStep,
  CreateSuccessStep,
  CreateFailureStep,
);
