import { defineMessages } from 'react-intl';

export default defineMessages({
  LABEL: {
    id: 'home.indicators.section.statistics.url.label',
    defaultMessage: 'See Tangram Dashboard',
  },

  BUTTON_LABEL: {
    id: 'home.indicators.section.statistics.url.button.label',
    defaultMessage: 'Go To Dashboard',
  },
});
