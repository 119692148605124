import { noop } from 'lodash';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import globalMessages from 'config/global.messages';
import { suggestionShape } from 'shapes/product';

import { FormattedMessage } from 'react-intl';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogAction,
} from 'react/generic/dialog/Dialog';

import SuggestionFormFields from 'react/business/suggestion/form/base/steps/form/Fields.presentation';
import SuggestionFormChildrenContainer from 'react/business/suggestion/form/base/steps/form/Form.children.container';

import classNames from './form.module.scss';

const SuggestionFormChildren = compose(
  SuggestionFormChildrenContainer,
)(SuggestionFormFields);

const enhancer = compose(
  memo,
);

const SuggestionDialogStepForm = ({
  title,
  submitMessage,
  onSubmit,
  isSubmitting,
  onRequestClose,
  suggestion,
  productId,
}) => (
  <SuggestionFormChildren
    onSubmit={onSubmit}
    isSubmitting={isSubmitting}
    suggestion={suggestion}
    productId={productId}
  >
    {({ form, canSubmit }) => (
      <>
        <DialogTitle>
          {title}
        </DialogTitle>

        <DialogContent>
          <div className={classNames.formContainer}>
            {form}
          </div>
        </DialogContent>

        <DialogActions>
          <DialogAction
            outline
            onClick={onRequestClose}
          >
            <FormattedMessage {...globalMessages.CANCEL} />
          </DialogAction>

          <DialogAction
            type="submit"
            disabled={!canSubmit}
          >
            {submitMessage}
          </DialogAction>
        </DialogActions>
      </>
    )}
  </SuggestionFormChildren>
);

SuggestionDialogStepForm.displayName = 'SuggestionDialogStepForm';

SuggestionDialogStepForm.propTypes = {
  title: PropTypes.node.isRequired,
  submitMessage: PropTypes.node.isRequired,
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  onRequestClose: PropTypes.func,
  suggestion: suggestionShape,
  productId: PropTypes.string,
};

SuggestionDialogStepForm.defaultProps = {
  onSubmit: noop,
  isSubmitting: false,
  onRequestClose: noop,
  suggestion: null,
  productId: '',
};

export default enhancer(SuggestionDialogStepForm);
