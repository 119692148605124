import { compose, withStateHandlers, withHandlers } from 'recompose';
import memoizeOne from 'memoize-one';

export default compose(
  withStateHandlers(
    () => ({
      isExpanded: false,
      isTruncated: false,
    }),
    {
      onExpand: () => () => ({ isExpanded: true }),
      onCollapse: () => () => ({ isExpanded: false }),
      setTruncated: () => isTruncated => ({ isTruncated }),
    },
  ),

  withHandlers({
    /*
     * We are using this in the preview of the Form.
     * Without changing the reference with the value of description,
     * it would not be called again and it won't be a good preview.
     * This also made us use the previous animationFrame with cancelAnimationFrame
     * meaning we had to store the previous animationFrame, and thus,
     * switching from SFC to Class Component.
     */
    detectContentTruncatedChange: ({ isTruncated, setTruncated }) => memoizeOne(() => (node) => {
      if (!node || isTruncated) {
        return;
      }

      // wait for HTML render to be finished to have the real values.
      // Executing callback too early will result in an equality,
      // thus hiding the see more button.
      requestAnimationFrame(() => {
        const isNodeTruncated = node.clientHeight !== node.scrollHeight;
        if (isNodeTruncated !== isTruncated) {
          setTruncated(isNodeTruncated);
        }
      });
    }),
  }),
);
