import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { FormattedMessage } from 'react-intl';

import { reviewShape } from 'shapes/product';

import ConnectedReviewCard from 'react/business/review/card/connectedReviewCard';

import messages from './home-latestreviews-section.messages';
import classNames from './home-latestreviews-section.module.scss';
import withLatestReviews from './withLatestReviews.connect';

const enhancer = compose(
  withLatestReviews,
  memo,
);

const HomeLatestReviewsSection = ({ reviews }) => (
  <div className={classNames.container}>
    <h4 className={classNames.title}>
      <FormattedMessage {...messages.TITLE} />
    </h4>
    <div className={classNames.carouselContainer}>
      {
        reviews.map(review => (
          <ConnectedReviewCard
            key={review.id}
            compact
            withTranslateButton
            review={review}
          />
        ))
      }
    </div>
  </div>
);

HomeLatestReviewsSection.propTypes = {
  reviews: PropTypes.arrayOf(reviewShape),
};

HomeLatestReviewsSection.defaultProps = {
  reviews: [],
};

export default enhancer(HomeLatestReviewsSection);
