import React from 'react';
import { Icon, Menu } from 'antd';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import { noop } from 'lodash';

import Link from 'react/generic/link/Link';
import Badge from 'react/generic/badge/Badge';

import { PREFERENCES_TABS_MAP, SETTINGS_TABS_MAP } from 'config/constants';

import withCurrentUser from 'react/business/user/withCurrentUser/withCurrentUser.connect';
import { compose } from 'recompose';

import { currentUserShape } from 'shapes/user';
import classNames from './user-menu.module.scss';

const { Item, Divider } = Menu;

const MENU_ITEM_GROUPS = {
  PREFERENCES: [
    {
      ...PREFERENCES_TABS_MAP.notifications,
      icon: 'notification',
    },
    {
      ...PREFERENCES_TABS_MAP.created,
      icon: 'tool',
    },
    {
      ...PREFERENCES_TABS_MAP.validate,
      icon: 'check',
    },
    {
      ...PREFERENCES_TABS_MAP.followed,
      icon: 'star',
    },
    {
      ...PREFERENCES_TABS_MAP.history,
      icon: 'history',
    },
  ],
  SETTINGS: [
    {
      ...SETTINGS_TABS_MAP.language,
      icon: 'flag',
    },
    {
      ...SETTINGS_TABS_MAP.notifications,
      icon: 'setting',
    },
  ],
};

const enhancer = compose(
  withCurrentUser,
);

const UserMenu = ({
  notificationsUnreadCount,
  isGetUnreadNotificationsCountLoading,
  onClick,
  user: currentUser,
}) => (
  <Menu
    mode="vertical"
    onClick={onClick}
    className={classNames.userMenu}
  >
    {
      MENU_ITEM_GROUPS.PREFERENCES
        .filter((item) => {
          if (item.id === PREFERENCES_TABS_MAP.validate.id) {
            return currentUser.isDomainLeader
              || currentUser.isCommunityArchitect
              || currentUser.isBusinessUnitArchitect;
          }
          return true;
        })
        .map(item => (
          <Item key={item.id}>
            <Link to={item.href}>
              { item.icon && (
              <Icon type={item.icon} />
              )}
              <FormattedMessage {...item.label} />
            </Link>
            {
            (item.id === PREFERENCES_TABS_MAP.notifications.id
              && (notificationsUnreadCount > 0 || isGetUnreadNotificationsCountLoading))
                && (
                  <Badge
                    text=""
                    isLoading={isGetUnreadNotificationsCountLoading}
                    className={classNames.notificationsLinkContainer}
                  />
                )
          }
          </Item>
        ))
    }
    <Divider />
    {
      MENU_ITEM_GROUPS.SETTINGS.map(item => (
        <Item key={item.id}>
          <Link to={item.href}>
            { item.icon && (
              <Icon type={item.icon} />
            )}
            <FormattedMessage {...item.label} />
          </Link>
        </Item>
      ))
    }
  </Menu>
);

UserMenu.propTypes = {
  notificationsUnreadCount: PropTypes.number,
  isGetUnreadNotificationsCountLoading: PropTypes.bool,
  onClick: PropTypes.func,
  user: currentUserShape.isRequired,
};

UserMenu.defaultProps = {
  notificationsUnreadCount: 0,
  isGetUnreadNotificationsCountLoading: false,
  onClick: noop,
};

export default enhancer(UserMenu);
