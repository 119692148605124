import { defineMessages } from 'react-intl';

export default defineMessages({
  SHARE: {
    id: 'product.feedback.share',
    defaultMessage: 'share',
  },
  COPY_TEXT: {
    id: 'product.feedback.copy.text',
    defaultMessage: 'URL was copied in clipboard, you can share it now!',
  },
});
