import { compose } from 'recompose';

import connect from 'react/hoc/connectProxy';

import {
  selectAllProductsCount,
  selectAllMaintainedProductsCount,
  selectIsAllProductsCountLoading,
} from 'redux/products/selectors';

const mapStateToProps = state => ({
  productsCount: selectAllProductsCount(state),
  maintainedProductsCount: selectAllMaintainedProductsCount(state),
  loading: (
    selectIsAllProductsCountLoading(state)
  ),
});

export default compose(
  connect(mapStateToProps),
);
