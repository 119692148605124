import moment from 'moment';

import { ORDER_BY_OPTIONS } from 'react/business/suggestion/suggestions.constants';

export * from './suggestions.repository';

export const sortBy = {
  [ORDER_BY_OPTIONS.DATE_ASC]: (a, b) => (moment(b.date).isBefore(a.date) ? 1 : -1),
  [ORDER_BY_OPTIONS.DATE_DESC]: (a, b) => (moment(a.date).isBefore(b.date) ? 1 : -1),
};

export const userCanEditSuggestion = (suggestion, currentUserId) => (
  suggestion.author === currentUserId
  && !suggestion.directRepliesCount
);

export const userCanDeleteSuggestion = (suggestion, currentUserId) => (
  suggestion.author === currentUserId
  && !suggestion.directRepliesCount
);

