import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { compose } from 'recompose';
import SuggestionFormSuccessStep from 'react/business/suggestion/form/base/steps/success/Success';
import { withRouter } from 'react-router';
import { historyShape, matchShape } from 'shapes/router';
import useRenderAction from 'react/generic/dialog/userRenderAction';
import SuggestionDialogResultStep from '../ResultStep';

const enhancer = compose(
  withRouter,
  memo,
);

const SuggestionDialogStepSuccess = ({
  successMessage,
  onClickClose,
  match,
  history,
  redirectOnSingleMessage,
}) => {
  const renderAction = useRenderAction({
    history, match, onClickClose, redirectOnSingleMessage,
  });

  return (
    <SuggestionDialogResultStep>
      <SuggestionFormSuccessStep
        successMessage={successMessage}
        renderAction={renderAction}
      />
    </SuggestionDialogResultStep>
  );
};

SuggestionDialogStepSuccess.displayName = 'SuggestionDialogStepSuccess';

SuggestionDialogStepSuccess.propTypes = {
  successMessage: PropTypes.node.isRequired,
  onClickClose: PropTypes.func,
  match: matchShape,
  history: historyShape,
  redirectOnSingleMessage: PropTypes.bool,
};

SuggestionDialogStepSuccess.defaultProps = {
  onClickClose: noop,
  match: {},
  history: {},
  redirectOnSingleMessage: false,
};

export default enhancer(SuggestionDialogStepSuccess);
