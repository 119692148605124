export const TOOLTIP_DEFINITION = {
  'data-tip': true,
  'data-effect': 'solid',
  'data-place': 'top',
  'data-event': 'mouseenter',
  'data-event-off': 'mouseleave',
};

export const TOOLTIP_DEFINITION_SPECIFIC = {
  'data-tip': false,
  'data-effect': 'solid',
  'data-place': 'top',
  'data-event': 'mouseenter',
  'data-event-off': 'click',
};
