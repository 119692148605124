import React, { Suspense, lazy } from 'react';
import Favicon from 'react-favicon';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from 'react-router-dom';
import { formatRoute } from 'react-router-named-routes';

import GlobalLoader from 'react/business/global/GlobalLoader';

import Home from 'react/views/home/Home';
import LoadingView from 'react/views/loading/LoadingView';

import favicon from 'style/imgs/branding/favicon.ico';

import { PRODUCT_VIEW } from 'config/constants';

import ROUTES from './routes';

const About = lazy(() => import('react/views/about/About'));
const Product = lazy(() => import('react/views/product/Product'));
const Component = lazy(() => import('react/views/component/Component'));
const ProductList = lazy(() => import('react/views/productList/ProductList'));
const CreateReviewStandalone = lazy(() => import('react/views/createReviewStandalone/CreateReviewStandalone'));
const BusinessActivitiesList = lazy(() => import('react/views/businessActivitiesList/BusinessActivitiesList'));
const BusinessUnitsList = lazy(() => import('react/views/businessUnitsList/BusinessUnitsList'));
const ArchitectureBuildingBlocksList = lazy(() => import('react/views/architectureBuildingBlocksList/ArchitectureBuildingBlocksList'));
const SubsetsList = lazy(() => import('react/views/subsetsList/SubsetsList'));
const Preferences = lazy(() => import('react/views/preferences/Preferences'));
const SnippetConfiguration = lazy(() => import('react/views/snippet/SnippetConfiguration'));
const Settings = lazy(() => import('react/views/settings/Settings'));
const ErrorPage = lazy(() => import('react/views/special/ErrorPage'));
const CommunitiesList = lazy(() => import('react/views/communitiesList/CommunitiesList'));
const ProductCreateFormTuto = lazy(() => import('react/views/product/forms/createForm/ProductCreateFormTuto'));
const ProductCreateForm = lazy(() => import('react/views/product/forms/createForm/ProductCreateForm'));
const ProductEditForm = lazy(() => import('react/views/product/forms/editForm/ProductEditForm'));
const ProductCreateChoose = lazy(() => import('react/views/product/forms/createForm/ProductCreateFormChoose'));
const ComponentCreateForm = lazy(() => import('react/views/component/forms/createForm/ComponentCreateForm'));
const ComponentEditForm = lazy(() => import('react/views/component/forms/editForm/ComponentEditForm'));

const App = () => (
  <>
    <Router>
      <Suspense fallback={<LoadingView />}>
        <Favicon url={favicon} />
        <GlobalLoader />
        <Switch>
          <Route
            path={ROUTES.HOME}
            exact
            component={Home}
          />

          <Route
            path={ROUTES.ABOUT}
            exact
            component={About}
          />

          <Route
            path={ROUTES.PRODUCTS}
            exact
            component={ProductList}
          />

          <Route
            path={ROUTES.PRODUCT.BYUSER}
            exact
            component={ProductList}
          />

          <Route
            exact
            path={ROUTES.PRODUCT.CREATE_FORM.TUTO}
            component={ProductCreateFormTuto}
          />

          <Route
            exact
            path={ROUTES.PRODUCT.CREATE_FORM.CHOOSE}
            component={ProductCreateChoose}
          />

          <Route
            exact
            path={ROUTES.PRODUCT.CREATE_FORM.FORM}
            component={ProductCreateForm}
          />

          <Route
            exact
            path={ROUTES.COMPONENT.CREATE_FORM.FORM}
            component={ComponentCreateForm}
          />

          <Route
            exact
            path={ROUTES.PRODUCT.EDIT_FORM}
            // eslint-disable-next-line react/prop-types
            render={props => <ProductEditForm {...props} key={props.match.params.productId} />}
          />

          <Route
            exact
            path={ROUTES.COMPONENT.EDIT_FORM}
            // eslint-disable-next-line react/prop-types
            render={props => <ComponentEditForm {...props} key={props.match.params.componentId} />}
          />

          <Redirect
            exact
            from={ROUTES.PRODUCT.PRESENTATION_DEFAULT}
            to={formatRoute(ROUTES.PRODUCT.PRESENTATION, {
              view: PRODUCT_VIEW.OVERVIEW,
            })}
          />

          <Route
            path={ROUTES.PRODUCT.PRESENTATION}
            exact
            /*
            * Add a key in the route to force React to remount the Product component
            * This way we ensure all Product lifecycle functions are called when switching from
            * a product to another.
            */
            // eslint-disable-next-line react/prop-types
            render={props => <Product {...props} key={props.match.params.productId} />}
          />

          <Route
            path={ROUTES.COMPONENT.PRESENTATION}
            exact
            /*
            * Add a key in the route to force React to remount the Component component
            * This way we ensure all Component lifecycle functions are called when switching from
            * a product to another.
            */
            // eslint-disable-next-line react/prop-types
            render={props => <Component {...props} key={props.match.params.componentId} />}
          />

          <Route
            path={ROUTES.PRODUCT.FEEDBACK_STANDALONE}
            exact
            /*
            * Add a key in the route to force React to remount the Product component
            * This way we ensure all Product lifecycle functions are called when switching from
            * a product to another.
            */
            render={props => (
              <CreateReviewStandalone
                {...props}
                // eslint-disable-next-line react/prop-types
                key={props.match.params.productId}
              />
            )}
          />

          <Route
            path={ROUTES.PRODUCT.PRESENTATION_SINGLE}
            exact
            // eslint-disable-next-line react/prop-types
            render={props => <Product {...props} key={props.match.params.productId} />}
          />

          <Route
            path={ROUTES.BUSINESS_UNITS}
            exact
            component={BusinessUnitsList}
          />
          <Route
            path={ROUTES.BUSINESS_ACTIVITIES}
            exact
            component={BusinessActivitiesList}
          />
          <Route
            path={ROUTES.ARCHITECTURE_BUILDING_BLOCKS}
            exact
            component={ArchitectureBuildingBlocksList}
          />
          <Route
            path={ROUTES.SUBSETS}
            exact
            component={SubsetsList}
          />
          <Route
            path={ROUTES.COMMUNITIES}
            exact
            component={CommunitiesList}
          />
          <Route
            path={ROUTES.PREFERENCES}
            exact
            component={Preferences}
          />
          <Route
            path={ROUTES.SNIPPET_CONFIGURATION}
            exact
            component={SnippetConfiguration}
          />
          <Route
            path={ROUTES.SETTINGS}
            exact
            component={Settings}
          />

          <Redirect exact from="/" to={formatRoute(ROUTES.HOME)} />

          <Route
            path={ROUTES.ERRORS}
            // eslint-disable-next-line react/prop-types
            render={props => <ErrorPage {...props} code={props.match.params.code} />}
          />
          <Route
            // eslint-disable-next-line react/prop-types
            render={props => <ErrorPage {...props} code="404" />}
          />
        </Switch>
      </Suspense>
    </Router>
  </>
);

export default App;
