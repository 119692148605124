import { noop, isEmpty } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import Button from 'react/generic/button/Button';
import globalMessages from 'config/global.messages';
import Asset, { ICON_MAP } from 'react/generic/asset/Asset';

import messages from './replies.messages';
import classNames from './replies.module.scss';

export default class MessageCardReplies extends React.PureComponent {
  static propTypes = {
    reviewId: PropTypes.string,
    suggestionId: PropTypes.string,
    compact: PropTypes.bool,
    repliesCount: PropTypes.number,
    repliesExpanded: PropTypes.bool,
    className: PropTypes.string,
    onClickReply: PropTypes.func,
    /** Called when clicking on the expand replies button. (shouldExpand: bool) => void. */
    onClickExpandReplies: PropTypes.func,
  };

  static defaultProps = {
    reviewId: null,
    suggestionId: null,
    className: null,
    compact: false,
    repliesCount: 0,
    repliesExpanded: false,
    onClickReply: noop,
    onClickExpandReplies: noop,
  };

  /**
   * Called when user clicks reply.
   *
   * @returns {any} Props onClickReply return value.
   */
  onClickReply = () => this.props.onClickReply(
    !isEmpty(this.props.reviewId) ? this.props.reviewId : this.props.suggestionId,
  );

  /**
   * Called when user requests to expand the replies list.
   *
   * @returns {any} Props onClickExpandReplies return value.
   */
  onClickExpandReplies = () => this.props.onClickExpandReplies(!this.props.repliesExpanded);

  /** @returns {object} JSX. */
  render() {
    const {
      className,
      compact,
      repliesCount,
      repliesExpanded,
    } = this.props;

    return (
      <div
        className={cn(
          className,
          classNames.commentContainer,
          compact && classNames.compact,
        )}
      >
        { repliesCount ? (
          <button
            type="button"
            className={cn(
              'unstyled',
              classNames.expandRepliesButton,
              repliesExpanded && classNames.expanded,
            )}
            disabled={!repliesCount}
            onClick={this.onClickExpandReplies}
          >
            <div className={classNames.repliesCount}>
              <FormattedMessage {...messages.COMMENTS} values={{ count: repliesCount }} />
            </div>

            { !!repliesCount
              && (
                <Asset
                  name={ICON_MAP.chevron}
                  className={classNames.chevron}
                />
              ) }
          </button>
        ) : <span /> }

        <Button
          overrideClassName={classNames.replyButton}
          overrideClassNames={classNames}
          outline
          onClick={this.onClickReply}
        >
          <FormattedMessage {...globalMessages.REPLY} />
        </Button>
      </div>
    );
  }
}
