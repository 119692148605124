import { compose, withPropsOnChange } from 'recompose';

export default (onChangePropName = 'onChange') => compose(
  withPropsOnChange(
    [onChangePropName],
    ({ [onChangePropName]: onChange }) => ({
      [onChangePropName]: event => onChange(event.target.value),
    }),
  ),
);
