import React, { memo } from 'react';
import { compose } from 'recompose';
import { FormattedMessage } from 'react-intl';

import Rate from 'react/generic/rate/RateWithRoundedValue';
import RateLabel from 'react/generic/rate/RateLabel';
import { detailedMarksShape } from 'shapes/review';
import messages from 'react/business/review/form/base/steps/form/fields.messages';

import classNames from './rateCriteria.module.scss';

const enhancer = compose(
  memo,
);

const MessageCardRateCriteria = ({ marks }) => (
  <>
    { marks && Object.keys(marks).map(markName => (
      <div key={markName} className={classNames.rateCriterionContainer}>
        <div className={classNames.markName}>
          <FormattedMessage {...messages[markName]} />
        </div>
        <div className={classNames.markContainer}>
          <RateLabel mark={marks[markName]} className={classNames.rateLabel} />
          <Rate value={marks[markName]} disabled />
        </div>
      </div>
    ))}
  </>
);

MessageCardRateCriteria.propTypes = {
  marks: detailedMarksShape,
};

MessageCardRateCriteria.defaultProps = {
  marks: null,
};

export default enhancer(MessageCardRateCriteria);
