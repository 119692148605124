import React from 'react';
import { FormattedMessage } from 'react-intl';

import connectCreatedProductsHomeIndicator from './created-products/created-products-home-indicator.connect';
import connectCreatedReviewsHomeIndicator from './created-reviews/created-reviews-home-indicator.connect';
import connectStatisticsUrlIndicator from './statistics-url/statistics-url-indicator.connect';
import CreatedProductsHomeIndicator from './created-products/CreatedProductsHomeIndicator';
import CreatedReviewsHomeIndicator from './created-reviews/CreatedReviewsHomeIndicator';
import StatisticsUrlIndicator from './statistics-url/StatisticsUrlIndicator';

import classNames from './home-indicators-section.module.scss';
import messages from './home-indicators-section.messages';

const ConnectedCreatedProductsHomeIndicator = connectCreatedProductsHomeIndicator(
  CreatedProductsHomeIndicator,
);

const ConnectedCreatedReviewsHomeIndicator = connectCreatedReviewsHomeIndicator(
  CreatedReviewsHomeIndicator,
);

const ConnectedStatisticsUrlIndicator = connectStatisticsUrlIndicator(
  StatisticsUrlIndicator,
);

const HomeIndicatorsSection = () => (
  <div className={classNames.container}>
    <div className={classNames.title}>
      <FormattedMessage {...messages.TITLE} />
    </div>
    <div className={classNames.indicators}>
      <ConnectedCreatedProductsHomeIndicator />
      <ConnectedCreatedReviewsHomeIndicator />
      <ConnectedStatisticsUrlIndicator />
    </div>
  </div>
);

HomeIndicatorsSection.displayName = 'HomeIndicatorsSection';
HomeIndicatorsSection.propTypes = {};
HomeIndicatorsSection.defaultProps = {};

export default HomeIndicatorsSection;
