import { compose } from 'recompose';

import connect from 'react/hoc/connectProxy';
import withMediumBreakpoint from 'react/hoc/withMediumBreakpoint';
import withLargeBreakpoint from 'react/hoc/withLargeBreakpoint';

import {
  selectLatestCreatedProducts,
  selectIsLatestProductsLoading,
} from 'redux/products/selectors';

const mapStateToProps = state => ({
  latestCreatedProducts: selectLatestCreatedProducts(state),
  latestCreatedProductsLoading: selectIsLatestProductsLoading(state),
});

export default compose(
  connect(mapStateToProps),
  withMediumBreakpoint,
  withLargeBreakpoint,
);
