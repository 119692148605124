import { compose } from 'recompose';
import { uniq } from 'lodash';
import connect from 'react/hoc/connectProxy';

import { ACTIONS } from 'redux/reviews/constants';
import { createProductReview, getLatestReviews } from 'redux/reviews/actions';
import { getProduct } from 'redux/products/actions';
import { isLoadingCreateProductReview } from 'redux/reviews/selectors';

export default compose(
  connect(
    state => ({
      isSubmitting: isLoadingCreateProductReview(state),
    }),
    (dispatch, props) => ({
      onSubmit: async ({ productId, source, ...review }) => {
        const reviewToCreate = {
          ...review,
          source,
        };

        // Create the review
        const action = await dispatch(createProductReview(productId, reviewToCreate, true));

        if (action.type === ACTIONS.CREATE_PRODUCT_REVIEW_SUCCESS) {
          if (props.fromHome) {
            // Refresh the latest created reviews
            const actionLatestReviews = await dispatch(getLatestReviews());

            if (actionLatestReviews.type === ACTIONS.GET_LATEST_REVIEWS_SUCCESS) {
              // Get product ids of the reviews
              const ids = uniq(actionLatestReviews.reviews?.map(r => r.product) || []);
              await Promise.all(ids.map(id => dispatch(getProduct(id))));
            }
          }
          return props.onSubmitSuccess?.(action.review);
        }
        return props.onSubmitFailure?.(action.error);
      },
    }),
  ),
);
