import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Rate from 'react/generic/rate/RateWithRoundedValue';

import classNames from './product-rating.module.scss';

const ProductRating = ({
  reviewCount,
  mark,
  className,
  inline,
  disabled,
}) => (
  <div
    className={cn(
      className,
      classNames.container,
      inline && classNames.inline,
    )}
  >
    <Rate
      className={cn(classNames.stars, !disabled && classNames.clickable)}
      value={mark}
      disabled
    />
    <span>({reviewCount})</span>
  </div>
);

ProductRating.displayName = 'ProductRating';

ProductRating.propTypes = {
  reviewCount: PropTypes.number,
  className: PropTypes.string,
  mark: PropTypes.number,
  inline: PropTypes.bool,
  disabled: PropTypes.bool,
};

ProductRating.defaultProps = {
  reviewCount: 0,
  className: null,
  mark: null,
  inline: false,
  disabled: false,
};

export default memo(ProductRating);
