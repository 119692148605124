import { createSelector } from 'reselect';

export const selectNotificationsMap = state => state.notifications.map;

export const selectNotificationsList = createSelector(
  [
    selectNotificationsMap,
  ],
  notifications => Object.values(notifications || {}),
);

export const selectNotificationsCount = state => state.notifications.totalCount;

export const selectNotificationById = (state, id) => selectNotificationsMap(state)[id];

export const selectNotificationsUnreadCount = state => state.notifications.unreadCount;

export const selectIsGetNotificationsLoading
  = state => !!state.notifications.isLoading.getNotifications;

export const selectIsGetUnreadNotificationsCountLoading
  = state => !!state.notifications.isLoading.getUnreadNotificationsCount;

export const selectIsSetNotificationReadLoading
  = (state, id) => (
    !!state.notifications.isLoading.setNotificationRead[id]
    || !!state.notifications.isLoading.setNotificationsAsRead[id]
  );

export const selectIsRemoveNotificationLoading
  = (state, id) => (
    !!state.notifications.isLoading.removeNotification[id]
    || !!state.notifications.isLoading.removeNotifications[id]
  );

export const isLoadingNotifications = createSelector(
  [
    selectIsGetNotificationsLoading,
    selectIsGetUnreadNotificationsCountLoading,
    selectIsSetNotificationReadLoading,
    selectIsRemoveNotificationLoading,
  ],
  (...args) => args.some(v => v),
);

export const selectNotificationsFilter = state => state.notifications.filter;

export const selectNotificationsPagination = state => state.notifications.limit;
