import makeSuggestionFormDialog from 'react/business/suggestion/form/dialog/base/SuggestionFormDialog';

import withSubmitCreateSuggestion from 'react/business/suggestion/form/base/withSubmitCreateSuggestion';
import CreateSuccessStep from 'react/business/suggestion/form/dialog/create/steps/success/Success';
import CreateFailureStep from 'react/business/suggestion/form/dialog/create/steps/failure/Failure';
import CreateFormStep from './steps/form/Form';

// Handle onSubmit to create a suggestion.
const SubmitCreateFormStep = withSubmitCreateSuggestion(CreateFormStep);

export default makeSuggestionFormDialog(
  SubmitCreateFormStep,
  CreateSuccessStep,
  CreateFailureStep,
);
