import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import cn from 'classnames';

import classNames from './header.module.scss';

const enhancer = compose(
  memo,
);

const MessageCardHeader = ({ className, compact, ...props }) => (
  <header
    {...props}
    className={cn(
      classNames.header,
      className,
      compact && classNames.compact,
    )}
  />
);

MessageCardHeader.displayName = 'MessageCardHeader';

MessageCardHeader.propTypes = {
  compact: PropTypes.bool,
  className: PropTypes.string,
};

MessageCardHeader.defaultProps = {
  compact: false,
  className: null,
};

export default enhancer(MessageCardHeader);
