import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import cn from 'classnames';

import classNames from './dialog.module.scss';

const enhancer = compose(
  memo,
);

const DialogContent = ({ ...props }) => (
  <div
    {...props}
    className={cn(
      props.className,
      classNames.dialogContent,
    )}
  />
);

DialogContent.displayName = 'DialogContent';

DialogContent.propTypes = {
  className: PropTypes.string,
};

DialogContent.defaultProps = {
  className: null,
};

export default enhancer(DialogContent);
