import { defineMessages } from 'react-intl';

export default defineMessages({
  OK: { id: 'global.ok', defaultMessage: 'Ok' },
  CANCEL: { id: 'global.cancel', defaultMessage: 'Cancel' },
  CLOSE: { id: 'global.close', defaultMessage: 'Close' },
  BACK: { id: 'global.back', defaultMessage: 'Back' },
  PREVIOUS: { id: 'global.previous', defaultMessage: 'Previous' },
  NEXT: { id: 'global.next', defaultMessage: 'Next' },
  SUBMIT: { id: 'global.submit', defaultMessage: 'Submit' },
  CONFIRM: { id: 'global.confirm', defaultMessage: 'Confirm' },
  REMOVE: { id: 'global.remove', defaultMessage: 'Remove' },
  DELETE: { id: 'global.delete', defaultMessage: 'Delete' },
  CONTACT: { id: 'global.contact', defaultMessage: 'Contact' },
  SEE_MORE: { id: 'global.seeMore', defaultMessage: 'See more' },
  SEE_LESS: { id: 'global.seeLess', defaultMessage: 'See less' },
  SHOW_DETAILS: { id: 'global.showDetails', defaultMessage: 'Show details' },
  HIDE_DETAILS: { id: 'global.hideDetails', defaultMessage: 'Hide details' },
  PEOPLE: { id: 'global.people', defaultMessage: 'People' },
  REPLY: { id: 'global.reply', defaultMessage: 'Reply' },
  HELPFUL: { id: 'global.helpful', defaultMessage: 'Was this helpful?' },
  REPORT_ABUSE: { id: 'global.reportAbuse', defaultMessage: 'Report abuse' },
  PREVIEW: { id: 'global.preview', defaultMessage: 'Preview' },
  FILTERS: { id: 'global.filters', defaultMessage: 'Filters:' },
  UNEXPECTED_ERROR: { id: 'global.unexpectedError', defaultMessage: 'An unexpected error occurred.' },
  COMING_SOON: { id: 'global.comingSoon', defaultMessage: 'Coming soon' },
  YES: { id: 'global.yes', defaultMessage: 'Yes' },
  NO: { id: 'global.no', defaultMessage: 'No' },
  NOTHING_TO_SHOW: { id: 'global.nothingToShow', defaultMessage: 'Nothing to show.' },
  EXAMPLE: { id: 'global.example', defaultMessage: 'Example: ' },
  SAVE: { id: 'global.save', defaultMessage: 'Save' },

  ORDER_BY: { id: 'global.orderBy', defaultMessage: 'Order by:' },
  ORDER_BY_DATE_ASC: { id: 'global.orderByDateAsc', defaultMessage: 'Date (oldest first)' },
  ORDER_BY_DATE_DESC: { id: 'global.orderByDateDesc', defaultMessage: 'Date (newest first)' },

  LOAD_MORE: { id: 'global.loadMore', defaultMessage: 'See more results' },
});
