import React from 'react';
import PropTypes from 'prop-types';

import HomeIndicator from 'react/generic/indicator/HomeIndicator';
import Asset, { ICON_MAP } from 'react/generic/asset/Asset';
import { FormattedMessage } from 'react-intl';

import theme from 'style/theme.variables.scss';

import messages from './statistics-url-indicator.messages';
import classNames from './statistics-url-indicator.module.scss';

const StatisticsUrl = ({ href }) => (
  <HomeIndicator
    count="+"
    loadingColor={theme.purple}
    label={<FormattedMessage {...messages.LABEL} />}
    buttonLabel={messages.BUTTON_LABEL}
    countClassName={classNames.label}
    buttonClassNames={classNames}
    buttonIcon={<Asset name={ICON_MAP.activity} className={classNames.icon} />}
    href={href}
    isExternalUrl
  />
);

StatisticsUrl.displayName = 'StatisticsUrl';

StatisticsUrl.propTypes = {
  isBelowMediumBreakpoint: PropTypes.bool,
  href: PropTypes.string,
};

StatisticsUrl.defaultProps = {
  isBelowMediumBreakpoint: false,
  href: null,
};

export default StatisticsUrl;
