import React from 'react';
import { compose } from 'recompose';
import {
  node, string, objectOf, bool,
} from 'prop-types';
import { upperFirst } from 'lodash';
import cn from 'classnames';
import { FlapperSpinner } from 'react-spinners-kit';
import { FormattedMessage } from 'react-intl';

import { messageShape } from 'shapes/vendor';

import Link from 'react/generic/link/Link';
import Button from 'react/generic/button/Button';

import classNames from './home-indicator.module.scss';

const enhancer = compose(
);

const HomeIndicator = ({
  count,
  loading,
  loadingColor,
  label,
  countClassName,
  hideButton,
  buttonIcon,
  buttonLabel,
  buttonClassNames,
  href,
  isExternalUrl,
}) => {
  const button = hideButton ? '' : (
    <Button
      outline
      overrideClassName={cn(buttonClassNames.button, classNames.button)}
      overrideClassNames={buttonClassNames}
    >
      <span className={classNames.buttonIcon}>{buttonIcon}</span>
      <span className={classNames.buttonLabel}>
        <FormattedMessage {...buttonLabel}>{upperFirst}</FormattedMessage>
      </span>
    </Button>
  );

  const buttonLink = isExternalUrl ? (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={href}
    >
      {button}
    </a>
  ) : (
    <Link to={href}>{button}</Link>
  );

  return (
    <div className={classNames.container}>
      <div className={cn(classNames.count, countClassName, loading && classNames.loading)}>
        { loading ?
          <FlapperSpinner size={40} color={loadingColor} />
          : count }
      </div>

      <div className={classNames.label}>
        {label}
      </div>

      { !hideButton
        && (
          <div className={classNames.buttonContainer}>
            {buttonLink}
          </div>
        ) }
    </div>
  );
};

HomeIndicator.displayName = 'HomeIndicator';
HomeIndicator.propTypes = {
  count: node,
  loading: bool,
  loadingColor: string,
  label: node.isRequired,
  countClassName: string,
  hideButton: bool,
  buttonIcon: node,
  buttonLabel: messageShape,
  buttonClassNames: objectOf(string),
  href: string,
  isExternalUrl: bool,
};
HomeIndicator.defaultProps = {
  count: 0,
  loading: false,
  loadingColor: '',
  countClassName: '',
  hideButton: false,
  buttonIcon: '',
  buttonLabel: null,
  buttonClassNames: {},
  href: '',
  isExternalUrl: false,
};

export default enhancer(HomeIndicator);
