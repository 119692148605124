import * as RepliesService from 'services/replies';
import { selectUserPreferences } from 'redux/users/selectors';

import { ACTIONS } from './constants';

export const GET_REPLIES = (productId, reviewId, replyId, query) => ({
  type: ACTIONS.GET_REPLIES,
  productId,
  reviewId,
  replyId,
  query,
});

export const GET_REPLIES_SUCCESS = (productId, reviewId, replyId, replies) => ({
  type: ACTIONS.GET_REPLIES_SUCCESS,
  productId,
  reviewId,
  replyId,
  replies,
});

export const GET_REPLIES_FAILURE = (productId, reviewId, replyId, error) => ({
  type: ACTIONS.GET_REPLIES_FAILURE,
  productId,
  reviewId,
  replyId,
  error,
});

export const getReplies = (productId, reviewId, replyId, query) => async (dispatch, getState) => {
  const preferences = selectUserPreferences(getState());

  dispatch(GET_REPLIES(productId, reviewId, replyId, query));

  try {
    const replies = await RepliesService.getReplies(productId, reviewId, replyId, {
      ...query,
      lang: preferences.alwaysTranslateContent ? preferences.language : null,
    });

    return dispatch(GET_REPLIES_SUCCESS(productId, reviewId, replyId, replies));
  } catch (err) {
    return dispatch(GET_REPLIES_FAILURE(productId, reviewId, replyId, err));
  }
};

export const GET_REPLY = (productId, reviewId, replyId, query) => ({
  type: ACTIONS.GET_REPLY,
  productId,
  reviewId,
  replyId,
  query,
});

export const GET_REPLY_SUCCESS = (productId, reviewId, reply) => ({
  type: ACTIONS.GET_REPLY_SUCCESS,
  productId,
  reviewId,
  reply,
});

export const GET_REPLY_FAILURE = (productId, reviewId, replyId, error) => ({
  type: ACTIONS.GET_REPLY_FAILURE,
  productId,
  reviewId,
  replyId,
  error,
});

export const getReply = (productId, reviewId, replyId, query) => async (dispatch) => {
  dispatch(GET_REPLY(productId, reviewId, replyId, query));

  try {
    const reply = await RepliesService.getReply(productId, reviewId, replyId, query);

    return dispatch(GET_REPLY_SUCCESS(productId, reviewId, reply));
  } catch (err) {
    return dispatch(GET_REPLY_FAILURE(productId, reviewId, replyId, err));
  }
};

export const CREATE_REPLY = (productId, reviewId, replyId, reply) => ({
  type: ACTIONS.CREATE_REPLY,
  productId,
  reviewId,
  replyId,
  reply,
});

export const CREATE_REPLY_SUCCESS = (productId, reviewId, replyId, reply) => ({
  type: ACTIONS.CREATE_REPLY_SUCCESS,
  productId,
  reviewId,
  replyId,
  reply,
});

export const CREATE_REPLY_FAILURE = (productId, reviewId, replyId, error) => ({
  type: ACTIONS.CREATE_REPLY_FAILURE,
  productId,
  reviewId,
  replyId,
  error,
});

export const createReply = (productId, reviewId, replyId, reply) => async (dispatch) => {
  dispatch(CREATE_REPLY(productId, reviewId, replyId, reply));

  try {
    const createdReply = await RepliesService.createReply(productId, reviewId, replyId, reply);
    return dispatch(CREATE_REPLY_SUCCESS(productId, reviewId, replyId, createdReply));
  } catch (err) {
    return dispatch(CREATE_REPLY_FAILURE(productId, reviewId, replyId, err));
  }
};

export const VOTE_REPLY = (productId, reviewId, replyId, vote) => ({
  type: ACTIONS.VOTE_REPLY,
  productId,
  reviewId,
  replyId,
  vote,
});

export const VOTE_REPLY_SUCCESS = (productId, reviewId, reply) => ({
  type: ACTIONS.VOTE_REPLY_SUCCESS,
  productId,
  reviewId,
  reply,
});

export const VOTE_REPLY_FAILURE = (productId, reviewId, replyId, error) => ({
  type: ACTIONS.VOTE_REPLY_FAILURE,
  productId,
  reviewId,
  replyId,
  error,
});

export const voteReply = (productId, reviewId, replyId, vote) => async (dispatch) => {
  dispatch(VOTE_REPLY(productId, reviewId, replyId, vote));

  try {
    const reply = await RepliesService.voteReply(productId, reviewId, replyId, vote);
    return dispatch(VOTE_REPLY_SUCCESS(productId, reviewId, reply));
  } catch (err) {
    return dispatch(VOTE_REPLY_FAILURE(productId, reviewId, replyId, err));
  }
};

export const DELETE_REPLY = (productId, reviewId, replyId) => ({
  type: ACTIONS.DELETE_REPLY,
  productId,
  reviewId,
  replyId,
});

export const DELETE_REPLY_SUCCESS = (productId, reviewId, replyId) => ({
  type: ACTIONS.DELETE_REPLY_SUCCESS,
  productId,
  reviewId,
  replyId,
});

export const DELETE_REPLY_FAILURE = (productId, reviewId, replyId, err) => ({
  type: ACTIONS.DELETE_REPLY_FAILURE,
  productId,
  reviewId,
  replyId,
  err,
});

export const deleteReply = (productId, reviewId, replyId) => async (dispatch) => {
  dispatch(DELETE_REPLY(productId, reviewId, replyId));

  try {
    await RepliesService.deleteReply(productId, reviewId, replyId);
    return dispatch(DELETE_REPLY_SUCCESS(productId, reviewId, replyId));
  } catch (err) {
    return dispatch(DELETE_REPLY_FAILURE(productId, reviewId, replyId, err));
  }
};
