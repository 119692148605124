import { trim, pull } from 'lodash';
import { scrollTopTo } from 'services/utils';

/**
 * Scroll window to top.
 */
export const scrollAppToTop = () => {
  scrollTopTo(document.getElementById('app'), 0, 500);
};

/**
 * Get the array of classes defined on this element.
 *
 * @param {Element} el - The DOM Node Element.
 * @returns {string[]} - The classNames in an array.
 */
export const classes = el => trim(el.className).split(/\s+/).filter(v => v);

/**
 * Add a className to given element if not already included.
 *
 * @param {Element} el - The DOM Node Element.
 * @param {string} name - The className to add if not already included.
 * @returns {string} - The className property.
 */
export const addClassname = (el, name) => {
  if (el.classList) {
    el.classList.add(name);
    return el.className;
  }

  const classNames = classes(el);
  if (!classNames.includes(name)) {
    classNames.push(name);
  }
  el.className = classNames.join(' ');
  return el.className;
};

/**
 * Remove a className to given element.
 *
 * @param {Element} el - The DOM Node Element.
 * @param {string} name - The className to remove.
 * @returns {string} - The className property.
 */
export const removeClassname = (el, name) => {
  if (el.classList) {
    el.classList.remove(name);
    return el.className;
  }

  el.className = pull(classes(el), name).join(' ');
  return el.className;
};

/**
 * Remove a array of classNames.
 *
 * @param {Element} el - The DOM Node Element.
 * @param {string} names - The classNames to remove.
 * @returns {string} - The className property.
 */
export const removeClassnames = (el, names) => {
  names.split(' ').forEach(name => removeClassname(el, name));
  return el.className;
};

/**
 * Get the position of a DOM Node Element.
 *
 * @param {Element} el - The DOM Node Element.
 * @returns {object} - The top and left position of the DOM Node Element.
 */
export const getOffset = (el) => {
  let x = 0;
  let y = 0;
  let currentElt = el;

  while (currentElt
    && !Number.isNaN(currentElt.offsetLeft)
    && !Number.isNaN(currentElt.offsetTop)) {
    x += currentElt.offsetLeft - currentElt.scrollLeft;
    y += currentElt.offsetTop - currentElt.scrollTop;
    currentElt = currentElt.offsetParent;
  }
  return { top: y, left: x };
};
