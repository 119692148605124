import React, {
  memo,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { capitalize } from 'lodash';
import { transliterate } from 'transliteration';

import { userShape } from 'shapes/user';
import { isLatinAlphabet } from 'utils/index';
import Asset, { ICON_MAP } from 'react/generic/asset/Asset';
import EmployeeType from 'react/generic/employeeType/EmployeeType';
import classNames from './userName.module.scss';

const UserName = ({
  user,
}) => {
  const [isTransliterateValueDisplayed, setTransliterateValueDisplayed] = useState(false);
  const onSetTransliterateValueDisplayed = useCallback(
    () => setTransliterateValueDisplayed(!isTransliterateValueDisplayed),
    [setTransliterateValueDisplayed, isTransliterateValueDisplayed],
  );

  const userName = [user?.firstName, user?.lastName]
    .filter(Boolean)
    .map(capitalize)
    .join(' ');

  const onTransliterate = useMemo(
    () => transliterate(userName),
    [userName],
  );

  return (
    <div className={classNames.userNameWrapper}>
      {
        isTransliterateValueDisplayed ?
          onTransliterate
          : userName
      }

      <EmployeeType
        user={user}
        className={classNames.employeeType}
      />

      {!isLatinAlphabet(user?.firstName) ? (
        <button
          type="button"
          className="unstyled with-pointer"
          onClick={onSetTransliterateValueDisplayed}
        >
          <Asset
            className={classNames.translationButton}
            name={ICON_MAP.translate}
          />
        </button>
      ) : null}
    </div>
  );
};

UserName.displayName = 'UserName';

UserName.propTypes = {
  user: userShape,
};

UserName.defaultProps = {
  user: null,
};

export default memo(UserName);
