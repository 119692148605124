import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PRODUCT_LINKS } from 'config/constants';
import Asset, { ICON_MAP } from 'react/generic/asset/Asset';

import messages from './product-link.messages';
import classNames from './product-link.module.scss';

export const linksReferences = {
  [PRODUCT_LINKS.URL]: {
    icon: <Asset name={ICON_MAP.external_link} />,
    tooltip: <FormattedMessage {...messages.PRODUCT_URL_TOOLTIP} />,
    activeClassName: classNames.urlActive,
  },

  [PRODUCT_LINKS.SOURCE]: {
    icon: <Asset name={ICON_MAP.github} />,
    tooltip: <FormattedMessage {...messages.SOURCE_TOOLTIP} />,
  },

  [PRODUCT_LINKS.API_REFERENCE]: {
    icon: <span className={classNames.textIcon}>API</span>,
    tooltip: <FormattedMessage {...messages.API_REFERENCE_TOOLTIP} />,
  },

  [PRODUCT_LINKS.MARKETING]: {
    icon: <span className={classNames.textIcon}>WWW</span>,
    tooltip: <FormattedMessage {...messages.PRODUCT_MARKETING_URL_TOOLTIP} />,
  },

  [PRODUCT_LINKS.DISCUSSION]: {
    icon: <Asset name={ICON_MAP.message_circle} />,
    tooltip: <FormattedMessage {...messages.DISCUSSION_TOOLTIP} />,
  },

  [PRODUCT_LINKS.DOCUMENTATION]: {
    icon: <Asset name={ICON_MAP.book_simple} />,
    tooltip: <FormattedMessage {...messages.DOCUMENTATION_TOOLTIP} />,
  },

  [PRODUCT_LINKS.ROADMAP]: {
    icon: <Asset name={ICON_MAP.roadmap} />,
    tooltip: <FormattedMessage {...messages.ROADMAP_TOOLTIP} />,
  },

  [PRODUCT_LINKS.BACKLOG]: {
    icon: <Asset name={ICON_MAP.trello} />,
    tooltip: <FormattedMessage {...messages.BACKLOG_TOOLTIP} />,
  },

  [PRODUCT_LINKS.SUPPORT]: {
    icon: <Asset name={ICON_MAP.solution} />,
    tooltip: <FormattedMessage {...messages.SUPPORT_TOOLTIP} />,
  },

  [PRODUCT_LINKS.DATASETS]: {
    icon: <Asset name={ICON_MAP.datasets} />,
    tooltip: <FormattedMessage {...messages.DATASETS_TOOLTIP} />,
  },
};
