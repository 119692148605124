import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import ReactTooltip from 'react-tooltip';
import { FormattedMessage } from 'react-intl';
import TooltipLayout from 'react/generic/tooltip/Tooltip';

import messages from './tooltip-content.messages';
import classNames from './tooltip-content.module.scss';

const TooltipContent = ({
  className, body, specific, ...props
}) => (
  <ReactTooltip
    {...props}
    className={cn(className, specific ? classNames.customSpecificTheme : classNames.customTheme)}
    globalEventOff={specific ? 'click' : ''}
  >
    <TooltipLayout
      title={<FormattedMessage {...messages.HINT} />}
      body={body}
      titleClassName={classNames.title}
      bodyClassName={classNames.body}
    />
  </ReactTooltip>
);

TooltipContent.displayName = 'TooltipContent';

TooltipContent.propTypes = {
  className: PropTypes.string,
  body: PropTypes.node,
  specific: PropTypes.bool,
};

TooltipContent.defaultProps = {
  className: null,
  body: null,
  specific: false,
};

export default memo(TooltipContent);
