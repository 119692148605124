import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import cn from 'classnames';

import classNames from './card.module.scss';

const enhancer = compose(
  memo,
);

const Card = props => (
  <div {...props} className={cn(props.className, classNames.card)} />
);

Card.displayName = 'Card';

Card.propTypes = {
  className: PropTypes.string,
};

Card.defaultProps = {
  className: null,
};

export default enhancer(Card);
