import React from 'react';
import { number, bool } from 'prop-types';

import HomeIndicator from 'react/generic/indicator/HomeIndicator';
import { FormattedMessage } from 'react-intl';

import theme from 'style/theme.variables.scss';

import messages from './created-reviews-home-indicator.messages';
import classNames from './created-reviews-home-indicator.module.scss';

const CreatedReviewsHomeIndicator = ({
  count,
  loading,
}) => (
  <HomeIndicator
    count={count}
    loadingColor={theme.yellow}
    loading={loading}
    label={<FormattedMessage {...messages.LABEL} values={{ count }} />}
    hideButton
    countClassName={classNames.label}
  />
);

CreatedReviewsHomeIndicator.displayName = 'CreatedReviewsHomeIndicator';

CreatedReviewsHomeIndicator.propTypes = {
  count: number,
  loading: bool,
};

CreatedReviewsHomeIndicator.defaultProps = {
  count: 0,
  loading: false,
};

export default CreatedReviewsHomeIndicator;
