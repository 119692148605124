import { noop } from 'lodash';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import SafeHtml from 'react/generic/html/SafeHtml';
import TranslateButton from 'react/generic/translation/TranslateButton';

import globalMessages from 'config/global.messages';
import classNames from './content.module.scss';

import withTruncateText from './withTruncateText';

const enhancer = compose(
  withTruncateText,
  memo,
);

const MessageContentBase = ({
  className,
  withTranslateButton,
  onRequestTranslate,
  isTranslatedContentDisplayed,
  content,
  compact,
  isExpanded,
  isTruncated,
  onCollapse,
  onExpand,
  detectContentTruncatedChange,
}) => (
  <>
    { !compact ? (
      <SafeHtml
        unsafeHtml={content}
        className={cn(
          classNames.content,
          className,
        )}
      />
    ) : (
      <>
        <SafeHtml
          forwardRef={detectContentTruncatedChange(content)}
          unsafeHtml={content}
          className={cn(
            classNames.description,
            !isExpanded && classNames.collapsed,
          )}
        />
      </>
    )}
    { isTruncated && (
    <div>
      <button
        type="button"
        className={classNames.seeMoreButton}
        onClick={isExpanded ? onCollapse : onExpand}
      >
        <FormattedMessage
          {...(isExpanded ? globalMessages.SEE_LESS : globalMessages.SEE_MORE)}
        />
      </button>
    </div>
    )}
    { withTranslateButton && (
    <div className={classNames.buttonContainer}>
      <TranslateButton
        onRequestTranslate={onRequestTranslate}
        isTranslatedContentDisplayed={isTranslatedContentDisplayed}
      />
    </div>
    )}
  </>
);

MessageContentBase.displayName = 'MessageContentBase';

MessageContentBase.propTypes = {
  className: PropTypes.string,
  withTranslateButton: PropTypes.bool,
  onRequestTranslate: PropTypes.func.isRequired,
  isTranslatedContentDisplayed: PropTypes.bool.isRequired,
  content: PropTypes.node,
  compact: PropTypes.bool,
  isExpanded: PropTypes.bool,
  isTruncated: PropTypes.bool,
  onCollapse: PropTypes.func.isRequired,
  onExpand: PropTypes.func.isRequired,
  detectContentTruncatedChange: PropTypes.func.isRequired,
};

MessageContentBase.defaultProps = {
  className: null,
  withTranslateButton: false,
  content: null,
  compact: false,
  isExpanded: false,
  isTruncated: false,
};

const MessageContent = enhancer(MessageContentBase);

MessageContent.displayName = 'MessageContent';

MessageContent.propTypes = {
  /** Original content. */
  content: PropTypes.node,
  /** Original content translated. */
  translatedContent: PropTypes.node,
  /** To translate a message. */
  onTranslate: PropTypes.func,
};

MessageContent.defaultProps = {
  content: null,
  translatedContent: null,
  onTranslate: noop,
};

export default MessageContent;
