import React, { memo } from 'react';
import { compose } from 'recompose';

import PropTypes from 'prop-types';
import classNames from './flexWrapper.module.scss';

const enhancer = compose(
  memo,
);

const MessageCardFlexWrapper = ({ children }) => (
  <div className={classNames.messageCardFlexWrapper}>
    { children }
  </div>
);

MessageCardFlexWrapper.displayName = 'MessageCardFlexWrapper';

MessageCardFlexWrapper.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
};

MessageCardFlexWrapper.defaultProps = {
  children: [],
};

export default enhancer(MessageCardFlexWrapper);
