import React, { memo } from 'react';
import { compose } from 'recompose';

import ReviewFormFailureStep from 'react/business/review/form/base/steps/failure/Failure';
import ReviewDialogResultStep from '../ResultStep';

const enhancer = compose(
  memo,
);

const ReviewDialogStepFailure = props => (
  <ReviewDialogResultStep>
    <ReviewFormFailureStep {...props} />
  </ReviewDialogResultStep>
);

ReviewDialogStepFailure.displayName = 'ReviewDialogStepFailure';

export default enhancer(ReviewDialogStepFailure);
