import { difference, isEmpty, isEqual } from 'lodash';
import React, { Component } from 'react';

const defaultGetIdsLoadingFromProps = props => props.idsLoading;

const defaultGetIdsLoadedFromProps = props => props.idsLoaded;

const defaultDiff = (idsToLoadFromProps, idsLoading, idsLoaded) => difference(
  idsToLoadFromProps,
  idsLoading,
  idsLoaded,
);

export default ({
  // (props) => [idsToLoadFromProps]
  getIdsToLoadFromProps,
  // (idsToLoadFromProps, idsLoading, idsLoaded) => [idsToFetch]
  diff = defaultDiff,
  // (idsToFetch, props) => void
  fetchItemsFromIds,
  // (props) => [idsLoading]
  getIdsLoadingFromProps = defaultGetIdsLoadingFromProps,
  // (props) => [idsLoaded]
  getIdsLoadedFromProps = defaultGetIdsLoadedFromProps,
}) => WrappedComponent => class LazyLoader extends Component {
  /**
   * Lazy load on first mount.
   */
  componentDidMount() {
    this.lazyLoad();
  }

  /**
   * Lazy load on every update if the data set to load is different.
   *
   * @param {object} prevProps - Prev props.
   */
  componentDidUpdate(prevProps) {
    const idsToLoad = getIdsToLoadFromProps(this.props);
    const prevIdsToLoad = getIdsToLoadFromProps(prevProps);

    // Prevent infinite loop on loading failure.
    // Deep equal comparison.
    if (!isEqual(idsToLoad, prevIdsToLoad)) {
      this.lazyLoad();
    }
  }

  /**
   * Compute the list of ids to load, and loads if necessary.
   * Does not reload when the item is still loading.
   */
  lazyLoad() {
    const ids = getIdsToLoadFromProps(this.props);

    const idsToFetch = diff(
      ids,
      getIdsLoadingFromProps(this.props),
      getIdsLoadedFromProps(this.props),
    );

    if (!isEmpty(idsToFetch)) {
      fetchItemsFromIds(idsToFetch, this.props);
    }
  }

  /** @returns {object} JSX. */
  render() {
    return (
      <WrappedComponent {...this.props} />
    );
  }
};
