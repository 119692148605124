import { isEmpty, noop } from 'lodash';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import Link from 'react/generic/link/Link';

import { BUSINESS_OBJECTS } from 'config/constants';
import { itemShape } from './autocompleteResultsSection.shapes';
import classNames from '../../autocompleteResults.module.scss';
import Asset, { ICON_MAP } from '../../../../../generic/asset/Asset';

const enhancer = compose(
  memo,
);

const AutocompleteResultsSection = ({
  items,
  title,
  type,
  onResultClick,
}) => (!isEmpty(items) ? (
  <section className={classNames.section}>
    {title && <h2 className={classNames.sectionTitle}>{title}</h2>}

    <div className={classNames.sectionContent}>
      <ul className={classNames.sectionList}>
        {items.map((item) => {
          const showComponentLabel = item.componentId && item.componentTitle;

          return (
            <li
              key={item.id}
              className={classNames.sectionListItem}
            >
              <Link
                to={item.href}
                onClick={() => {
                  onResultClick(item);
                }}
              >

                <div className={classNames.sectionListItemName}>
                  {type !== BUSINESS_OBJECTS.PERSONS ?
                    item.name : `${(`${item.firstName} ${item.lastName}`).toUpperCase()} (${item.externalId})`}
                </div>
              </Link>

              {showComponentLabel ? (
                <Link to={item.componentHref}>
                  {item.componentTitle
                      && (
                        <div className={classNames.sectionListItemAction}>
                          <Asset name={ICON_MAP.build} className={classNames.icon} />
                          {item.componentTitle}
                        </div>
                      )}
                </Link>
              )
                : null}

            </li>
          );
        })}
      </ul>
    </div>
  </section>
) : null);

AutocompleteResultsSection.displayName = 'AutocompleteResultsSection';

AutocompleteResultsSection.propTypes = {
  items: PropTypes.arrayOf(itemShape),
  title: PropTypes.node,
  type: PropTypes.string,
  onResultClick: PropTypes.func,
};

AutocompleteResultsSection.defaultProps = {
  items: [],
  title: null,
  type: '',
  onResultClick: noop,
};

export default enhancer(AutocompleteResultsSection);
