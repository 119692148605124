import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import cn from 'classnames';

import CoverPicture from 'react/generic/coverPicture/CoverPicture';
import defaultAvatar from 'style/imgs/users/default_avatar.png';
import rabbit from 'style/imgs/users/rabbit.jpg';

import classNames from './avatar.module.scss';

const enhancer = compose(
  memo,
);

// EASTEREGG: once every twenty, render the legacy rabbit as the default avatar.
const getDefaultAvatar = () => (Math.random() > 0.95 ? rabbit : defaultAvatar);

const Avatar = ({ className, src, ...props }) => (
  <CoverPicture
    {...props}
    src={src || getDefaultAvatar()}
    className={cn(className, classNames.avatar)}
  />
);

Avatar.propTypes = {
  src: PropTypes.string,
  /** Root element className. */
  className: PropTypes.string,
};

Avatar.defaultProps = {
  src: null,
  className: null,
};

export default enhancer(Avatar);
