import classNames from './drag-source/list-field-drag-source.module.scss';

export const ITEM_TYPES = {
  DEFAULT: 'ITEM',
  FEATURES: 'FEATURES',
  BUSINESS_UNITS: 'BUSINESS_UNITS',
  TEAM_MEMBERS: 'TEAM_MEMBERS',
  MODULES: 'MODULES',
  LINKS: 'LINKS',
};

export const ACTIONS_POSITIONS = {
  ALIGNED: '',
  TOP: classNames.top,
};
