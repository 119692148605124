import { defineMessages } from 'react-intl';

import {
  PRODUCT_STAGES,
  PRODUCT_SORTING,
  PRODUCT_HOSTING_TYPES,
} from 'config/constants';

export const PROPOSITION_TYPES = {
  FULFILL_ALL: 'FULFILL_ALL',
  DIGITAL_RESOURCES: 'DIGITAL_RESOURCES',
  CREATE_TEAM: 'CREATE_TEAM',
  SUPPORT_MANAGEMENT: 'SUPPORT_MANAGEMENT',
  INVOICE_COST: 'INVOICE_COST',
  ANOTHER_REASON: 'ANOTHER_REASON',
};

export default defineMessages({
  BU_GLOBAL: { id: 'product.bu.global', defaultMessage: 'Global product' },
  BIG_PRODUCT: { id: 'product.bu.bigProduct', defaultMessage: 'Digital Product' },
  BU_LEADER: { id: 'product.bu.owner', defaultMessage: 'BU leader' },
  AUTHOR: { id: 'product.author', defaultMessage: 'Author' },
  KIND_PRODUCT: {
    id: 'product.kinds.product',
    defaultMessage: 'Product',
  },
  KIND_COMPONENT: {
    id: 'product.kinds.component',
    defaultMessage: 'Component',
  },
  [PRODUCT_STAGES.TBD]: {
    id: 'product.stage.tbd',
    defaultMessage: 'To Be Developed',
  },
  [PRODUCT_STAGES.WIP]: {
    id: 'product.stage.wip',
    defaultMessage: 'Work In Progress',
  },
  [PRODUCT_STAGES.LIVE]: {
    id: 'product.stage.live',
    defaultMessage: 'Live',
  },
  [PRODUCT_STAGES.DEC]: {
    id: 'product.stage.dec',
    defaultMessage: 'Deprecated',
  },
  [PRODUCT_STAGES.DEP]: {
    id: 'product.stage.dep',
    defaultMessage: 'Decommissioned',
  },
  [PRODUCT_STAGES.ARCH]: {
    id: 'product.stage.arch',
    defaultMessage: 'Archived',
  },
  [PRODUCT_SORTING.NAME_ASC]: {
    id: 'product.sorting.name',
    defaultMessage: 'Name',
  },
  [PRODUCT_SORTING.CREATED_DATE_DESC]: {
    id: 'product.sorting.createdDate',
    defaultMessage: 'Created',
  },
  [PRODUCT_SORTING.UPDATED_DATE_DESC]: {
    id: 'product.sorting.updatedDate',
    defaultMessage: 'Last updated',
  },
  [PRODUCT_HOSTING_TYPES.OPA]: {
    id: 'product.hostingTypes.onPremiseAdeo',
    defaultMessage: 'On Premise Adeo',
  },
  [PRODUCT_HOSTING_TYPES.OPB]: {
    id: 'product.hostingTypes.onPremiseBU',
    defaultMessage: 'On Premise BU',
  },
  [PRODUCT_HOSTING_TYPES.PRC]: {
    id: 'product.hostingTypes.privateCloud',
    defaultMessage: 'Private Cloud',
  },
  [PRODUCT_HOSTING_TYPES.PUC]: {
    id: 'product.hostingTypes.publicCloud',
    defaultMessage: 'Public Cloud',
  },
  [PRODUCT_HOSTING_TYPES.SAAS]: {
    id: 'product.hostingTypes.SaaS',
    defaultMessage: 'SaaS',
  },
  [PROPOSITION_TYPES.FULFILL_ALL]: {
    id: 'product.proposition.fulfillAll',
    defaultMessage: 'Fulfill all the criteria defining a digital product',
  },
  [PROPOSITION_TYPES.DIGITAL_RESOURCES]: {
    id: 'product.proposition.digitalResources',
    defaultMessage: "Don't fulfill all the criteria, but I need an identifier to provide digital resources",
  },
  [PROPOSITION_TYPES.CREATE_TEAM]: {
    id: 'product.proposition.createTeam',
    defaultMessage: "Don't fulfill all the criteria, but I need an identifier to create a team",
  },
  [PROPOSITION_TYPES.SUPPORT_MANAGEMENT]: {
    id: 'product.proposition.supportManagement',
    defaultMessage: "Don't fulfill all the criteria, but I need an identifier to have support/ticket management",
  },
  [PROPOSITION_TYPES.INVOICE_COST]: {
    id: 'product.proposition.invoiceCost',
    defaultMessage: "Don't fulfill all the criteria, but I need an identifier to invoice cost",
  },
  [PROPOSITION_TYPES.ANOTHER_REASON]: {
    id: 'product.proposition.anotherReason',
    defaultMessage: "Don't fulfill all the criteria, but I need an identifier for another reason",
  },
});
