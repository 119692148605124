import React, { memo } from 'react';
import { compose, withProps } from 'recompose';

import { FormattedMessage } from 'react-intl';

import ReviewDialogStepSuccess from 'react/business/review/form/dialog/base/steps/success/Success';

import messages from './success.messages';

export default compose(
  memo,

  withProps(() => ({
    successMessage: (
      <FormattedMessage {...messages.REVIEW_CREATE_SUCCESS} />
    ),
    redirectOnSingleMessage: true,
  })),
)(ReviewDialogStepSuccess);
