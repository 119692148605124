import { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, renameProp } from 'recompose';
import { fieldArrayFieldsPropTypes } from 'redux-form';
import LinksFormPresentation from './LinksForm.presentation';

const { shape } = PropTypes;

export const enhancer = compose(
  renameProp('fields', 'links'),
  memo,
);

const LinksForm = enhancer(LinksFormPresentation);

LinksForm.displayName = 'LinksForm';
LinksForm.propTypes = {
  fields: shape(fieldArrayFieldsPropTypes).isRequired,
};

export default LinksForm;
