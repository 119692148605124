import agent from 'services/http';
import { mapReviewFromServer } from './reviews.mapper';

const e = encodeURIComponent;

/**
 * GET /api/products/:productId/reviews.
 * Get the list of reviews of products.
 *
 * @async
 * @param {string} productId - Product id.
 * @param {object} query - Query parameters.
 * @returns {{reviews: Array<object>, count: number}} Results.
 */
export async function getProductReviews(productId, query) {
  const { body: { reviews, count } } = await agent
    .get(`/api/products/${e(productId)}/reviews`)
    .query(query);

  return {
    reviews: (reviews || []).map(mapReviewFromServer),
    count,
  };
}

/**
 * GET /api/products/:productId/reviews/:reviewId.
 * Get a specific review.
 *
 * @async
 * @param {string} productId - Product id.
 * @param {string} reviewId - Review id.
 * @param {object} query - Query parameters.
 * @returns {object} Review.
 */
export async function getProductReview(productId, reviewId, query) {
  const { body: review } = await agent
    .get(`/api/products/${e(productId)}/reviews/${e(reviewId)}`)
    .query(query);

  return mapReviewFromServer(review);
}

/**
 * POST /api/products/:productId/reviews.
 *
 * @async
 * @param {string} productId - Product id.
 * @param {object} review - Review.
 * @param {number} review.mark - Review mark.
 * @param {string} review.content - Review text.
 * @returns {object} Review - The created review.
 */
export async function createProductReview(productId, review) {
  const { body } = await agent
    .post(`/api/products/${e(productId)}/reviews`)
    .send(review);

  return mapReviewFromServer(body);
}

/**
 * DELETE /api/products/:productId/reviews/:reviewId.
 * Delete a specific review.
 *
 * @async
 * @param {string} productId - Product id.
 * @param {string} reviewId - Review id.
 * @returns {object} Http response body.
 */
export async function deleteProductReview(productId, reviewId) {
  const { body } = await agent
    .delete(`/api/products/${e(productId)}/reviews/${e(reviewId)}`);

  return body;
}

/**
 * PATCH /api/products/:productId/reviews/:reviewId.
 * Patch a specific review.
 *
 * @async
 * @param {string} productId - Product id.
 * @param {object} review - Review.
 * @returns {object} Review - The updated review.
 */
export async function patchProductReview(productId, review) {
  const { body } = await agent
    .patch(`/api/products/${e(productId)}/reviews/${e(review.id)}`)
    .send(review);

  return mapReviewFromServer(body);
}

/**
 * POST /api/products/:productId/reviews/:reviewId/votes.
 *
 * @async
 * @param {string} productId - Product id.
 * @param {object} reviewId - Review id.
 * @param {number} vote - Vote (0/1/-1).
 * @returns {object} Review - The updated review.
 */
export async function voteProductReview(productId, reviewId, vote) {
  const { body } = await agent
    .post(`/api/products/${e(productId)}/reviews/${e(reviewId)}/votes`)
    .send({ vote });

  return mapReviewFromServer(body);
}

/**
 * GET /api/reviews.
 *
 * @async
 * @param {object} query - Query parameters.
 * @returns {{reviews: Array<object>, count: number}} Results.
 */
export async function getReviews(query) {
  const { body: { reviews, count } } = await agent
    .get('/api/reviews')
    .query(query);

  return {
    reviews: (reviews || []).map(mapReviewFromServer),
    count,
  };
}
