import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { compose, withHandlers } from 'recompose';
import { formatRoute } from 'react-router-named-routes';
import cn from 'classnames';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';

import ROUTES from 'react/routes';
import connect from 'react/hoc/connectProxy';
import { reviewShape, productShape } from 'shapes/product';
import { userShape } from 'shapes/user';

import withDialogState from 'react/generic/dialog/withDialogState';

import { selectProductById } from 'redux/products/selectors';

import EditIconButton from 'react/generic/button/buttons/EditIconButton';
import DeleteIconButton from 'react/generic/button/buttons/DeleteIconButton';

import Link from 'react/generic/link/Link';
import MessageCard from 'react/generic/message/messageCard/MessageCard';
import MessageCardHeader from 'react/generic/message/messageCard/layout/header/Header';
import MessageCardMain from 'react/generic/message/messageCard/layout/main/Main';
import MessageCardFooter from 'react/generic/message/messageCard/layout/footer/Footer';
import MessageCardAuthor from 'react/generic/message/messageCard/author/Author';
import MessageCardDate from 'react/generic/message/messageCard/date/Date';
import MessageCardRate from 'react/generic/message/messageCard/rate/Rate';
import MessageCardHelpful from 'react/generic/message/messageCard/helpful/Helpful';
import MessageCardReplies from 'react/generic/message/messageCard/replies/Replies';
import MessageCardContent from 'react/generic/message/messageCard/content/Content';
import DeleteConfirmationDialog from 'react/business/review/delete/dialog/DeleteConfirmationDialog';
import CreateReplyFormDialog from 'react/business/review/reply/createReplyForm/CreateReplyFormDialog';
import EditReviewFormDialog from 'react/business/review/form/dialog/edit/EditReviewFormDialog';
import TranslationContentSwitch from 'react/generic/translation/TranslationContentSwitch.children';
import { isTranslatedContentEmpty } from 'react/generic/translation/translation.utils';

import MessageCardShare from 'react/generic/message/messageCard/share/Share';
import MessageCardFlexWrapper from 'react/generic/message/messageCard/layout/flexWrapper/MessageCardFlexWrapper';
import classNames from './review-card.module.scss';

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

const enhancer = compose(
  withDialogState('Reply'),
  withDialogState('EditReview'),
  withDialogState('DeleteReview'),

  withHandlers({
    onSubmitReview: ({ onCreateReply, onCloseReplyDialog }) => async (reply) => {
      // Submit reply.
      await onCreateReply?.(reply);

      onCloseReplyDialog();
    },
  }),

  connect(
    (state, props) => ({
      product: selectProductById(state, props.review.product),
    }),
  ),
);

const ReviewCard = ({
  author,
  review,
  product,
  className,
  compact,
  withTranslateButton,
  withReplies,
  repliesExpanded,
  onClickExpandReplies,
  onVote,
  onTranslate,
  canEditReview,
  canDeleteReview,
  isReplyDialogOpen,
  onOpenReplyDialog,
  onCloseReplyDialog,
  isEditReviewDialogOpen,
  onOpenEditReviewDialog,
  onCloseEditReviewDialog,
  isDeleteReviewDialogOpen,
  onOpenDeleteReviewDialog,
  onCloseDeleteReviewDialog,
  onSubmitReview,
}) => (
  <>
    <MessageCard
      className={cn(className, classNames.container)}
      compact={compact}
    >
      {compact && (
        <MessageCardHeader compact={compact} className={classNames.productNameHeader}>
          <div className={classNames.productNameLink}>
            <Link to={formatRoute(ROUTES.PRODUCT.REVIEWS, { productId: product?.id })}>
              <div className={classNames.productName}>
                { product && (
                  <ResponsiveEllipsis
                    text={product?.name}
                    className={classNames.ellipsis}
                    maxLine={2}
                    basedOn="letters"
                  />
                ) }
              </div>
            </Link>
          </div>
        </MessageCardHeader>
      )}

      <MessageCardHeader compact={compact}>
        <MessageCardAuthor author={author} compact={compact} />
        { !compact
          && <MessageCardRate mark={review.mark} detailedMarks={review.detailedMarks} /> }

        { compact
          && (
            <MessageCardDate className={classNames.dateContainer} date={review.date} />
          ) }
      </MessageCardHeader>

      <MessageCardMain compact={compact}>
        { compact
          && (
            <MessageCardRate mark={review.mark} className={classNames.rateContainer} />
          ) }

        { !compact && <MessageCardDate className={classNames.dateContainer} date={review.date} /> }

        <TranslationContentSwitch
          key={review.content}
          original={review.content}
          translated={review.translatedContent}
          onTranslate={onTranslate}
          isTranslatedContentEmpty={isTranslatedContentEmpty}
        >
          {(displayedContent, isTranslatedContentDisplayed, onRequestTranslate) => (
            <>
              <MessageCardContent
                className={classNames.contentContainer}
                withTranslateButton={withTranslateButton}
                content={displayedContent}
                isTranslatedContentDisplayed={isTranslatedContentDisplayed}
                onRequestTranslate={onRequestTranslate}
                compact
              />
              <CreateReplyFormDialog
                isOpen={isReplyDialogOpen}
                onRequestClose={onCloseReplyDialog}
                onSubmit={onSubmitReview}
                content={displayedContent}
                author={author}
                reviewDate={review.date}
              />
            </>
          )}
        </TranslationContentSwitch>
      </MessageCardMain>

      {!compact && (
        <MessageCardFooter compact={compact}>
          { !compact
            && (
              <MessageCardHelpful
                messageId={review.id}
                helpful={review.helpful}
                onVote={onVote}
                className={classNames.customHelpful}
              />
            ) }

          <div className={classNames.footerRight}>
            { !compact && (
              <div className={classNames.actionsContainer}>
                { canEditReview
                  && (
                    <EditIconButton
                      onClickEdit={onOpenEditReviewDialog}
                    />
                  ) }

                { canDeleteReview
                  && (
                    <DeleteIconButton
                      onClickDelete={onOpenDeleteReviewDialog}
                    />
                  ) }
              </div>
            )}
            { withReplies
              && (
                <MessageCardFlexWrapper>
                  <MessageCardReplies
                    compact={compact}
                    reviewId={review.id}
                    repliesCount={review.directRepliesCount}
                    repliesExpanded={repliesExpanded}
                    onClickReply={onOpenReplyDialog}
                    onClickExpandReplies={onClickExpandReplies}
                  />
                  <MessageCardShare appendText={review.id.toString()} />
                </MessageCardFlexWrapper>
              ) }
          </div>
        </MessageCardFooter>
      )}
    </MessageCard>

    <EditReviewFormDialog
      productId={review.product}
      isOpen={isEditReviewDialogOpen}
      onRequestClose={onCloseEditReviewDialog}
      review={review}
    />

    <DeleteConfirmationDialog
      isOpen={isDeleteReviewDialogOpen}
      onRequestClose={onCloseDeleteReviewDialog}
      productId={review.product}
      reviewId={review.id}
      onDeleteSuccess={onCloseDeleteReviewDialog}
    />
  </>
);

ReviewCard.displayName = 'ReviewCard';

ReviewCard.propTypes = {
  /** Review. */
  review: reviewShape.isRequired,
  /** Review author. */
  author: userShape,
  /** Product of this review. */
  product: productShape,
  /** Should display in compact mode. */
  compact: PropTypes.bool,
  /** Should display the translate button. */
  withTranslateButton: PropTypes.bool,
  /** Should display reply button and replies toggle. */
  withReplies: PropTypes.bool,
  /** Are the replies displayed. */
  repliesExpanded: PropTypes.bool,
  /** Root element classname. */
  className: PropTypes.string,
  /** Called when requesting to vote for the review. (reviewId, vote) => void. */
  onVote: PropTypes.func,
  /** Called when clicking on the expand replies button. (shouldExpand) => void. */
  onClickExpandReplies: PropTypes.func,
  /** Called when requesting to translate. () => ({success: bool}). */
  onTranslate: PropTypes.func,
  /** Should display edit button. */
  canEditReview: PropTypes.bool,
  /** Should display delete button. */
  canDeleteReview: PropTypes.bool,
  onSubmitReview: PropTypes.func,
  isReplyDialogOpen: PropTypes.bool.isRequired,
  onOpenReplyDialog: PropTypes.func.isRequired,
  onCloseReplyDialog: PropTypes.func.isRequired,
  isEditReviewDialogOpen: PropTypes.bool.isRequired,
  onOpenEditReviewDialog: PropTypes.func.isRequired,
  onCloseEditReviewDialog: PropTypes.func.isRequired,
  isDeleteReviewDialogOpen: PropTypes.bool.isRequired,
  onOpenDeleteReviewDialog: PropTypes.func.isRequired,
  onCloseDeleteReviewDialog: PropTypes.func.isRequired,
};

ReviewCard.defaultProps = {
  className: null,
  author: null,
  product: null,
  compact: false,
  withTranslateButton: false,
  withReplies: false,
  repliesExpanded: false,
  onVote: noop,
  onClickExpandReplies: noop,
  onTranslate: noop,
  canEditReview: false,
  canDeleteReview: false,
  onSubmitReview: noop,
};

export default enhancer(ReviewCard);
