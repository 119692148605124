import { uniqueId, last } from 'lodash';

// LIFO of layers.
let layers = [];

/**
 * On press escape, close the last layer inserted if necessary.
 *
 * @param {object} event - DOM event.
 */
function handleKeyUp(event) {
  if (
    event.key === 'Escape'
    // The IE is strong in this one.
    || event.key === 'Esc'
  ) {
    const lastLayer = last(layers);

    if (lastLayer?.shouldCloseOnEscape && lastLayer.onRequestClose) {
      lastLayer.onRequestClose();
    }
  }
}

document.addEventListener('keyup', handleKeyUp);

/**
 * Add a layer to the LIFO.
 *
 * @param {object} layer - Layer.
 * @param {string} [layer.id] - Id of the layer. If none is given, one will be generated.
 * @param {boolean} layer.shouldCloseOnEscape - Should request to close the modal when pressing esc.
 * @param {Function} layer.onRequestClose - Function to call when requesting to close the modal.
 * @returns {string} Id of the layer.
 */
export function addLayer(layer) {
  const layerData = {
    ...layer,
  };

  layerData.id = layerData.id || uniqueId('layer');

  layers.push(layerData);

  return layerData.id;
}

/**
 * Manually remove a layer from the LIFO.
 *
 * @param {string} layerId - Id of the layer returned by addLayer.
 */
export function removeLayer(layerId) {
  layers = layers.filter(({ id }) => id !== layerId);
}
