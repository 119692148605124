import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import cn from 'classnames';

import { userShape } from 'shapes/user';

import Avatar from 'react/generic/avatar/Avatar';
import UserName from 'react/generic/users/name/UserName';

import classNames from './author.module.scss';

const enhancer = compose(
  memo,
);

const MessageCardAuthor = ({
  author,
  compact,
  className,
  ...props
}) => (
  <div
    {...props}
    className={cn(
      className,
      classNames.author,
      compact && classNames.compact,
    )}
  >
    <Avatar src={author?.avatar} className={classNames.avatar} />
    <div className={classNames.nameContainer}>
      <div className={classNames.name}><UserName user={author} /></div>
      <div className={classNames.title}>{author?.title}</div>
    </div>
  </div>
);

MessageCardAuthor.displayName = 'MessageCardAuthor';

MessageCardAuthor.propTypes = {
  author: userShape,
  compact: PropTypes.bool,
  className: PropTypes.string,
};

MessageCardAuthor.defaultProps = {
  author: null,
  compact: false,
  className: null,
};

export default enhancer(MessageCardAuthor);
