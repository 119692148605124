import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import cn from 'classnames';

import Button from './Button';

import classNames from './icon-button.module.scss';

const enhancer = compose(
  memo,
);

const IconButton = ({
  className,
  children,
  ...props
}) => (
  <Button
    {...props}
    className={cn(
      className,
      classNames.iconButton,
    )}
  >
    <div className={classNames.iconContainer}>
      {children}
    </div>
  </Button>
);

IconButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

IconButton.defaultProps = {
  className: null,
  children: null,
};

IconButton.displayName = 'IconButton';

export default enhancer(IconButton);
