import React from 'react';
import { compose, withHandlers, withProps } from 'recompose';

import Asset, { ICON_MAP } from 'react/generic/asset/Asset';
import { getProductTitlesStartWith } from 'services/searches';

import Autocomplete from 'react/generic/form/autocomplete/Autocomplete';

import classNames from './products.module.scss';

const mapProducts = product => ({
  value: product.id,
  label: product.name,
});

const searchProductTitles = async (query) => {
  if (!query) {
    return [];
  }
  const results = await getProductTitlesStartWith(query);

  return results.map(mapProducts);
};

const customComponents = {
  DropdownIndicator: () => (
    <span className={classNames.loupeContainer}>
      <Asset name={ICON_MAP.loupe} />
    </span>
  ),
};

export default compose(
  withProps({
    components: customComponents,
  }),
  withHandlers({
    loadOptions: () => query => searchProductTitles(query),
  }),
)(Autocomplete);
