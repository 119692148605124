import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import cn from 'classnames';

import classNames from './result-step.module.scss';

const enhancer = compose(
  memo,
);

const SuggestionDialogResultStep = ({ children }) => (
  children ?
    React.cloneElement(
      React.Children.only(children),
      {
        className: cn(
          React.Children.only(children).props.className,
          classNames.container,
        ),
      },
    )
    : null
);

SuggestionDialogResultStep.displayName = 'SuggestionDialogResultStep';

SuggestionDialogResultStep.propTypes = {
  children: PropTypes.node,
};

SuggestionDialogResultStep.defaultProps = {
  children: null,
};

export default enhancer(SuggestionDialogResultStep);
