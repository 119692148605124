import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { elementShape } from 'shapes/vendor';

import { Link as ReactRouterLink } from 'react-router-dom';

const enhancer = compose(
  memo,
);

/* A Link that can be disabled. */
const Link = ({
  isDisabled,
  disabledElement,
  children,
  ...props
}) => React.createElement(
  isDisabled ? disabledElement : ReactRouterLink,
  props,
  children,
);

Link.displayName = 'LinkProxy';

Link.propTypes = {
  isDisabled: PropTypes.bool,
  disabledElement: elementShape,
  children: PropTypes.node,
};

Link.defaultProps = {
  isDisabled: false,
  disabledElement: 'div',
  children: null,
};

export default enhancer(Link);
