import { LATEST_REVIEWS_DISPLAY_COUNT, REVIEWS_PAGINATION_ITEM_NUMBER } from 'config/constants';
import * as ReviewsService from 'services/reviews';
import { selectUserPreferences } from 'redux/users/selectors';

import { ACTIONS } from './constants';

export const GET_PRODUCT_REVIEWS = (productId, { preserve, ...query }) => ({
  type: ACTIONS.GET_PRODUCT_REVIEWS,
  productId,
  query,
  preserve,
});

export const GET_PRODUCT_REVIEWS_SUCCESS = (productId, reviews, count, preserve) => ({
  type: ACTIONS.GET_PRODUCT_REVIEWS_SUCCESS,
  productId,
  reviews,
  count,
  preserve,
});

export const GET_PRODUCT_REVIEWS_FAILURE = (productId, error) => ({
  type: ACTIONS.GET_PRODUCT_REVIEWS_FAILURE,
  productId,
  error,
});

export const getProductReviews = (
  productId,
  { preserve, ...query },
) => async (dispatch, getState) => {
  const preferences = selectUserPreferences(getState());

  query.limit = preferences?.reviewsOnPage || REVIEWS_PAGINATION_ITEM_NUMBER;

  dispatch(GET_PRODUCT_REVIEWS(productId, { preserve, ...query }));

  try {
    const { count, reviews } = await ReviewsService.getProductReviews(productId, {
      ...query,
      lang: preferences.alwaysTranslateContent ? preferences.language : null,
    });

    return dispatch(GET_PRODUCT_REVIEWS_SUCCESS(productId, reviews, count, preserve));
  } catch (err) {
    return dispatch(GET_PRODUCT_REVIEWS_FAILURE(productId, err));
  }
};

export const GET_PRODUCT_REVIEW = (productId, reviewId, query) => ({
  type: ACTIONS.GET_PRODUCT_REVIEW,
  productId,
  reviewId,
  query,
});

export const GET_PRODUCT_REVIEW_SUCCESS = (productId, review) => ({
  type: ACTIONS.GET_PRODUCT_REVIEW_SUCCESS,
  productId,
  review,
});

export const GET_PRODUCT_REVIEW_FAILURE = (productId, reviewId, error) => ({
  type: ACTIONS.GET_PRODUCT_REVIEW_FAILURE,
  productId,
  reviewId,
  error,
});

export const getProductReview = (productId, reviewId, query) => async (dispatch) => {
  dispatch(GET_PRODUCT_REVIEW(productId, reviewId, query));

  try {
    const review = await ReviewsService.getProductReview(productId, reviewId, query);

    return dispatch(GET_PRODUCT_REVIEW_SUCCESS(productId, review));
  } catch (err) {
    return dispatch(GET_PRODUCT_REVIEW_FAILURE(productId, reviewId, err));
  }
};

export const CREATE_PRODUCT_REVIEW = (productId, review, noRedirect) => ({
  type: ACTIONS.CREATE_PRODUCT_REVIEW,
  productId,
  review,
  noRedirect,
});

export const CREATE_PRODUCT_REVIEW_SUCCESS = (productId, review) => ({
  type: ACTIONS.CREATE_PRODUCT_REVIEW_SUCCESS,
  productId,
  review,
});

export const CREATE_PRODUCT_REVIEW_FAILURE = (productId, noRedirect, error) => ({
  type: ACTIONS.CREATE_PRODUCT_REVIEW_FAILURE,
  productId,
  noRedirect,
  error,
});

export const createProductReview = (productId, review, noRedirect) => async (dispatch) => {
  dispatch(CREATE_PRODUCT_REVIEW(productId, review, noRedirect));

  try {
    const createdReview = await ReviewsService.createProductReview(productId, review);
    return dispatch(CREATE_PRODUCT_REVIEW_SUCCESS(productId, createdReview));
  } catch (err) {
    return dispatch(CREATE_PRODUCT_REVIEW_FAILURE(productId, noRedirect, err));
  }
};

export const VOTE_PRODUCT_REVIEW = (productId, reviewId, vote) => ({
  type: ACTIONS.VOTE_PRODUCT_REVIEW,
  productId,
  reviewId,
  vote,
});

export const VOTE_PRODUCT_REVIEW_SUCCESS = (productId, review, vote) => ({
  type: ACTIONS.VOTE_PRODUCT_REVIEW_SUCCESS,
  productId,
  review,
  vote,
});

export const VOTE_PRODUCT_REVIEW_FAILURE = (productId, reviewId, error) => ({
  type: ACTIONS.VOTE_PRODUCT_REVIEW_FAILURE,
  productId,
  reviewId,
  error,
});

export const voteProductReview = (productId, reviewId, vote) => async (dispatch) => {
  dispatch(VOTE_PRODUCT_REVIEW(productId, reviewId, vote));

  try {
    const review = await ReviewsService.voteProductReview(productId, reviewId, vote);
    return dispatch(VOTE_PRODUCT_REVIEW_SUCCESS(productId, review, vote));
  } catch (err) {
    return dispatch(VOTE_PRODUCT_REVIEW_FAILURE(productId, reviewId, err));
  }
};

export const PATCH_PRODUCT_REVIEW = (productId, review) => ({
  type: ACTIONS.PATCH_PRODUCT_REVIEW,
  productId,
  review,
});

export const PATCH_PRODUCT_REVIEW_SUCCESS = (productId, review) => ({
  type: ACTIONS.PATCH_PRODUCT_REVIEW_SUCCESS,
  productId,
  review,
});

export const PATCH_PRODUCT_REVIEW_FAILURE = (productId, err) => ({
  type: ACTIONS.PATCH_PRODUCT_REVIEW_FAILURE,
  productId,
  err,
});

export const patchProductReview = (productId, review) => async (dispatch) => {
  dispatch(PATCH_PRODUCT_REVIEW(productId, review));

  try {
    const updatedReview = await ReviewsService.patchProductReview(productId, review);
    return dispatch(PATCH_PRODUCT_REVIEW_SUCCESS(productId, updatedReview));
  } catch (err) {
    return dispatch(PATCH_PRODUCT_REVIEW_FAILURE(productId, err));
  }
};

export const DELETE_PRODUCT_REVIEW = (productId, reviewId) => ({
  type: ACTIONS.DELETE_PRODUCT_REVIEW,
  productId,
  reviewId,
});

export const DELETE_PRODUCT_REVIEW_SUCCESS = (productId, reviewId) => ({
  type: ACTIONS.DELETE_PRODUCT_REVIEW_SUCCESS,
  productId,
  reviewId,
});

export const DELETE_PRODUCT_REVIEW_FAILURE = (productId, reviewId, err) => ({
  type: ACTIONS.DELETE_PRODUCT_REVIEW_FAILURE,
  productId,
  reviewId,
  err,
});

export const deleteProductReview = (productId, reviewId) => async (dispatch) => {
  dispatch(DELETE_PRODUCT_REVIEW(productId, reviewId));

  try {
    await ReviewsService.deleteProductReview(productId, reviewId);
    return dispatch(DELETE_PRODUCT_REVIEW_SUCCESS(productId, reviewId));
  } catch (err) {
    return dispatch(DELETE_PRODUCT_REVIEW_FAILURE(productId, reviewId, err));
  }
};

export const GET_ALL_REVIEWS_COUNT = () => ({
  type: ACTIONS.GET_ALL_REVIEWS_COUNT,
});

export const GET_ALL_REVIEWS_COUNT_SUCCESS = count => ({
  type: ACTIONS.GET_ALL_REVIEWS_COUNT_SUCCESS,
  count,
});

export const GET_ALL_REVIEWS_COUNT_FAILURE = error => ({
  type: ACTIONS.GET_ALL_REVIEWS_COUNT_FAILURE,
  error,
});

export const getAllReviewsCount = () => async (dispatch) => {
  dispatch(GET_ALL_REVIEWS_COUNT());

  try {
    const { count } = await ReviewsService.getReviews({ limit: 0 });

    return dispatch(GET_ALL_REVIEWS_COUNT_SUCCESS(count));
  } catch (err) {
    return dispatch(GET_ALL_REVIEWS_COUNT_FAILURE(err));
  }
};

export const GET_LATEST_REVIEWS = () => ({
  type: ACTIONS.GET_LATEST_REVIEWS,
});

export const GET_LATEST_REVIEWS_SUCCESS = reviews => ({
  type: ACTIONS.GET_LATEST_REVIEWS_SUCCESS,
  reviews,
});

export const GET_LATEST_REVIEWS_FAILURE = error => ({
  type: ACTIONS.GET_LATEST_REVIEWS_FAILURE,
  error,
});

export const getLatestReviews = () => async (dispatch) => {
  dispatch(GET_LATEST_REVIEWS());

  try {
    const { reviews } = await ReviewsService.getReviews({ limit: LATEST_REVIEWS_DISPLAY_COUNT });
    return dispatch(GET_LATEST_REVIEWS_SUCCESS(reviews));
  } catch (err) {
    return dispatch(GET_LATEST_REVIEWS_FAILURE(err));
  }
};
