import { noop } from 'lodash';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';
import { FormattedMessage } from 'react-intl';

import globalMessages from 'config/global.messages';
import { REVIEW_SOURCES } from 'react/business/review/reviews.constants';
import { reviewShape } from 'shapes/product';

import withDisplayWarning from 'react/business/review/form/base/actions/withDisplayWarning';

import ReviewFormFields from 'react/business/review/form/base/steps/form/Fields.presentation';
import ReviewFormChildrenContainer from 'react/business/review/form/base/steps/form/Form.children.container';
import EmptyContentWarning from 'react/business/review/form/base/actions/warning/emptyContent/EmptyContent';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogAction,
} from 'react/generic/dialog/Dialog';

import messages from './form.messages';
import classNames from './form.module.scss';

const ReviewFormChildren = compose(
  ReviewFormChildrenContainer,
)(ReviewFormFields);

const enhancer = compose(
  memo,

  withDisplayWarning,

  withHandlers({
    onShowWarning: ({ onShowWarning }) => (event) => {
      event.preventDefault();
      return onShowWarning();
    },
  }),
);

const ReviewDialogStepForm = ({
  title,
  submitMessage,
  onSubmit,
  isSubmitting,
  onRequestClose,
  review,
  isWarningDisplayed,
  onShowWarning,
  onHideWarning,
  productId,
  source,
}) => (
  <ReviewFormChildren
    onSubmit={onSubmit}
    isSubmitting={isSubmitting}
    review={review}
    productId={productId}
    source={source}
  >
    {({ form, canSubmit, isContentEmpty }) => (
      <>
        <DialogTitle>
          {title}
        </DialogTitle>

        <DialogContent>
          <div className={classNames.explanationContainer}>
            <FormattedMessage {...messages.REVIEW_FORM_DIALOG_EXPLANATION} />
          </div>

          <div className={classNames.formContainer}>
            {form}
          </div>
        </DialogContent>

        <DialogActions>
          <DialogAction
            outline
            onClick={onRequestClose}
          >
            <FormattedMessage {...globalMessages.CANCEL} />
          </DialogAction>

          <EmptyContentWarning
            isDisplayed={isContentEmpty && isWarningDisplayed}
            onHide={onHideWarning}
          >
            <DialogAction
              type="submit"
              disabled={!canSubmit}
              onClick={(
                isContentEmpty && !isWarningDisplayed ?
                  onShowWarning
                  : onHideWarning
              )}
            >
              {isContentEmpty && isWarningDisplayed ?
                <FormattedMessage {...globalMessages.CONFIRM} />
                : submitMessage}
            </DialogAction>
          </EmptyContentWarning>
        </DialogActions>
      </>
    )}
  </ReviewFormChildren>
);

ReviewDialogStepForm.displayName = 'ReviewDialogStepForm';

ReviewDialogStepForm.propTypes = {
  title: PropTypes.node.isRequired,
  submitMessage: PropTypes.node.isRequired,
  isWarningDisplayed: PropTypes.bool.isRequired,
  onShowWarning: PropTypes.func.isRequired,
  onHideWarning: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  onRequestClose: PropTypes.func,
  review: reviewShape,
  productId: PropTypes.string,
  source: PropTypes.oneOf(Object.values(REVIEW_SOURCES)).isRequired,
};

ReviewDialogStepForm.defaultProps = {
  onSubmit: noop,
  isSubmitting: false,
  onRequestClose: noop,
  review: null,
  productId: '',
};

export default enhancer(ReviewDialogStepForm);
