import { compose, withProps, withHandlers } from 'recompose';
import { createForm } from 'rc-form';

import { htmlToEditorState, editorStateToHtml } from 'react/generic/form/wysiwyg/wysiwyg.utils';
import { DETAILED_MARKS } from 'react/business/review/reviews.constants';

export default compose(
  createForm(),

  withHandlers({
    onSubmit: ({ form, onSubmit, source }) => (event) => {
      event.preventDefault();
      event.stopPropagation();

      form.validateFields((err, values) => {
        if (!err) {
          const detailedMarks = { ...values.detailedMarks };
          Object.keys(detailedMarks).forEach((mark) => {
            if (detailedMarks[mark] === 0) {
              detailedMarks[mark] = undefined;
            }
          });
          onSubmit({
            ...values,
            content: editorStateToHtml(values.content),
            productId: values.productId?.value,
            detailedMarks,
            source,
          });
        }
      });
    },
  }),

  withProps(({
    form,
    isSubmitting,
    review,
    productId,
  }) => ({
    canSubmit: !isSubmitting
    && (!!form.getFieldValue('productId') || !!productId)
    && !!form.getFieldValue('mark'),

    isContentEmpty: !form.getFieldValue('content')?.getCurrentContent()?.hasText(),

    contentDecorator: form.getFieldDecorator(
      'content',
      {
        initialValue: htmlToEditorState(review?.content),
      },
    ),

    productIdDecorator: form.getFieldDecorator(
      'productId',
      {
        initialValue: productId || review?.productId ?
          { value: productId || review?.productId }
          : null,
      },
    ),

    showProductField: !productId,

    markDecorator: form.getFieldDecorator('mark', {
      initialValue: review?.mark,
      rules: [{
        type: 'number',
        min: 1,
        max: 5,
        required: true,
      }],
    }),

    detailedMarks: Object.keys(DETAILED_MARKS).map((mark) => {
      const detailMark = DETAILED_MARKS[mark];

      return {
        name: mark,
        decorator: form.getFieldDecorator(`detailedMarks.${detailMark}`, {
          initialValue: review?.detailedMarks[detailMark],
          rules: [{
            type: 'number',
            min: 0,
            max: 5,
          }],
        }),
      };
    }),
  })),
);
