import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import cn from 'classnames';
import { isEmpty } from 'lodash';

import { Icon, Popover } from 'antd';

import Rate from 'react/generic/rate/RateWithRoundedValue';
import RateLabel from 'react/generic/rate/RateLabel';
import { detailedMarksShape } from 'shapes/review';
import MessageCardRateCriteria from './criteria/RateCriteria';

import classNames from './rate.module.scss';

const enhancer = compose(
  memo,
);

const MessageCardRate = ({
  mark,
  className,
  detailedMarks,
  ...props
}) => (
  <div {...props} className={cn(className, classNames.rateContainer)}>
    <RateLabel mark={mark} className={classNames.rateLabel} />
    <Rate value={mark} disabled />

    {!isEmpty(detailedMarks)
      && (
      <Popover
        content={<MessageCardRateCriteria marks={detailedMarks} />}
        placement="right"
      >
        <Icon className={classNames.detailButton} type="plus-square" />
      </Popover>
      )
    }
  </div>
);

MessageCardRate.displayName = 'MessageCardRate';

MessageCardRate.propTypes = {
  mark: PropTypes.number,
  className: PropTypes.string,
  detailedMarks: detailedMarksShape,
};

MessageCardRate.defaultProps = {
  mark: 0,
  className: null,
  detailedMarks: null,
};

export default enhancer(MessageCardRate);
