import React, { memo } from 'react';
import { compose } from 'recompose';

import Button from 'react/generic/button/Button';

import classNames from './dialog.module.scss';

const enhancer = compose(
  memo,
);

const DialogAction = ({ ...props }) => (
  <Button
    {...props}
    overrideClassName={classNames.dialogAction}
    overrideClassNames={classNames}
  />
);

DialogAction.displayName = 'DialogAction';

export default enhancer(DialogAction);
