import { compose, withStateHandlers } from 'recompose';

export default (dialogName) => {
  const stateName = `is${dialogName}DialogOpen`;
  const openHandlerName = `onOpen${dialogName}Dialog`;
  const closeHandlerName = `onClose${dialogName}Dialog`;

  return compose(
    withStateHandlers(
      () => ({ [stateName]: false }),
      {
        [openHandlerName]: () => () => ({ [stateName]: true }),
        [closeHandlerName]: () => () => ({ [stateName]: false }),
      },
    ),
  );
};
