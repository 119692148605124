import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import connect from 'react/hoc/connectProxy';

import TopBarLoader from 'react/generic/loader/TopBarLoader';
import { selectGlobalLoading } from 'redux/app/selectors';

const mapStateToProps = state => ({
  loading: selectGlobalLoading(state),
});

const enhance = compose(
  connect(mapStateToProps),
);

const GlobalLoader = ({ loading }) => (
  <TopBarLoader
    fixed
    loading={loading}
  />
);

GlobalLoader.displayName = 'GlobalLoader';
GlobalLoader.propTypes = { loading: PropTypes.bool };
GlobalLoader.defaultProps = { loading: false };

const EnhancedGlobalLoader = enhance(GlobalLoader);
EnhancedGlobalLoader.displayName = 'EnhancedGlobalLoader';
EnhancedGlobalLoader.propTypes = {};
EnhancedGlobalLoader.defaultProps = {};

export default EnhancedGlobalLoader;
