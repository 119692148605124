import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import classNames from './top-bar-loader.module.scss';

const TopBarLoader = ({ loading, fixed }) => (
  <div
    className={cn(classNames.container, loading && classNames.loading, fixed && classNames.fixed)}
  >
    <div className={classNames.loader} />
  </div>
);

TopBarLoader.displayName = 'TopBarLoader';

TopBarLoader.propTypes = {
  loading: PropTypes.bool,
  fixed: PropTypes.bool,
};

TopBarLoader.defaultProps = {
  loading: false,
  fixed: false,
};

export default TopBarLoader;
