import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { compose } from 'recompose';
import { DragSource } from 'react-dnd';
import { Icon } from 'antd';
import cn from 'classnames';

import { identity } from 'services/functions/utils';

import { collect, spec } from './drag-source-spec';
import classNames from './list-field-drag-source.module.scss';
import { ACTIONS_POSITIONS } from '../constants';

const {
  bool, oneOfType, string, func, shape, number, oneOf, node,
} = PropTypes;

export default (type) => {
  const enhancer = compose(
    DragSource(type, spec, collect),
  );

  const ListFieldDragSource = ({
    actionsPosition,
    connectDragSource,
    connectDragPreview,
    onClickDelete,
    isDragging,
    index,
    children,
    hideDeleteButton,
  }) => {
    const deleteItem = useCallback(
      () => {
        onClickDelete({ index });
      },
      [onClickDelete, index],
    );

    return connectDragPreview(
      <div
        className={cn(classNames.container, actionsPosition, isDragging && classNames.dragging)}
      >
        {
          connectDragSource(
            <span className={classNames.handle}>
              <Icon type="drag" />
            </span>,
          )
        }
        <div className={classNames.item}>
          {children}
        </div>

        {!hideDeleteButton && (
          <div className={classNames.delete}>
            <button
              type="button"
              className="unstyled with-pointer"
              onClick={deleteItem}
            >
              <Icon type="delete" theme="twoTone" />
            </button>
          </div>
        )}
      </div>,
    );
  };

  ListFieldDragSource.displayName = 'ListFieldDragSource';
  ListFieldDragSource.propTypes = {
    actionsPosition: oneOf(Object.values(ACTIONS_POSITIONS)),
    connectDragSource: func,
    connectDragPreview: func,
    onClickDelete: func,
    isDragging: bool,
    item: shape({
      id: oneOfType([number, string]).isRequired,
    }),
    index: number,
    children: node,
    hideDeleteButton: bool,
  };
  ListFieldDragSource.defaultProps = {
    actionsPosition: ACTIONS_POSITIONS.ALIGNED,
    connectDragSource: identity,
    connectDragPreview: identity,
    onClickDelete: noop,
    isDragging: false,
    item: {},
    index: 0,
    children: null,
    hideDeleteButton: false,
  };

  const EnhancedListFieldDragSource = enhancer(ListFieldDragSource);

  EnhancedListFieldDragSource.displayName = 'EnhancedListFieldDragSource';
  EnhancedListFieldDragSource.propTypes = {
    children: node,
  };
  EnhancedListFieldDragSource.defaultProps = {
    children: null,
  };

  return EnhancedListFieldDragSource;
};
