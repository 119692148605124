import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Input } from 'antd';

import FormattedMessageChildren from 'react/generic/i18n/FormattedMessageChildren';
import Wysiwyg from 'react/generic/form/wysiwyg/Wysiwyg';
import Products from 'react/business/products/form/autocompletes/Products';
import productMessages from 'react/business/products/form/autocompletes/product.messages';

import messages from './fields.messages';
import classNames from './fields.module.scss';

const enhancer = compose(
  memo,
);

const SuggestionFormFields = ({
  titleDecorator,
  contentDecorator,
  productIdDecorator,
  showProductField,
}) => (
  <>
    { (showProductField) && (
      <div>
        <FormattedMessageChildren {...productMessages.SELECT_PRODUCT_PLACEHOLDER}>
          { placeholder => productIdDecorator(
            <Products placeholder={placeholder} />,
          ) }
        </FormattedMessageChildren>
      </div>
    ) }

    <div className={classNames.titleContainer}>
      <FormattedMessageChildren {...messages.TITLE_PLACEHOLDER}>
        { placeholder => titleDecorator(
          <Input placeholder={placeholder} />,
        ) }
      </FormattedMessageChildren>
    </div>

    <div className={classNames.contentContainer}>
      <FormattedMessageChildren {...messages.CONTENT_PLACEHOLDER}>
        { placeholder => contentDecorator(
          <Wysiwyg
            editorClassName={classNames.content}
            placeholder={placeholder}
          />,
        ) }
      </FormattedMessageChildren>
    </div>
  </>
);

SuggestionFormFields.displayName = 'SuggestionFormFields';

SuggestionFormFields.propTypes = {
  titleDecorator: PropTypes.func.isRequired,
  contentDecorator: PropTypes.func.isRequired,
  productIdDecorator: PropTypes.func.isRequired,
  showProductField: PropTypes.bool,
};

SuggestionFormFields.defaultProps = {
  showProductField: false,
};

export default enhancer(SuggestionFormFields);
