import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import { fieldArrayFieldsPropTypes } from 'redux-form';

import classnames from 'classnames';
import { ACTIONS_POSITIONS, ITEM_TYPES } from './constants';
import classNames from './list-field.module.scss';
import makeListFieldDropTarget from './drop-target/ListFieldDropTarget';
import makeListFieldDragSource from './drag-source/ListFieldDragSource';

const { shape, func, oneOf } = PropTypes;
const renderNothing = () => null;

export default (itemType = ITEM_TYPES.DEFAULT, itemClassName) => {
  const ListFieldDropTarget = makeListFieldDropTarget(itemType);
  const ListFieldDragSource = makeListFieldDragSource(itemType);

  const ListField = ({
    actionsPosition,
    fields,
    renderItem,
    fieldProps,
    hideDeleteButton,
    ...props
  }) => {
    const onDrop = useCallback(
      (item, targetIndex) => {
        fields.move(item.index, targetIndex);
      },
      [fields],
    );

    const onClickDelete = useCallback(
      (item) => {
        fields.remove(item.index);
      },
      [fields],
    );

    return (
      fields.map((field, index) => {
        const item = fields.get(index);

        const children = React.Children.map(props.children, child => React.cloneElement(child, {
          field,
          item,
          index,
          ...fieldProps,
        }));

        if (!item.id) item.id = uuid();

        return (
          <ListFieldDropTarget
            key={item.id}
            className={classnames(classNames.item, itemClassName)}
            itemType={itemType}
            index={index}
            onDrop={onDrop}
          >
            <ListFieldDragSource
              id={item.id}
              actionsPosition={actionsPosition}
              index={index}
              item={item}
              onClickDelete={onClickDelete}
              hideDeleteButton={hideDeleteButton}
            >
              {children}
            </ListFieldDragSource>
          </ListFieldDropTarget>
        );
      })
    );
  };

  ListField.displayName = 'ListField';

  ListField.propTypes = {
    actionsPosition: oneOf(Object.values(ACTIONS_POSITIONS)),
    fields: shape(fieldArrayFieldsPropTypes).isRequired,
    renderItem: func,
    // eslint-disable-next-line react/forbid-prop-types
    fieldProps: PropTypes.object,
    hideDeleteButton: PropTypes.bool,
  };
  ListField.defaultProps = {
    actionsPosition: ACTIONS_POSITIONS.ALIGNED,
    renderItem: renderNothing,
    fieldProps: {},
    hideDeleteButton: false,
  };

  return ListField;
};
