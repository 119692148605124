import agent from 'services/http';
import { mapSuggestionFromServer } from './suggestions.mapper';

const e = encodeURIComponent;

/**
 * GET /api/products/:productId/suggestions.
 * Get the list of suggestions of products.
 *
 * @async
 * @param {string} productId - Product id.
 * @param {object} query - Query parameters.
 * @returns {{suggestions: Array<object>, count: number}} Results.
 */
export async function getProductSuggestions(productId, query) {
  const { body: { suggestions, count } } = await agent
    .get(`/api/products/${e(productId)}/suggestions`)
    .query(query);

  return {
    suggestions: (suggestions || []).map(mapSuggestionFromServer),
    count,
  };
}

/**
 * GET /api/products/:productId/suggestions/:suggestionId.
 * Get a specific suggestion.
 *
 * @async
 * @param {string} productId - Product id.
 * @param {string} suggestionId - Suggestion id.
 * @param {object} query - Query parameters.
 * @returns {object} Suggestion.
 */
export async function getProductSuggestion(productId, suggestionId, query) {
  const { body: suggestion } = await agent
    .get(`/api/products/${e(productId)}/suggestions/${e(suggestionId)}`)
    .query(query);

  return mapSuggestionFromServer(suggestion);
}

/**
 * POST /api/products/:productId/suggestions.
 *
 * @async
 * @param {string} productId - Product id.
 * @param {object} suggestion - Suggestion.
 * @param {string} suggestion.title - Suggestion title.
 * @param {string} suggestion.content - Suggestion text.
 * @returns {object} Suggestion - The created suggestion.
 */
export async function createProductSuggestion(productId, suggestion) {
  const { body } = await agent
    .post(`/api/products/${e(productId)}/suggestions`)
    .send(suggestion);

  return mapSuggestionFromServer(body);
}

/**
 * DELETE /api/products/:productId/suggestions/:suggestionId.
 * Delete a specific suggestion.
 *
 * @async
 * @param {string} productId - Product id.
 * @param {string} suggestionId - Suggestion id.
 * @returns {object} Http response body.
 */
export async function deleteProductSuggestion(productId, suggestionId) {
  const { body } = await agent
    .delete(`/api/products/${e(productId)}/suggestions/${e(suggestionId)}`);

  return body;
}

/**
 * PATCH /api/products/:productId/suggestions/:suggestionId.
 * Patch a specific suggestion.
 *
 * @async
 * @param {string} productId - Product id.
 * @param {object} suggestion - Suggestion.
 * @returns {object} Suggestion - The updated suggestion.
 */
export async function patchProductSuggestion(productId, suggestion) {
  const { body } = await agent
    .patch(`/api/products/${e(productId)}/suggestions/${e(suggestion.id)}`)
    .send(suggestion);

  return mapSuggestionFromServer(body);
}

/**
 * POST /api/products/:productId/suggestions/:suggestionId/votes.
 *
 * @async
 * @param {string} productId - Product id.
 * @param {object} suggestionId - Suggestion id.
 * @param {number} vote - Vote (0/1/-1).
 * @returns {object} Suggestion - The updated suggestion.
 */
export async function voteProductSuggestion(productId, suggestionId, vote) {
  const { body } = await agent
    .post(`/api/products/${e(productId)}/suggestions/${e(suggestionId)}/votes`)
    .send({ vote });

  return mapSuggestionFromServer(body);
}

/**
 * GET /api/suggestions.
 *
 * @async
 * @param {object} query - Query parameters.
 * @returns {{suggestions: Array<object>, count: number}} Results.
 */
export async function getSuggestions(query) {
  const { body: { suggestions, count } } = await agent
    .get('/api/suggestions')
    .query(query);

  return {
    suggestions: (suggestions || []).map(mapSuggestionFromServer),
    count,
  };
}
