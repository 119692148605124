import React from 'react';
import { number, bool } from 'prop-types';

import HomeIndicator from 'react/generic/indicator/HomeIndicator';
import { FormattedMessage } from 'react-intl';

import theme from 'style/theme.variables.scss';

import messages from './created-products-home-indicator.messages';
import classNames from './created-products-home-indicator.module.scss';

const CreatedProductsHomeIndicator = ({
  productsCount,
  maintainedProductsCount,
  loading,
}) => (
  <div className={classNames.labelsWrap}>
    <HomeIndicator
      count={maintainedProductsCount}
      loadingColor={theme.salmon}
      loading={loading}
      label={(
        <FormattedMessage
          {...messages.LABEL}
          values={{
            maintainedProductsCount,
          }}
        />

        )}
      hideButton
      countClassName={classNames.label}
    />
    <div className={classNames.allProductsWrap}>
      <HomeIndicator
        count={(
          <div className={classNames.allProductsCountWrap}>
            {productsCount}
            {' '}
            <div className={classNames.products}>
              <FormattedMessage
                {...messages.PRODUCT}
                values={{
                  productsCount,
                }}
              />
            </div>
          </div>

          )}
        loadingColor={theme.primary}
        loading={loading}
        label={(
          <div className={classNames.allProductsText}>
            <FormattedMessage
              {...messages.SUB_LABEL}
            />
          </div>

          )}
        hideButton
        countClassName={classNames.subLabel}
      />
    </div>
  </div>
);

CreatedProductsHomeIndicator.displayName = 'CreatedProductsHomeIndicator';

CreatedProductsHomeIndicator.propTypes = {
  productsCount: number,
  maintainedProductsCount: number,
  loading: bool,
  isBelowMediumBreakpoint: bool,
};

CreatedProductsHomeIndicator.defaultProps = {
  productsCount: 0,
  maintainedProductsCount: 0,
  loading: false,
  isBelowMediumBreakpoint: false,
};

export default CreatedProductsHomeIndicator;
