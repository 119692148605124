/**
 * Returns runtime environment variable if set.
 * Runtime environment is configured in `public/index.html` inside window.env.
 * They are configured using placeholders as in window.env = { A : '$MY_APP_A' }.
 *
 * @param {string} variableName - The variable name.
 * @returns {string} - The variable value.
 */
function getEnvironmentVariable(variableName) {
  if (
    window.env?.[variableName]
    && window.env?.[variableName] !== `$${process.env.ENV_PREFIX}${variableName}`
  ) {
    return window.env[variableName];
  }

  return null;
}

const appEnv = getEnvironmentVariable('ENV') || process.env.NODE_ENV;
const appVersion = process.env.npm_package_version;

export default {
  app: {
    name: process.env.npm_package_name,
    version: appVersion,
    env: appEnv,
  },

  externalUrls: {
    statistics: getEnvironmentVariable('EXTERNAL_STATISTICS_URL'),
  },
};
