import { createSelector } from 'reselect';

import { userCanEditReview, userCanDeleteReview } from 'services/reviews';
import { selectCurrentUser } from 'redux/users/selectors';

export const selectProductReviewsMapById = (state, productId) => state.reviews.map[productId];

export const selectReviewsCurrentCount
  = (state, productId) => Object.keys(state.reviews.map[productId] || {}).length;

export const selectProductReviewsListById = createSelector(
  [
    selectProductReviewsMapById,
  ],
  productReviewsMap => Object.values(productReviewsMap || {}),
);

export const selectProductReviewsListByIdTop2 = createSelector(
  [
    selectProductReviewsMapById,
  ],
  productReviewsMap => Object.values(productReviewsMap || {}).slice(0, 2),
);

export const selectProductReviewById = (
  state,
  productId,
  reviewId,
) => selectProductReviewsMapById(state, productId)?.[reviewId];

export const selectAllReviewsCount = state => state.reviews.allReviewsCount;

export const selectProductReviewsCount
  = (state, productId) => state.reviews.countByProduct[productId];

export const isLoadingGetAllReviewsCount = state => !!state.reviews.isLoading.getAllReviewsCount;
export const isLoadingGetProductReviews = state => !!state.reviews.isLoading.getProductReviews;
export const isLoadingGetProductReview = state => !!state.reviews.isLoading.getProductReview;
export const isLoadingCreateProductReview = state => !!state.reviews.isLoading.createProductReview;
export const isLoadingPatchProductReview = state => !!state.reviews.isLoading.patchProductReview;
export const isLoadingVoteProductReview = state => !!state.reviews.isLoading.voteProductReview;
export const isLoadingDeleteProductReview
  = state => !!state.reviews.isLoading.deleteProductReview;

export const isLoadingReviews = createSelector(
  [
    isLoadingGetProductReviews,
    isLoadingGetProductReview,
    isLoadingCreateProductReview,
    isLoadingPatchProductReview,
    isLoadingVoteProductReview,
    isLoadingDeleteProductReview,
  ],
  (...args) => args.some(v => v),
);

export const selectCanUserEditReview = createSelector(
  [
    (state, review) => review,
    selectCurrentUser,
  ],
  (review, user) => userCanEditReview(review, user?.id),
);

export const selectCanUserDeleteReview = createSelector(
  [
    (state, review) => review,
    selectCurrentUser,
  ],
  (review, user) => userCanDeleteReview(review, user?.id),
);

export const selectLatestReviewsList = state => Object.values(state.reviews.latestReviews);
