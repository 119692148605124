import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import memoize from 'memoize-one';

import { FormattedMessage } from 'react-intl';
import ReactSelect from 'react-select';

import themeVariables from 'style/theme.variables.scss';
import formVariables from 'react/generic/form/form.variables.scss';
import withSelectValueSelector from './withSelectValueSelector';

import messages from './select.messages';

const getBorderColor = (state, hasError) => {
  switch (true) {
    case hasError:
      return themeVariables.bittersweet;
    case state.isFocused:
      return themeVariables.primary;
    default:
      return themeVariables.borderGrey;
  }
};

class Select extends PureComponent {
  /** See react-select propTypes. */
  static propTypes = {
    /** Method returning a message when no options. () => React.node. */
    noOptionsMessage: PropTypes.func,
    placeholder: PropTypes.node,
    element: PropTypes.func,
    hasError: PropTypes.bool,
  };

  static defaultProps = {
    noOptionsMessage: null,
    placeholder: null,
    element: ReactSelect,
    hasError: false,
  };

  /**
   * Return the default no options message.
   *
   * @returns {object} React node.
   */
  static getDefaultNoOptionsMessage() {
    return (
      <FormattedMessage {...messages.DEFAULT_NO_OPTIONS} />
    );
  }

  /**
   * Create default noOptionsMessage for react-select.
   *
   * @returns {object} Function returning no options message.
   */
  getNoOptionMessage() {
    return this.props.noOptionsMessage || Select.getDefaultNoOptionsMessage;
  }

  /**
   * Get the placeholder or the default one if not specified.
   *
   * @returns {object} Placeholder React node.
   */
  getPlaceholder() {
    return (
      this.props.placeholder
      || (
        <FormattedMessage {...messages.DEFAULT_PLACEHOLDER} />
      )
    );
  }

  getStyles = memoize(hasError => ({
    control: (provided, state) => ({
      ...provided,
      border: `1px solid ${getBorderColor(state, hasError)}`,
      borderRadius: '0.3125rem',
      backgroundColor: state.isDisabled ? '#efefef' : 'white',
      boxShadow: 'none',
    }),

    singleValue: provided => ({
      ...provided,
      color: themeVariables.greyText,
      fontWeight: 400,
      fontSize: '0.875rem',
    }),

    placeholder: provided => ({
      ...provided,
      color: formVariables.formPlaceholderColor,
      fontSize: '0.875rem',
    }),

    container: provided => ({ ...provided, zIndex: 'auto' }),

    menu: provided => ({ ...provided, zIndex: 9999 }),
    menuList: provided => ({ ...provided, zIndex: 9999 }),
  }));

  /** @returns {object} JSX. */
  render() {
    const {
      element,
      hasError,
      ...props
    } = this.props;

    return React.createElement(
      element,
      {
        ...props,
        styles: this.getStyles(!!hasError),
        // Set a prefix different of the className to get a better css priority.
        placeholder: this.getPlaceholder(),
        noOptionsMessage: this.getNoOptionMessage(),
      },
    );
  }
}

export default Select;

export const SelectWithValueSelector = compose(
  withSelectValueSelector,
)(Select);
