import { ICON_MAP } from 'react/generic/asset/Asset';
import ROUTES from 'react/routes';

import { NAVIGATION_SHORTCUTS_MESSAGES } from 'config/constants.messages';

export const SELECT_OPTIONS = [
  {
    id: 'businessunits',
    icon: ICON_MAP.world,
    label: NAVIGATION_SHORTCUTS_MESSAGES.BUSINESS_UNITS,
    url: ROUTES.BUSINESS_UNITS,
  },
  {
    id: 'communities',
    icon: ICON_MAP.book,
    label: NAVIGATION_SHORTCUTS_MESSAGES.COMMUNITIES,
    url: ROUTES.COMMUNITIES,
  },
  {
    id: 'businessactivities',
    icon: ICON_MAP.layer,
    label: NAVIGATION_SHORTCUTS_MESSAGES.BUSINESS_ACTIVITIES,
    url: ROUTES.BUSINESS_ACTIVITIES,
  },
  {
    id: 'architectureBuildingBlocks',
    icon: ICON_MAP.build,
    label: NAVIGATION_SHORTCUTS_MESSAGES.ARCHITECTURE_BUILDING_BLOCKS,
    url: ROUTES.ARCHITECTURE_BUILDING_BLOCKS,
  },
  {
    id: 'subsets',
    icon: ICON_MAP.box,
    label: NAVIGATION_SHORTCUTS_MESSAGES.SUBSETS,
    url: ROUTES.SUBSETS,
  },
];
