import { defineMessages } from 'react-intl';

export default defineMessages({
  PRODUCT_URL: { id: 'product.links.productUrl', defaultMessage: 'Access app' },
  PRODUCT_URL_TOOLTIP: {
    id: 'product.links.productUrl.tooltip',
    defaultMessage: 'Access to the application. (Only for web applications)',
  },

  PRODUCT_MARKETING_URL: { id: 'product.links.productMarketingUrl', defaultMessage: 'Website' },
  PRODUCT_MARKETING_URL_TOOLTIP: {
    id: 'product.links.productMarketingUrl.tooltip',
    defaultMessage: 'Institutional website of the digital product.',
  },

  SOURCE: { id: 'product.links.source', defaultMessage: 'Source code' },
  SOURCE_TOOLTIP: {
    id: 'product.links.source.tooltip',
    defaultMessage: 'Source code of the digital product.',
  },

  API_REFERENCE: { id: 'product.links.apiReference', defaultMessage: 'API Reference' },
  API_REFERENCE_TOOLTIP: {
    id: 'product.links.apiReference.tooltip',
    defaultMessage: 'Documentations on how to use the API of the digital product.',
  },

  DISCUSSION: { id: 'product.links.discussion', defaultMessage: 'Discussion' },
  DISCUSSION_TOOLTIP: {
    id: 'product.links.discussion.tooltip',
    defaultMessage: 'Chat tool about the digital product.',
  },

  DOCUMENTATION: { id: 'product.links.documentation', defaultMessage: 'Documentation' },
  DOCUMENTATION_TOOLTIP: {
    id: 'product.links.documentation.tooltip',
    defaultMessage: 'Documentations on how to use the digital product.',
  },

  ROADMAP: { id: 'product.links.roadmap', defaultMessage: 'Roadmap' },
  ROADMAP_TOOLTIP: {
    id: 'product.links.roadmap.tooltip',
    defaultMessage: 'Roadmap of the digital product.',
  },

  BACKLOG: { id: 'product.links.backlog', defaultMessage: 'Backlog' },
  BACKLOG_TOOLTIP: {
    id: 'product.links.backlog.tooltip',
    defaultMessage: 'Backlog of the digital product.',
  },

  SUPPORT: { id: 'product.links.support', defaultMessage: 'Support' },
  SUPPORT_TOOLTIP: {
    id: 'product.links.support.tooltip',
    defaultMessage: 'Support of the digital product.',
  },

  DATASETS: { id: 'product.links.datasets', defaultMessage: 'Datasets' },
  DATASETS_TOOLTIP: {
    id: 'product.links.datasets.tooltip',
    defaultMessage: 'Datasets declared in Data-Finder with this product id.',
  },
});
