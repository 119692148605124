import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import cn from 'classnames';
import { useDebounceFn } from 'ahooks';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';

import AppHeaderPresentation from './header/AppHeader.presentation';
import connectAppHeader from './header/app-header.connect';
import AppFooter from './footer/AppFooter';
import classNames from './layout.module.scss';
import TipsAndNews from './tipsAndNews/TipsAndNews';

const AppHeader = connectAppHeader(AppHeaderPresentation);

const DEBOUNCED_HEADER_DISAPPEAR_ON_SCROLL = 50;

const enhancer = compose(
  memo,
);

const ApplicationLayout = ({
  children,
  subheader,
  mainClassName,
  pageType,
}) => {
  const containerRef = useBottomScrollListener(() => {});
  // I had to declare this const because of linter
  // which didn't allow me to use containerRef.current in cleanup.
  const node = containerRef.current;

  // Hide header while scrolling down.
  const [isHeaderHidden, setIsHeaderHidden] = useState(false);
  const [scrollPos, setScrollPos] = useState(0);

  const handleScroll = () => {
    const currScrollPos = containerRef.current?.scrollTop || scrollPos;
    setScrollPos(currScrollPos);
    setIsHeaderHidden(currScrollPos > scrollPos);
  };
  const { run: debouncedScrollHandler } = useDebounceFn(
    handleScroll,
    { wait: DEBOUNCED_HEADER_DISAPPEAR_ON_SCROLL },
  );

  useEffect(() => {
    // Make sure the ref is mounted before trying to reach it.
    requestAnimationFrame(() => {
      const currentRef = containerRef.current;
      if (currentRef) {
        currentRef.addEventListener('scroll', debouncedScrollHandler);
      }
    });

    return () => {
      if (node) {
        node.removeEventListener('scroll', debouncedScrollHandler);
      }
    };
  }, [containerRef, node, debouncedScrollHandler]);

  return (
    <>
      <div className={classNames.headerFixContainer}>
        <div
          id="headerContainer"
          className={cn(classNames.headerContainer, isHeaderHidden && classNames.hidden)}
        >
          <AppHeader pageType={pageType} />
          <TipsAndNews />
          {subheader}
        </div>
      </div>
      <main
        id="main"
        ref={containerRef}
        className={cn(mainClassName, classNames.content, classNames.mainContainer)}
      >
        <div className={classNames.childrenContainer}>
          <div className={classNames.navbarReplacer} />
          <div>
            {children}
          </div>
        </div>

        <footer role="contentinfo" className={classNames.footerContainer}>
          <AppFooter pageType={pageType} />
        </footer>
      </main>
    </>
  );
};

ApplicationLayout.displayName = 'ApplicationLayout';

ApplicationLayout.propTypes = {
  /** The page content (the component rendered by given route). */
  children: PropTypes.node,
  subheader: PropTypes.node,
  mainClassName: PropTypes.string,
  pageType: PropTypes.string,
};

ApplicationLayout.defaultProps = {
  children: null,
  subheader: null,
  mainClassName: null,
  pageType: '',
};

export default enhancer(ApplicationLayout);
