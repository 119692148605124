import { noop } from 'lodash';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { FormattedMessage } from 'react-intl';
import Button from 'react/generic/button/Button';

import messages from './translate-button.messages';
import classNames from './translateButton.module.scss';

const enhancer = compose(
  memo,
);

const TranslateButton = ({
  isTranslatedContentDisplayed,
  onRequestTranslate,
  ...props
}) => (
  <Button
    {...props}
    onClick={onRequestTranslate}
  >
    <FormattedMessage
      {...(isTranslatedContentDisplayed ? messages.SEE_ORIGINAL : messages.TRANSLATE)}
    />
  </Button>
);

TranslateButton.displayName = 'TranslateButton';

TranslateButton.propTypes = {
  outline: PropTypes.bool,
  isTranslatedContentDisplayed: PropTypes.bool,
  onRequestTranslate: PropTypes.func,
  overrideClassName: PropTypes.string,
  overrideClassNames: PropTypes.shape({}),
};

TranslateButton.defaultProps = {
  outline: true,
  isTranslatedContentDisplayed: false,
  onRequestTranslate: noop,
  overrideClassName: classNames.button,
  overrideClassNames: classNames,
};

export default enhancer(TranslateButton);
