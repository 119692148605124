import React, { memo } from 'react';
import { compose, mapProps } from 'recompose';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { withRouter } from 'react-router-dom';
import { formatRoute } from 'react-router-named-routes';
import { FormattedMessage } from 'react-intl';

import ROUTES from 'react/routes';
import Button from 'react/generic/button/Button';
import Link from 'react/generic/link/Link';
import withDialogState from 'react/generic/dialog/withDialogState';
import { PRODUCT_FORM_STEPS } from 'react/business/products/form/steps/form-steps.constants';
import SelectProductsByCategories from 'react/business/home/sections/about/actions/card/select/SelectProductsByCategories';
import createMessages from 'react/pages/product/form/step-0-choose/form-choose-step.messages';

import CreateSuggestionFormDialog from 'react/business/suggestion/form/dialog/create/CreateSuggestionFormDialog';
import CreateReviewFormDialog from 'react/business/review/form/dialog/create/CreateReviewFormDialog';

import ActionCard from './card/ActionCard';

import messages from './action-cards-grid.messages';
import classNames from './action-cards-grid.module.scss';

const enhancer = compose(
  withRouter,
  withDialogState('CreateSuggestion'),
  withDialogState('CreateReview'),
  mapProps(props => ({
    ...props,
    onOpenCreateReviewDialog: () => {
      props.onOpenCreateReviewDialog();
    },
    onOpenCreateSuggestionDialog: () => {
      props.onOpenCreateSuggestionDialog();
    },
  })),
  memo,
);

const ActionCardsGrid = ({
  isCreateSuggestionDialogOpen,
  onOpenCreateSuggestionDialog,
  onCloseCreateSuggestionDialog,
  onSubmitSuggestionSuccess,
  isCreateReviewDialogOpen,
  onOpenCreateReviewDialog,
  onCloseCreateReviewDialog,
  onSubmitReviewSuccess,
}) => (
  <div className={classNames.actionsGrid}>
    <ActionCard
      title={<FormattedMessage {...messages.CONSULT} />}
      quote={<FormattedMessage {...messages.CONSULT_SUB} />}
      hint={<FormattedMessage {...messages.CONSULT_HINT} />}
      className={classNames.consultCard}
      titleClassName={classNames.consultTitle}
    >
      <div className={classNames.selectProducts}>
        <SelectProductsByCategories />
      </div>
      <Link to={formatRoute(ROUTES.PRODUCTS)} className={classNames.linkButton}>
        <Button
          outline
          overrideClassName={classNames.consultButton}
          overrideClassNames={classNames}
        >
          <FormattedMessage {...messages.CONSULT_BUTTON_PRODUCTS} />
        </Button>
      </Link>
    </ActionCard>

    <ActionCard
      title={<FormattedMessage {...messages.CREATE} />}
      quote={<FormattedMessage {...messages.CREATE_SUB} />}
      hint={<FormattedMessage {...messages.CREATE_HINT} />}
      className={classNames.createCard}
      titleClassName={classNames.createTitle}
    >
      <Link
        to={formatRoute(
          ROUTES.PRODUCT.CREATE_FORM.FORM,
          {
            step: PRODUCT_FORM_STEPS.REQUEST,
          },
        )}
        className={classNames.linkButton}
      >
        <Button
          outline
          overrideClassName={classNames.createButton}
          overrideClassNames={classNames}
        >
          <FormattedMessage {...createMessages.PRODUCT_CREATE} />
        </Button>
      </Link>

    </ActionCard>

    <ActionCard
      title={<FormattedMessage {...messages.CONTRIBUTE} />}
      quote={<FormattedMessage {...messages.CONTRIBUTE_SUB} />}
      hint={<FormattedMessage {...messages.CONTRIBUTE_HINT} />}
      className={classNames.contributeCard}
      titleClassName={classNames.contributeTitle}
    >
      <div className={classNames.buttonModalContainer}>
        <Button
          outline
          overrideClassName={classNames.contributeButton}
          overrideClassNames={classNames}
          onClick={onOpenCreateReviewDialog}
        >
          <FormattedMessage {...messages.CONTRIBUTE_BUTTON_FEEDBACK} />
        </Button>
        <CreateReviewFormDialog
          isOpen={isCreateReviewDialogOpen}
          onRequestClose={onCloseCreateReviewDialog}
          onSubmitSuccess={onSubmitReviewSuccess}
          fromHome
        />
      </div>

      <div className={classNames.buttonModalContainer}>
        <Button
          outline
          overrideClassName={classNames.contributeButton}
          overrideClassNames={classNames}
          onClick={onOpenCreateSuggestionDialog}
        >
          <FormattedMessage {...messages.CONTRIBUTE_BUTTON_SUGGESTION} />
        </Button>
        <CreateSuggestionFormDialog
          isOpen={isCreateSuggestionDialogOpen}
          onRequestClose={onCloseCreateSuggestionDialog}
          onSubmitSuccess={onSubmitSuggestionSuccess}
        />
      </div>
    </ActionCard>
  </div>
);

ActionCardsGrid.displayName = 'ActionCardsGrid';

ActionCardsGrid.propTypes = {
  /* Suggestion modal */
  isCreateSuggestionDialogOpen: PropTypes.bool,
  onOpenCreateSuggestionDialog: PropTypes.func,
  onCloseCreateSuggestionDialog: PropTypes.func,
  onSubmitSuggestionSuccess: PropTypes.func,
  /* Review modal */
  isCreateReviewDialogOpen: PropTypes.bool,
  onOpenCreateReviewDialog: PropTypes.func,
  onCloseCreateReviewDialog: PropTypes.func,
  onSubmitReviewSuccess: PropTypes.func,
};

ActionCardsGrid.defaultProps = {
  /* Suggestion modal */
  isCreateSuggestionDialogOpen: false,
  onOpenCreateSuggestionDialog: noop,
  onCloseCreateSuggestionDialog: noop,
  onSubmitSuggestionSuccess: noop,
  /* Review modal */
  isCreateReviewDialogOpen: false,
  onOpenCreateReviewDialog: noop,
  onCloseCreateReviewDialog: noop,
  onSubmitReviewSuccess: noop,
};

export default enhancer(ActionCardsGrid);
