import { debounce, noop } from 'lodash';
import {
  compose,
  withState,
  withPropsOnChange,
  withHandlers,
  defaultProps,
} from 'recompose';

import { connect } from 'react-redux';

import { DEBOUNCE_TIME } from 'config/constants';
import { defaultFilters } from 'react/business/facets/connect/withFiltersControllerInState';
import { setCachedFilters } from 'redux/searches/actions';

export default compose(
  defaultProps({
    onSearch: noop,
    onSubmit: noop,
  }),

  // Debounce the onSearch callback.
  withPropsOnChange(
    ['onSearch'],
    ({ onSearch }) => ({
      onSearch: debounce(onSearch, DEBOUNCE_TIME),
    }),
  ),

  // Search input value.
  withState(
    'search',
    'onChangeSearch',
    '',
  ),

  withState(
    'isFocused',
    'onChangeFocus',
    false,
  ),

  connect(
    null,
    dispatch => ({
      resetCachedFiltersOnSearch: filters => dispatch(setCachedFilters(filters)),
    }),
  ),

  withHandlers({
    // Override onChangeSearch to pass the event value directly.
    onChangeSearch: props => (event) => {
      const value = event.target.value || '';
      props.onChangeSearch(value);
      props.onSearch({ search: value, searchContext: props.searchContext });
      props.resetCachedFiltersOnSearch(defaultFilters);
    },

    // Override onSubmit to prevent default form submition.
    onSubmit: props => (event) => {
      event.preventDefault();
      return props.onSubmit(props.search);
    },

    onFocus: ({
      search, onSearch, onChangeFocus, searchContext,
    }) => () => {
      if (search) {
        onSearch({ search, searchContext });
      }

      onChangeFocus(true);
    },

    onBlur: ({ onChangeFocus }) => () => {
      // without timeout the results container is unmounted before the result link is triggered
      setTimeout(
        () => onChangeFocus(false),
        500,
      );
    },
  }),

);
