import { injectIntl } from 'react-intl';

/**
 * @example
 * const messages = defineMessages({ MSG: { id: 'myMessage', defaultMessage: 'My message' }});
 *
 * // ...
 * <FormattedMessageChildren {...messages.MSG} />
 *   { message => <input placeholder={message} /> }
 * </FormattedMessageChildren>
 */
export default injectIntl(({
  intl,
  children,
  values,
  ...messageDescriptor
}) => children(intl.formatMessage(messageDescriptor, values)));
