import React, { memo } from 'react';
import { compose } from 'recompose';

import SuggestionFormFailureStep from 'react/business/suggestion/form/base/steps/failure/Failure';
import SuggestionDialogResultStep from '../ResultStep';

const enhancer = compose(
  memo,
);

const SuggestionDialogStepFailure = props => (
  <SuggestionDialogResultStep>
    <SuggestionFormFailureStep {...props} />
  </SuggestionDialogResultStep>
);

SuggestionDialogStepFailure.displayName = 'SuggestionDialogStepFailure';

export default enhancer(SuggestionDialogStepFailure);
