const configEmployeeTypes = [
  {
    code: '0',
    name: 'externe',
    isExternal: true,
  },
  {
    code: '1',
    name: 'collaborateur',
    isExternal: false,
  },
  {
    code: '2',
    name: 'prestataire',
    isExternal: true,
  },
  {
    code: '3',
    name: 'fournisseur',
    isExternal: true,
  },
  {
    code: '4',
    name: 'intérimaire',
    isExternal: true,
  },
  {
    code: '5',
    name: 'stagiaire',
    isExternal: true,
  },
  {
    code: '6',
    name: 'franchisé',
    isExternal: true,
  },
  {
    code: '7',
    name: 'détaché BU',
    isExternal: true,
  },
  {
    code: '8',
    name: 'Prestataire IT forfait',
    isExternal: true,
  },
  {
    code: '9',
    name: 'prestataire IT régie',
    isExternal: true,
  },
  {
    code: '10',
    name: 'prestataire IT occasionel',
    isExternal: true,
  },
  {
    code: '98',
    name: 'comptes spéciaux',
    isExternal: true,
  },
  {
    code: '99',
    name: 'retraité...',
    isExternal: true,
  }];

export default configEmployeeTypes;
