import React, { memo } from 'react';
import { compose } from 'recompose';
import moment from 'moment';

import { dateShape } from 'shapes/generic';
import { Tooltip } from 'antd';

const enhancer = compose(
  memo,
);

const MessageCardFooter = ({ date }) => (
  <Tooltip title={moment(date).format('lll')}>
    {moment(date).fromNow()}
  </Tooltip>
);

MessageCardFooter.propTypes = {
  date: dateShape,
};

MessageCardFooter.defaultProps = {
  date: null,
};

export default enhancer(MessageCardFooter);
