import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  renameProps,
  withState,
  withHandlers,
  withProps,
} from 'recompose';
import cn from 'classnames';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import omitProps from 'react/hoc/omitProps';

import { Editor } from 'react-draft-wysiwyg';
import Field from '../field/Field';

import withDebouncedOnChange from '../withDebouncedOnChange';
import reduxFormInputAdapter from '../reduxFormInputAdapter';

import classNames from './wysiwyg.module.scss';

// Handle focus to add a focus class when editor is focused.
const withAddClassNameOnFocus = compose(
  withState(
    'hasFocus',
    'onSetFocus',
    false,
  ),

  withHandlers({
    onFocus: ({ onFocus, onSetFocus }) => (event) => {
      onSetFocus(true);
      return onFocus && onFocus(event);
    },

    onBlur: ({ onBlur, onSetFocus }) => (event) => {
      onSetFocus(false);
      return onBlur && onBlur(event);
    },
  }),

  withProps(({ wrapperClassName, hasFocus }) => ({
    wrapperClassName: cn(
      wrapperClassName,
      hasFocus && classNames.focus,
    ),
  })),

  omitProps(['onSetFocus', 'hasFocus']),
);

const enhancer = compose(
  renameProps({
    value: 'editorState',
    onChange: 'onEditorStateChange',
    disabled: 'readOnly',
    className: 'wrapperClassName',
  }),

  // Add focus classname when the editor is focused.
  withAddClassNameOnFocus,
);

class Wysiwyg extends PureComponent {
  /** @returns {object} JSX. */
  render() {
    const {
      title,
      tooltip,
      error,
      warning,
      ...props
    } = this.props;

    return (
      <Field
        title={title}
        tooltip={tooltip}
        error={error}
        warning={warning}
      >
        <Editor
          {...props}
          wrapperClassName={cn(
            props.wrapperClassName,
            classNames.editorContainer,
            error && classNames.hasError,
            props.readOnly && classNames.disabled,
          )}
          toolbarClassName={cn(
            props.toolbarClassName,
            classNames.toolbar,
          )}
          editorClassName={cn(
            props.editorClassName,
            classNames.editor,
          )}
        />
      </Field>
    );
  }
}

Wysiwyg.propTypes = {
  // See https://jpuri.github.io/react-draft-wysiwyg/#/docs
  toolbar: PropTypes.shape({}),
  title: PropTypes.node,
  tooltip: PropTypes.node,
  error: PropTypes.node,
  warning: PropTypes.node,
  wrapperClassName: PropTypes.string,
  toolbarClassName: PropTypes.string,
  editorClassName: PropTypes.string,
  readOnly: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  forwardedRef: PropTypes.any,
};

Wysiwyg.defaultProps = {
  toolbar: {
    options: ['inline', 'blockType', 'list', 'history'],
    inline: {
      inDropdown: false,
      options: ['bold', 'italic', 'underline'],
    },
    blockType: {
      inDropdown: false,
      options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'Blockquote', 'Code'],
    },
    list: {
      inDropdown: false,
      options: ['unordered', 'ordered'],
    },
    history: {
      inDropdown: false,
      options: ['undo', 'redo'],
    },
  },
  title: null,
  tooltip: null,
  error: null,
  warning: null,
  wrapperClassName: null,
  toolbarClassName: null,
  editorClassName: null,
  readOnly: false,
  forwardedRef: null,
};

const EnhancedWysiwyg = enhancer(Wysiwyg);

export default React.forwardRef(
  (props, ref) => <EnhancedWysiwyg {...props} forwardedRef={ref} />,
);

export const DebouncedReduxFormWysiwyg = compose(
  reduxFormInputAdapter,
  withDebouncedOnChange(),
)(EnhancedWysiwyg);
