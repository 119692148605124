import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import cn from 'classnames';

import { Icon } from 'antd';

import classNames from './badge.module.scss';

const enhance = compose(
  memo,
);

const Badge = enhance(({
  text,
  isLoading,
  className,
  ...props
}) => (
  <div
    {...props}
    className={cn(className, classNames.badge)}
  >
    { isLoading ?
      <Icon type="loading" />
      : text }
  </div>
));

Badge.propTypes = {
  text: PropTypes.node,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
};

Badge.defaultProps = {
  text: null,
  isLoading: false,
  className: null,
};

Badge.displayName = 'Badge';

export default Badge;
