import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { FormattedMessage } from 'react-intl';

import globalMessages from 'config/global.messages';
import Dialog, {
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogAction,
} from 'react/generic/dialog/Dialog';

const DeleteConfirmation = ({
  onRequestDelete,
  title,
  content,
  isLoading,
  ...props
}) => (
  <Dialog {...props}>
    <DialogTitle>
      {title}
    </DialogTitle>

    <DialogContent>
      {content}
    </DialogContent>

    <DialogActions>
      <DialogAction
        outline
        onClick={props.onRequestClose}
      >
        <FormattedMessage {...globalMessages.CANCEL} />
      </DialogAction>

      <DialogAction
        onClick={onRequestDelete}
        disabled={isLoading}
      >
        <FormattedMessage {...globalMessages.SUBMIT} />
      </DialogAction>
    </DialogActions>
  </Dialog>
);

DeleteConfirmation.displayName = 'DeleteConfirmation';

DeleteConfirmation.propTypes = {
  onRequestDelete: PropTypes.func,
  onRequestClose: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isLoading: PropTypes.bool,
};

DeleteConfirmation.defaultProps = {
  onRequestDelete: noop,
  onRequestClose: noop,
  title: null,
  content: null,
  isLoading: false,
};

export default DeleteConfirmation;
