import React from 'react';
import { compose, withPropsOnChange } from 'recompose';

import { FormattedMessage } from 'react-intl';
import Warning from '../Warning';

import messages from './empty-content.messages';

export default compose(
  withPropsOnChange(
    () => false,
    () => ({
      warning: <FormattedMessage {...messages.EMPTY_CONTENT_WARNING} />,
    }),
  ),
)(Warning);
