import React, { memo } from 'react';
import { compose, withProps } from 'recompose';
import { FormattedMessage } from 'react-intl';

import { REVIEW_SOURCES } from 'react/business/review/reviews.constants';
import ReviewDialogStepForm from 'react/business/review/form/dialog/base/steps/form/Form';

import messages from './form.messages';

export default compose(
  memo,

  withProps(() => ({
    title: <FormattedMessage {...messages.REVIEW_FORM_DIALOG_TITLE} />,
    submitMessage: <FormattedMessage {...messages.SUBMIT} />,
    source: REVIEW_SOURCES.WEBSITE,
  })),
)(ReviewDialogStepForm);
