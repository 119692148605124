import React, { memo } from 'react';

import { Tooltip } from 'antd';
import messages from 'react/business/products/product.messages';

import FormattedMessageChildren from 'react/generic/i18n/FormattedMessageChildren';

const BigProductTooltip = props => (
  <FormattedMessageChildren {...messages.BIG_PRODUCT}>
    { message => <Tooltip title={message} {...props} /> }
  </FormattedMessageChildren>
);

BigProductTooltip.displayName = 'BigProductTooltip';

export default memo(BigProductTooltip);
