import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'recompose';
import cn from 'classnames';

import Asset, { ICON_MAP } from 'react/generic/asset/Asset';

import messages from './searchinput.messages';
import classNames from './search-input.module.scss';

const enhancer = compose(
  injectIntl,
  memo,
);

const SearchInput = ({
  className,
  search,
  onChangeSearch,
  onSubmit,
  onFocus,
  onBlur,
  intl,
  // eslint-disable-next-line no-unused-vars
  searchContext,
}) => (
  <div className={cn(className, classNames.container)}>
    <form
      className={classNames.form}
      onSubmit={onSubmit}
    >
      <button
        type="submit"
        className={classNames.searchButton}
      >
        <Asset name={ICON_MAP.loupe} />
      </button>

      <input
        className={classNames.searchInput}
        placeholder={intl.formatMessage(messages.PLACEHOLDER)}
        value={search}
        onChange={onChangeSearch}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </form>
  </div>
);

SearchInput.displayName = 'SearchInput';

SearchInput.propTypes = {
  className: PropTypes.string,
  search: PropTypes.string,
  onChangeSearch: PropTypes.func,
  /** Called when the user submits the form. */
  onSubmit: PropTypes.func,
  /** From injectIntl. */
  intl: intlShape.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  searchContext: PropTypes.string,
};

SearchInput.defaultProps = {
  className: '',
  search: '',
  onChangeSearch: noop,
  onSubmit: noop,
  onFocus: noop,
  onBlur: noop,
  searchContext: '',
};

export default enhancer(SearchInput);
