import React, { memo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import cn from 'classnames';
import classNames from './app-footer.module.scss';

const enhancer = compose(
  memo,
);

const QUICK_LINKS = {
  WORKPLACE: { link: 'https://adeo.workplace.com/groups/ADEO.TANGRAM/', label: 'Workplace' },
  // SUPPORT: { link: 'https://adeo.service-now.com/nav_to.do?uri=%2Fincident_list.do%3Fsysparm_query%3Dassignment_group.nameSTARTSWITHASFR-L3-Tangram%26sysparm_first_row%3D1%26sysparm_view%3D%26sysparm_choice_query_raw%3D%26sysparm_list_header_search%3Dtrue', label: 'Support' },
  // API: { link: 'https://developer.leroymerlin.fr/docs/api/7c9f229a-be38-4f60-b79a-ea2f6347fe84', label: 'API' },
  // GSITE: { link: 'https://sites.google.com/ext.adeo.com/tangram/', label: 'GSite' },
  // TANGRAM_EXPORT: { link: 'https://finder.data.adeo.cloud/#/search/datasets/tangram', label: 'Tangram export' },
  // PRODUCTS_EXPORT: { link: 'https://finder.data.adeo.cloud/#/search/datasets/rapid', label: 'Products export' },
};

const AppFooter = ({ pageType }) => (
  <div className={cn(classNames.container, classNames[`container--${pageType}`])}>
    {
      Object.keys(QUICK_LINKS).map((key) => {
        const l = QUICK_LINKS[key];

        return (
          // Click with the middle button of a mouse will not trigger the onClick event.
          <a href={l.link} target="_blank" rel="noopener noreferrer" key={l.label}>{l.label}</a>
        );
      })
    }
  </div>
);

AppFooter.displayName = 'AppFooter';

AppFooter.propTypes = {
  pageType: PropTypes.string,
};

AppFooter.defaultProps = {
  pageType: '',
};

export default enhancer(AppFooter);
